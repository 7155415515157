import {
  PRODUCT_TRANSACTION_RETURN_ALL,
  PRODUCT_TRANSACTION_RETURN_GET,
  PRODUCT_TRANSACTION_RETURN_DEL,
  PRODUCT_TRANSACTION_RETURN_PUT,
  PRODUCT_TRANSACTION_RETURN_POST,
  PRODUCT_TRANSACTION_RETURN_LOADING,
  PRODUCT_TRANSACTION_RETURN_ERROR,
  PRODUCT_TRANSACTION_RETURN_RESET,
  // eslint-disable-next-line import/named
} from '../../actions/types';

const initialState = {
  productTransactionReturn: null,
  isLoading: true,
  isCompleted: false,
};
// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  switch (action.type) {
    case PRODUCT_TRANSACTION_RETURN_LOADING:
      return { isLoading: true, isCompleted: false };
    case PRODUCT_TRANSACTION_RETURN_RESET:
      return { isLoading: true, isCompleted: false, ...action.payload };
    case PRODUCT_TRANSACTION_RETURN_ERROR:
      return { isLoading: true, isCompleted: false, ...action.payload };
    case PRODUCT_TRANSACTION_RETURN_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case PRODUCT_TRANSACTION_RETURN_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case PRODUCT_TRANSACTION_RETURN_POST:
      return { isLoading: false, isCompleted: true };
    case PRODUCT_TRANSACTION_RETURN_PUT:
      return { isLoading: false, isCompleted: true };
    case PRODUCT_TRANSACTION_RETURN_DEL:
      return { isLoading: false, isCompleted: true };
    default:
      return state;
  }
}
