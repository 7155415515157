import {
  PRODUCT_TRANSACTION_EXPORT_ALL,
  PRODUCT_TRANSACTION_EXPORT_GET,
  PRODUCT_TRANSACTION_EXPORT_DEL,
  PRODUCT_TRANSACTION_EXPORT_PUT,
  PRODUCT_TRANSACTION_EXPORT_POST,
  PRODUCT_TRANSACTION_EXPORT_LOADING,
  PRODUCT_TRANSACTION_EXPORT_ERROR,
  PRODUCT_TRANSACTION_EXPORT_RESET,
  // eslint-disable-next-line import/named
} from '../../actions/types';

const initialState = {
  productTransactionExport: null,
  isLoading: true,
  isCompleted: false,
};
// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  switch (action.type) {
    case PRODUCT_TRANSACTION_EXPORT_LOADING:
      return { isLoading: true, isCompleted: false };
    case PRODUCT_TRANSACTION_EXPORT_RESET:
      return { isLoading: true, isCompleted: false, ...action.payload };
    case PRODUCT_TRANSACTION_EXPORT_ERROR:
      return { isLoading: true, isCompleted: false, ...action.payload };
    case PRODUCT_TRANSACTION_EXPORT_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case PRODUCT_TRANSACTION_EXPORT_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case PRODUCT_TRANSACTION_EXPORT_POST:
      return { isLoading: false, isCompleted: true };
    case PRODUCT_TRANSACTION_EXPORT_PUT:
      return { isLoading: false, isCompleted: true };
    case PRODUCT_TRANSACTION_EXPORT_DEL:
      return { isLoading: false, isCompleted: true };
    default:
      return state;
  }
}
