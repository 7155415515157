import React, { useState, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import axios from 'axios';
import { Login } from '../views/Auth';
import { AuthNavbar } from '../components/Navbars';
import { AuthFooter } from '../components/Footers';
import { Loading } from '../components/Loading';
// import background from '../assets/images/BG-2.webp';

export const Auth = () => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    console.log('Layout : Auth');
    // async function initSystem() {
    //   const { data, status } = await axios.post(
    //     `${process.env.REACT_APP_API_URL}/init-system`,
    //   );
    //   console.log('init system : ', data.message);
    //   if (status === 200) setIsLoading(false);
    // }
    // initSystem();
    return () => {};
  }, []);
  console.log('Layout : Auth');
  if (isLoading) {
    return (
      <div className="bg-white">
        {/* <AuthNavbar /> */}
        <section className="relative w-full h-full min-h-screen">
          {/* <div
            className="absolute top-0 w-full h-full bg-gray-900 bg-no-repeat bg-full"
            style={{
              backgroundImage: `url(${background})`,
            }}
          ></div> */}
          <Switch>
            {window.localStorage.token ? (
              <>
                <Redirect from="/" to="/" />
              </>
            ) : (
              <>
                <Route path="/auth/login" exact component={Login} />
                <Redirect from="/auth" to="/auth/login" />
              </>
            )}
          </Switch>
        </section>
        {/* <AuthFooter /> */}
      </div>
    );
  }
  return <Loading />;
};

export default Auth;
