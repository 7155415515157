export const ReportHeader = (setting) => ({
  alignment: 'between',
  margin: [20, 4, 20, 0],
  table: {
    widths: ['50%', '50%'],
    body: [
      [
        {
          image: 'logo',
          width: 190,
          rowSpan: 2,
          margin: [0, 20, 0, 0],
          border: [false, false, false, false],
        },
        // {
        //   margin: [0, 20, 0, 0],
        //   text: `${setting?.owner?.name} `,
        //   style: 'header',
        //   border: [false, false, false, false],
        // },
        {
          margin: [0, 20, 0, 0],
          text: `${setting?.owner?.name} `,
          style: 'header',
          border: [false, false, false, false],
        },
      ],
      [
        { text: '', border: [false, false, false, false] },
        {
          margin: [0, 0, 0, 20],
          text: `${setting?.nameEng || ''}\n${
            setting?.owner?.address?.house_number || ''
          } ${setting?.owner?.address?.village_number || ''} ${
            setting?.owner?.address?.road || ''
          } ${setting?.owner?.address?.subdistrict || ''}\n${
            setting?.owner?.address?.district || ''
          } ${setting?.owner?.address?.province || ''} ${
            setting?.owner?.address?.postcode || ''
          }\nเลขประจำตัวผู้เสียภาษี ${setting?.owner?.tax} โทร ${
            setting?.owner?.telephone
          } `,
          style: 'body1',
          border: [false, false, false, false],
        },
      ],
    ],
  },
  styles: {
    header: {
      fontSize: 10,
      bold: true,
      alignment: 'right',
    },
    header1: {
      fontSize: 14,
      bold: true,
    },
  },
  images: {
    logo: setting?.logo?.url || '-',
  },
});

export default ReportHeader;
