import React from 'react';
import ReactExport from 'react-export-excel';
import _ from 'lodash';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

const { ExcelFile } = ReactExport;
const { ExcelSheet, ExcelColumn } = ReactExport.ExcelFile;

export default function ExcelSheetGenerator({
  dataSets,
  sheetName,
  columnList = [],
  columnDataList = [],
  spreadedColumn = '',
  type = '',
}) {
  let spreadedDataSet = [];
  if (spreadedColumn !== '') {
    _.map(dataSets, (eachData) => {
      _.map(eachData?.[spreadedColumn], (eachColumn, index) => {
        spreadedDataSet.push({ ...eachData, ...eachColumn });
      });
    });
  } else {
    spreadedDataSet = dataSets;
  }

  const dataSetWithIndex = _.map(spreadedDataSet, (eachData, index) => ({
    index: index + 1,
    ...eachData,
  }));

  const dataSetWithIndexForProduct = _.map(
    spreadedDataSet,
    (eachData, index) => ({
      index: index + 1,
      ...eachData,
      image_show: `=IMAGE(G${index + 2},E${index + 2},3,50,50)`,
    }),
  );

  console.log('spreadedDataSet sheetName', sheetName);
  console.log('spreadedDataSet', spreadedDataSet);
  console.log(
    'spreadedDataSet dataSetWithIndexForProduct',
    dataSetWithIndexForProduct,
  );

  return (
    <ExcelFile
      hideElement={true}
      filename={`${sheetName}-${dayjs().format('YYYYMMDD')}`}
    >
      {type === 'excelDataAll' ? (
        <ExcelSheet data={dataSetWithIndex} name={sheetName}>
          {_.map(columnDataList, (eachElement, index) => (
            <ExcelColumn
              label={eachElement.label}
              value={eachElement?.value}
              key={index}
            />
          ))}
        </ExcelSheet>
      ) : (
        <ExcelSheet
          data={
            sheetName === 'Product'
              ? dataSetWithIndexForProduct
              : dataSetWithIndex
          }
          name={sheetName}
        >
          {_.map(columnList, (eachElement, index) => (
            <ExcelColumn
              label={eachElement.label}
              value={eachElement?.value}
              key={index}
            />
          ))}
        </ExcelSheet>
      )}
    </ExcelFile>
  );
}

ExcelSheetGenerator.propTypes = {
  dataSets: PropTypes.arrayOf(PropTypes.object),
  columnList: PropTypes.arrayOf(PropTypes.object),
  columnDataList: PropTypes.arrayOf(PropTypes.object),
  sheetName: PropTypes.string,
  spreadedColumn: PropTypes.string,
  type: PropTypes.string,
};
