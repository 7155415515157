import React from 'react';
import { Card, CardContent } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import FileSync from '../../assets/images/filesync.png';
import SeasonChnage from '../../assets/images/season-change.png';
import Synchronize from '../../assets/images/synchronize.png';
import Upgrade from '../../assets/images/upgrade.png';

import LoadingLinear from './LoadingLinear';

// const useStyles = makeStyles({
//   backdrop: {
//     zIndex: 100,
//     color: '#fff',
//   },
// });

export default function LoadingNoBackdrop({ isLoading }) {
  // const classes = useStyles();
  const imageList = _.shuffle([SeasonChnage, FileSync, Synchronize, Upgrade]);
  return (
    <div className="min-h-screen overflow-hidden mt-4">
      <div className="w-full">
        <Card>
          <CardContent>
            <div className="text-center text-lg">กำลังโหลด กรุณารอสักครู่</div>
            <div className="p-2 px-4">
              <LoadingLinear isLoading={isLoading} />
            </div>
            <div className="flex justify-center">
              <img className="w-3/4" src={imageList[0]} />
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}

LoadingNoBackdrop.propTypes = {
  isLoading: PropTypes.bool,
};

LoadingNoBackdrop.defaultProps = {
  isLoading: true,
};
