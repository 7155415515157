import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { Card, Button } from '@mui/material';

import { Paperclip } from 'react-feather';
import VideoFileIcon from '@mui/icons-material/VideoFile';
import SaveIcon from '@mui/icons-material/Save';

import { useParams, useHistory } from 'react-router';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as actions from '../../redux/actions';
import { BackButton } from '../../components/Button';
import { EditProductForm } from '../../components/Forms';
import { Loading } from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
import ImageUpload from '../../components/ImageUpload/ImageUpload';

export default function EditProduct({ title, subtitle }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const product = useSelector((state) => state.product);
  const productType = useSelector((state) => state.productType);
  const brand = useSelector((state) => state.brand);
  const [selectOption, setSelectOption] = useState([]);
  const [serialType, setSerialType] = useState(product?.serial_type);
  const [privatePrice, setPrivatePrice] = useState(product?.private_price);
  const [uploadedImage, setUploadedImage] = useState(product?.images);
  const [productDescribe, setProductDescribe] = useState(product?.detail);
  const [delivery, setDelivery] = useState(product?.delivery);
  const [showFront, setShowFront] = useState(product?.show_front);
  const [popularProduct, setPopularProduct] = useState(
    product?.popular_product,
  );
  // const [taxInclude, setTaxInclude] = useState(product?.tax_include);

  const [file, setFile] = useState(product?.files || []);
  const [video, setVideo] = useState(product?.videos || []);

  console.log('product in edit', product);

  const [modalFileOpen, setModalFileOpen] = useState(false);
  const [modalVideoOpen, setModalVideoOpen] = useState(false);

  const {
    formState: { errors },
    handleSubmit,
    control,
    setValue,
  } = useForm({
    defaultValues: {
      name: product.name,
      unit: product.unit,
      detail: product.detail,
      type_code: product.type_code,
    },
  });

  useEffect(() => {
    dispatch(actions.productGet(id));
    dispatch(actions.productTypeAll(''));
    dispatch(actions.brandAll(''));
    return () => {};
  }, []);

  useEffect(() => {
    if (!product.isLoading) {
      setValue('optionList', product?.options);
    }
    setFile(product?.files || []);
    setVideo(product?.videos || []);
    setUploadedImage(product?.images);
    setProductDescribe(product?.detail);
    setDelivery(product?.delivery);
    setShowFront(product?.show_front);
    setPopularProduct(product?.popular_product);
    // setTaxInclude(product?.tax_include);
    return () => {};
  }, [product]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const onSubmit = async (data) => {
    // const barcodeNumber = `${'885' + 'data.type_code'}${data.type_code}2`;
    console.log('data before submit', data);

    try {
      const payload = {
        ...product,
        type_code: data.type_code,
        model_name: data.model_name,
        name: data.name,
        detail: data?.detail || productDescribe,
        unit: data.unit,
        product_type: data.product_type,
        brand: data.brand,
        images: _.map(uploadedImage, (image) => ({
          // eslint-disable-next-line no-underscore-dangle
          id: image._id,
          image: image.data_url || image.url,
        })),
        minimum_inventory: data.minimum_inventory,
      };

      console.log('product after submit', product);
      console.log('payload after submit', payload);
      await dispatch(actions.productPut(id, payload));
      await dispatch(actions.productGet(id));
      setFile([]);
      alert('สำเร็จ');
      history.goBack();
    } catch (error) {
      console.log(error);
    }
  };

  // console.log('product', product);
  if (product.isLoading || product.rows) {
    return <Loading />;
  }
  if (!product.isLoading && product.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
          <div className="text-lg font-semibold ml-6">แก้ไขข้อมูลสินค้า</div>
        </div>
        <div>
          <Card className="p-4">
            <form onSubmit={handleSubmit(onSubmit)}>
              <EditProductForm
                control={control}
                Controller={Controller}
                errors={errors}
                product={product}
                productType={productType}
                brand={brand}
              />
              <div className="p-2 bg-gray-600 text-white my-2 w-full">
                รูปภาพของสินค้า
              </div>
              <div className="px-1 pt-4">
                <ImageUpload
                  images={uploadedImage}
                  setImages={setUploadedImage}
                  preview_size="250"
                  maxNumber={1}
                />
              </div>
              <div className="flex flex-row justify-end gap-1 py-4">
                <Button
                  variant="contained"
                  type="submit"
                  startIcon={<SaveIcon />}
                >
                  บันทึก
                </Button>
              </div>
            </form>
          </Card>
        </div>
      </div>
    );
  }
  return <Error />;
}

EditProduct.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditProduct.defaultProps = {
  title: '',
  subtitle: '',
};
