import { FormControlLabel, Checkbox, Card } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

export function AvailableModuleForm({ availableModule, Controller, control }) {
  console.log('availableModule in setting from', availableModule);
  const renderAccessList = () => (
    <div className="">
      <div className="w-full px-1 ">
        <div className="w-full px-1">
          <Controller
            name={'DASHBOARD'}
            control={control}
            defaultValue={availableModule?.DASHBOARD || false}
            rules={{ required: false }}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} defaultChecked={field.value} />}
                label="แดชบอร์ด"
              />
            )}
          />
        </div>
        {/* <div className="w-full px-1 py-1">
          <Controller
            name={'POS'}
            control={control}
            defaultValue={availableModule?.POS || false}
            rules={{ required: false }}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} defaultChecked={field.value} />}
                label="จัดการขายหน้าร้าน"
              />
            )}
          />
        </div>{' '} */}
        <div className="w-full px-1 py-1">
          <Controller
            name={'SPM'}
            control={control}
            defaultValue={availableModule?.SPM || false}
            rules={{ required: false }}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} defaultChecked={field.value} />}
                label="จัดการคำสั่งซื้อขาย"
              />
            )}
          />
        </div>{' '}
        <div className="w-full px-1 py-1">
          <Controller
            name={'WMS'}
            control={control}
            defaultValue={availableModule?.WMS || false}
            rules={{ required: false }}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} defaultChecked={field.value} />}
                label="คลังสินค้า"
              />
            )}
          />
        </div>
        <div className="w-full px-1 py-1">
          <Controller
            name={'HISTORY'}
            control={control}
            defaultValue={availableModule?.HISTORY || false}
            rules={{ required: false }}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} defaultChecked={field.value} />}
                label="จัดการประวัติ"
              />
            )}
          />
        </div>
        <div className="w-full px-1 py-1">
          <Controller
            name={'CRM'}
            control={control}
            defaultValue={availableModule?.CRM || false}
            rules={{ required: false }}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} defaultChecked={field.value} />}
                label="ลูกค้าสัมพันธ์"
              />
            )}
          />
        </div>
        {/* <div className="w-full px-1 py-1">
          <Controller
            name={'CMS'}
            control={control}
            defaultValue={availableModule?.CMS || false}
            rules={{ required: false }}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} defaultChecked={field.value} />}
                label="จัดการเว็บไซต์"
              />
            )}
          />
        </div> */}
        {/* <div className="w-full px-1 py-1">
          <Controller
            name={'DISCOUNT'}
            control={control}
            defaultValue={availableModule?.DISCOUNT || false}
            rules={{ required: false }}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} defaultChecked={field.value} />}
                label="จัดการส่วนลด"
              />
            )}
          />
        </div> */}
        {/* <div className="w-full px-1 py-1">
          <Controller
            name={'RESERVE'}
            control={control}
            defaultValue={availableModule?.RESERVE || false}
            rules={{ required: false }}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} defaultChecked={field.value} />}
                label="จัดการการจอง"
              />
            )}
          />
        </div> */}
        <div className="w-full px-1 py-1">
          <FormControlLabel
            control={<Checkbox defaultChecked={true} disabled={true} />}
            label="ทรัพยากรบุคคล"
          />
        </div>
        <div className="w-full px-1 py-1">
          <FormControlLabel
            control={<Checkbox defaultChecked={true} disabled={true} />}
            label="การตั้งค่า"
          />
        </div>
      </div>
    </div>
  );

  return (
    <div className="flex flex-row flex-wrap">
      <Card className="w-full p-4">
        <div> {renderAccessList()} </div>
      </Card>
    </div>
  );
}

AvailableModuleForm.propTypes = {
  availableModule: PropTypes.object,
  Controller: PropTypes.func,
  control: PropTypes.object,
};

AvailableModuleForm.defaultProps = {
  information: null,
};

export default AvailableModuleForm;
