import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

// import { io } from 'socket.io-client';

// import { FullScreen, useFullScreenHandle } from 'react-full-screen';

// eslint-disable-next-line object-curly-newline
import {
  Backdrop,
  Button,
  Table,
  Fade,
  Modal,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Card,
  TextField,
  InputAdornment,
} from '@mui/material';

import { red } from '@mui/material/colors';

import DescriptionIcon from '@mui/icons-material/Description';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';

import { useForm, Controller } from 'react-hook-form';

import dayjs from 'dayjs';
import _ from 'lodash';
import 'dayjs/locale/th';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import PropTypes from 'prop-types';
// import newGif from '../../assets/images/new-post.gif';
import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { Error, NotAuthorized } from '../../components/Error';
import accessSubModule from '../../utils/functions/accessSubModule';
import { ViewTitle } from '../../components/ViewTitle';
import { Clock } from '../../components/DigitalClock';

dayjs.locale('th');
dayjs.extend(buddhistEra);

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  boxShadow: 24,
  p: 4,
};

const defaultValue = {
  tracking_number: '',
};

const OnlineHistory = ({ title, subtitle }) => {
  const module = 'HISTORY';
  const subModule = 'SALEORDER';
  const dispatch = useDispatch();
  const product = useSelector((state) => state.product);
  const paymentTransaction = useSelector((state) => state.paymentTransaction);
  const option = useSelector((state) => state.option);
  const setting = useSelector((state) => state.setting);
  const me = useSelector((state) => state.me);

  const history = useHistory();

  // const socket = io(process.env.REACT_APP_SOCKET_URL);

  // console.log('product', product?.rows);
  // const product = useSelector((state) => state.product);
  console.log('paymentTransaction', paymentTransaction.rows);
  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(25);
  const [total, setTotal] = useState(undefined);
  const [openTrack, setOpenTrack] = useState(false);

  const [reload, setReload] = useState(false);

  // console.log('checkPaymentOrder', checkPaymentOrder);

  const newPaymentTransaction = _.filter(
    paymentTransaction?.rows,
    // eslint-disable-next-line consistent-return
    (eachPaymentTransaction) => {
      if (
        eachPaymentTransaction?.status_order === 'PRODUCT_COMPLETE' ||
        eachPaymentTransaction?.status_order === 'PRODUCT_CANCEL'
      ) {
        return eachPaymentTransaction;
      }
    },
  );

  console.log('newPaymentTransaction', newPaymentTransaction);

  const [selectOrder, setSelectOrder] = useState(null);

  const { control, handleSubmit, reset } = useForm({
    mode: 'onBlur',
  });

  const handleCloseTrack = () => setOpenTrack(false);

  // socket.on('connect', () => {
  //   console.log('socket connect online');
  //   socket.on('new-message', (data) => {
  //     console.log('socket log', data);
  //     // window.location.reload();
  //     setReload(true);
  //   });
  // });

  useEffect(() => {
    dispatch(actions.meGet());

    const fetchPaymentTransaction = async () => {
      try {
        await dispatch(
          actions.paymentTransactionAll({
            name,
            page,
            size,
          }),
        );
        await dispatch(actions.getAllOption(''));
        await dispatch(actions.productAll(''));
        await dispatch(actions.settingAll());
      } catch (error) {
        console.error(error);
      }
    };
    fetchPaymentTransaction();

    return () => {};
  }, [name, page, size]);

  useEffect(() => {
    if (reload) {
      console.log('😂🤣😍');

      setTimeout(() => {
        console.warn('FETCH TXN');
        dispatch(
          actions.paymentTransactionAll({
            name,
            page,
            size,
          }),
        );
        setReload(false);
      }, 1000);
    }

    return () => {};
  }, [reload]);

  useEffect(() => {
    setTotal(newPaymentTransaction.length);
    return () => {};
  }, [paymentTransaction]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleToInfoPage = async (id) => {
    history.push(`/spm/online/${id}`);
  };

  // const checkProduct = (id) => {
  //   const productOfId = _.filter(
  //     product.rows,
  //     (eachProduct) => eachProduct.id === id,
  //   );
  //   return productOfId[0];
  // };

  const checkOption = (optionId) => {
    const optionData = _.find(option?.rows, { id: optionId });

    return optionData;
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderSearch = () => (
    <Card>
      <div className="p-4 md:flex">
        <div className="w-full md:w-1/2">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="w-full md:w-1/2 text-center my-auto text-gray-800 flex justify-center">
          {/* <FormControlLabel
            control={
              <Checkbox
                checked={checkPaymentOrder}
                onChange={handleChangeSelect}
                name="checkOrder"
              />
            }
            label="แสดงเฉพาะออเดอร์ที่ชำระเงินเเล้ว"
          /> */}
          <Clock hour12={false} />
        </div>
      </div>
    </Card>
  );

  // console.log('data in tracking', selectOrder);

  const onSubmitTracking = async (data) => {
    const payload = {
      tracking_number: data?.tracking_number,
    };
    const id = selectOrder?.source_id;
    await dispatch(actions.paymentTransactionPut(id, payload));
    alert('สำเร็จ');
    reset(defaultValue);
    handleCloseTrack();
    await dispatch(actions.paymentTransactionAll({ name, page, size }));
  };

  const renderModalAddTrackingNumber = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openTrack}
      onClose={handleCloseTrack}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openTrack}>
        <Card sx={style} className="max-w-sm ">
          <div className="py-2">เพิ่มหมายเลยติดตามสินค้า</div>
          <div className="py-2">
            <form onSubmit={handleSubmit(onSubmitTracking)}>
              <div className="w-full  px-1 py-2">
                <Controller
                  name={'tracking_number'}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="หมายเลขติดตามสินค้า"
                      fullWidth
                      type="string"
                      size={'small'}
                      required
                      defaultValue={selectOrder?.tracking_number}
                    />
                  )}
                />
              </div>
              <div className="flex flex-row justify-end gap-1 py-4">
                <Button
                  variant="contained"
                  type="submit"
                  startIcon={<SaveIcon />}
                >
                  บันทึก
                </Button>
                <Button variant="outlined" onClick={handleCloseTrack}>
                  ยกเลิก
                </Button>
              </div>
            </form>
          </div>
        </Card>
      </Fade>
    </Modal>
  );

  const buttonDetailOrder = (data) => (
    <>
      <Button
        variant="contained"
        color={'secondary'}
        fullWidth
        startIcon={<DescriptionIcon />}
        size={'small'}
        onClick={() => handleToInfoPage(data?.id)}
      >
        รายละเอียด
      </Button>
    </>
  );

  const renderTable = () => (
    <div className="my-2">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">วันที่สั่ง</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">คู่ค้า</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">รายการสินค้า</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">จำนวนเงิน</div>
                </TableCell>

                <TableCell>
                  <div className="font-bold">ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(newPaymentTransaction) ? (
                newPaymentTransaction.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    className={index % 2 === 0 ? 'bg-gray-100' : ''}
                  >
                    <TableCell component="th" scope="row">
                      <div className="flex">
                        <p className="my-auto">
                          {(page - 1) * size + index + 1}
                        </p>
                      </div>
                    </TableCell>
                    <TableCell>
                      {row?.status_order === 'PRODUCT_CANCEL' && (
                        <div className="w-full pb-2">
                          <CancelIcon sx={{ color: red[500] }} />{' '}
                          ออเดอร์ถูกยกเลิก
                        </div>
                      )}
                      <div className="w-full">
                        {`${dayjs(row?.createdAt).format('D MMM BBBB')}`}
                        {' , '}
                        {`${dayjs(row?.createdAt).format('HH:mm')}`}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="font-bold flex">
                        <p className="my-auto">{`${row?.customer?.name}`}</p>
                      </div>
                    </TableCell>
                    <TableCell>
                      {!_.isEmpty(row?.order_id?.orders) && (
                        <>
                          {row?.order_id?.orders.map((eachOrder, _index) => (
                            <div key={_index} className="w-full py-1 flex">
                              <p className="pr-1 text-red-500 font-bold">
                                {eachOrder?.quantity} X
                              </p>
                              <p className="font-bold">
                                {eachOrder?.product?.name}
                              </p>
                            </div>
                          ))}
                        </>
                      )}
                    </TableCell>
                    <TableCell>
                      <p>
                        {(row?.amount || 0)
                          .toFixed(1)
                          .replace(/\d(?=(\d{3})+\.)/g, '$&,')}{' '}
                        บาท
                      </p>
                      <p>
                        ({' '}
                        {(row?.net || 0)
                          .toFixed(1)
                          .replace(/\d(?=(\d{3})+\.)/g, '$&,')}{' '}
                        บาท )
                      </p>
                    </TableCell>

                    <TableCell>{buttonDetailOrder(row)}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25, 100]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={handleChangePage}
        />
      </Paper>
    </div>
  );

  if (
    paymentTransaction.isLoading ||
    product.isLoading ||
    option.isLoading ||
    !paymentTransaction.rows
  ) {
    return <Loading />;
  }
  if (
    !paymentTransaction.isLoading &&
    paymentTransaction.isCompleted &&
    !product.isLoading &&
    product.isCompleted &&
    !option.isLoading &&
    option.isCompleted
  ) {
    if (accessSubModule(me?.userData?.access, module, subModule)) {
      return (
        <div>
          {renderModalAddTrackingNumber()}
          <div className="flex justify-between">
            <div>{renderTitle()}</div>
            {/* <div className="mt-6">{renderChengeLayout()}</div> */}
          </div>
          {renderSearch()}
          {renderTable()}
        </div>
      );
    }
    return <NotAuthorized />;
  }
  return <Error message={paymentTransaction?.message} />;
};

OnlineHistory.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

OnlineHistory.defaultProps = {
  title: '',
  subtitle: '',
};

export default OnlineHistory;
