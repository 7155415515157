/* eslint-disable no-confusing-arrow */
import appConfig from '../../configs/app';

const columns = [
  {
    label: 'ลำดับที่',
    value: 'index',
  },
  {
    label: 'วันที่',
    value: 'date',
  },
  {
    label: 'หมายเลขโครงการ',
    value: 'project_number',
  },
  {
    label: 'หมายเลขบิล',
    value: 'bill_number',
  },
  {
    label: 'ขื่อสินค้า',
    value: 'product_name',
  },
  {
    label: 'สถานะ',
    value: 'transaction_type',
  },
  {
    label: 'จำนวน',
    value: 'quantity',
  },
  {
    label: 'ก่อน',
    value: 'prev_quantity',
  },
  {
    label: 'คลัง',
    value: 'warehouse_name',
  },
  {
    label: 'คงเหลือ',
    value: 'next_quantity',
  },
  {
    label: 'ผู้ดำเนินการ',
    value: 'user',
  },
];

const query = ({ id }) => ({
  place: id,
  fetchStockLot: true,
  fetchProcess: false,
  page: 1,
  size: appConfig.maxFetchSize,
});

export default { columns, query };
