import {
  PRODUCT_TRANSACTION_EXPORT_ALL,
  PRODUCT_TRANSACTION_EXPORT_GET,
  PRODUCT_TRANSACTION_EXPORT_DEL,
  PRODUCT_TRANSACTION_EXPORT_PUT,
  PRODUCT_TRANSACTION_EXPORT_POST,
  PRODUCT_TRANSACTION_EXPORT_LOADING,
  PRODUCT_TRANSACTION_EXPORT_ERROR,
  PRODUCT_TRANSACTION_EXPORT_RESET,
} from '../types';

import api from '../../../utils/functions/api';

export const productTransactionExportCreate = (payload) => async (dispatch) => {
  try {
    console.log('payload in product transaction', payload);
    dispatch({ type: PRODUCT_TRANSACTION_EXPORT_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/product-transaction-export`,
      {
        ...payload,
      },
    );
    dispatch({ type: PRODUCT_TRANSACTION_EXPORT_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PRODUCT_TRANSACTION_EXPORT_ERROR });
    throw new Error(error);
  }
};

export const productTransactionExportAll = (params) => async (dispatch) => {
  try {
    const {
      name = '',
      size = '',
      page = 1,
      transactionType = '',
      startDate = '',
      endDate = '',
      DashBoardDaily = '',
      DashBoardPage = false,
      selectDate = '',
      statusDocText = '',
      statusReturnText = '',
      sortExportBill = '',
      returnPage = '',
    } = params;
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/product-transaction-export?name=${name}&size=${size}&page=${page}&status=${statusDocText}&status_return=${statusReturnText}&type=${transactionType}&startDate=${startDate}&endDate=${endDate}&DashBoardDaily=${DashBoardDaily}&DashBoardPage=${DashBoardPage}&selectDate=${selectDate}&sortExportBill=${sortExportBill}&returnPage=${returnPage}`,
    );
    if (status === 200) {
      dispatch({ type: PRODUCT_TRANSACTION_EXPORT_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: PRODUCT_TRANSACTION_EXPORT_ERROR });
    throw new Error(error);
  }
};

export const productTransactionExportReset = () => async (dispatch) => {
  try {
    const data = [];
    dispatch({ type: PRODUCT_TRANSACTION_EXPORT_RESET, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PRODUCT_TRANSACTION_EXPORT_ERROR });
    throw new Error(error);
  }
};

export const productTransactionExportGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/product-transaction-export/${id}`,
    );
    if (status === 200) {
      dispatch({ type: PRODUCT_TRANSACTION_EXPORT_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: PRODUCT_TRANSACTION_EXPORT_ERROR });
    throw new Error(error);
  }
};

export const productTransactionExportPut = (id, payload) => async (
  dispatch,
) => {
  try {
    dispatch({ type: PRODUCT_TRANSACTION_EXPORT_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/product-transaction-export/${id}`,
      payload,
    );
    dispatch({ type: PRODUCT_TRANSACTION_EXPORT_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PRODUCT_TRANSACTION_EXPORT_ERROR });
    throw new Error(error);
  }
};
export const productTransactionExportDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_TRANSACTION_EXPORT_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/product-transaction-export/${id}`,
    );
    dispatch({ type: PRODUCT_TRANSACTION_EXPORT_DEL, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PRODUCT_TRANSACTION_EXPORT_ERROR });
    throw new Error(error);
  }
};
