import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Checkbox,
  FormControlLabel,
  Divider,
  Box,
  Switch,
} from '@mui/material';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import ImageUpload from '../../ImageUpload/ImageUpload';

export function EmployeeForm({
  errors,
  department,
  roletype,
  employee,
  Controller,
  control,
  addUser,
  setAddUser,
  employeeImage,
  setEmployeeImage,
  checkDASHBOARD,
  setCheckDASHBOARD,
  checkSPM,
  setCheckSPM,
  checkWMS,
  setCheckWMS,
  checkHIS,
  setCheckHIS,
  checkCRM,
  setCheckCRM,
  checkHMS,
  setCheckHMS,
  checkSETTING,
  setCheckSETTING,
}) {
  console.log('checkWMS', checkWMS);
  console.log('checkDASHBOARD', checkDASHBOARD);
  console.log('checkSPM', checkSPM);
  console.log('checkHIS', checkHIS);
  console.log('checkCRM', checkCRM);
  console.log('checkHMS', checkHMS);
  console.log('checkSETTING', checkSETTING);

  // DASHBOARD
  const handleChangeDASHBOARD = (e) => {
    setCheckDASHBOARD([e.target.checked]);
  };
  const handleChangeDASHBOARDMAIN = (e) => {
    setCheckDASHBOARD([e.target.checked]);
  };

  // SPM
  const handleChangeSPM = (e) => {
    setCheckSPM([e.target.checked, e.target.checked, e.target.checked]);
  };
  const handleChangeSPMIMPORT = (e) => {
    setCheckSPM([e.target.checked, checkSPM[1], checkSPM[2]]);
  };
  const handleChangeSPMEXPORT = (e) => {
    setCheckSPM([checkSPM[0], e.target.checked, checkSPM[2]]);
  };
  const handleChangeSPMRETURN = (e) => {
    setCheckSPM([checkSPM[0], checkSPM[1], e.target.checked]);
  };

  // WMS
  const handleChangeWMS = (e) => {
    setCheckWMS([
      e.target.checked,
      e.target.checked,
      e.target.checked,
      e.target.checked,
    ]);
  };
  const handleChangeWMSPRODUCT = (e) => {
    setCheckWMS([e.target.checked, checkWMS[1], checkWMS[2], checkWMS[3]]);
  };
  const handleChangeWMSPRODUCTTYPE = (e) => {
    setCheckWMS([checkWMS[0], e.target.checked, checkWMS[2], checkWMS[3]]);
  };
  const handleChangeWMSBRAND = (e) => {
    setCheckWMS([checkWMS[0], checkWMS[1], e.target.checked, checkWMS[3]]);
  };
  const handleChangeWMSWAREHOUSE = (e) => {
    setCheckWMS([checkWMS[0], checkWMS[1], checkWMS[2], e.target.checked]);
  };

  // HISTORY
  const handleChangeHIS = (e) => {
    setCheckHIS([e.target.checked, e.target.checked]);
  };
  const handleChangeHISSALEORDER = (e) => {
    setCheckHIS([e.target.checked, checkHIS[1]]);
  };
  const handleChangeHISSTOCK = (e) => {
    setCheckHIS([checkHIS[0], e.target.checked]);
  };

  // CUSTOMER
  const handleChangeCRM = (e) => {
    setCheckCRM([e.target.checked, e.target.checked]);
  };
  const handleChangeCRMCUSTOMER = (e) => {
    setCheckCRM([e.target.checked, checkCRM[1]]);
  };
  const handleChangeCRMCUSTTOMERTYPE = (e) => {
    setCheckCRM([checkCRM[0], e.target.checked]);
  };

  // HRMS
  const handleChangeHMS = (e) => {
    setCheckHMS([
      e.target.checked,
      e.target.checked,
      e.target.checked,
      e.target.checked,
    ]);
  };
  const handleChangeHMSEMPLOYEE = (e) => {
    setCheckHMS([e.target.checked, checkHMS[1], checkHMS[2], checkHMS[3]]);
  };
  const handleChangeHMSDEPARTMENT = (e) => {
    setCheckHMS([checkHMS[0], e.target.checked, checkHMS[2], checkHMS[3]]);
  };
  const handleChangeHMSROLETYPE = (e) => {
    setCheckHMS([checkHMS[0], checkHMS[1], e.target.checked, checkHMS[3]]);
  };
  const handleChangeHMSUSER = (e) => {
    setCheckHMS([checkHMS[0], checkHMS[1], checkHMS[2], e.target.checked]);
  };

  // SETTING
  const handleChangeSETTING = (e) => {
    setCheckSETTING([e.target.checked]);
  };
  const handleChangeSETTINGINFOR = (e) => {
    setCheckSETTING([e.target.checked]);
  };

  const subModuleForm = () => (
    <div className="w-full">
      <div className="w-full font-bold mb-4">สิทธ์การเข้าถึง</div>
      <Divider />
      {/* DASHBOARD */}
      <div>
        <div className="w-full px-1 py-1">
          <FormControlLabel
            control={
              <Switch
                checked={checkDASHBOARD[0]}
                onChange={handleChangeDASHBOARD}
              />
            }
            label="แดชบอร์ด"
          />
          {checkDASHBOARD[0] && (
            <>
              <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                <Controller
                  name={'access.DASHBOARD.MAIN.access'}
                  control={control}
                  defaultValue={
                    employee ? employee?.access?.DASHBOARD?.MAIN?.access : false
                  }
                  rules={{ required: false }}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          checked={checkDASHBOARD[0]}
                          onChange={handleChangeDASHBOARDMAIN}
                          defaultChecked={field.value}
                        />
                      }
                      label="แดชบอร์ดหลัก"
                    />
                  )}
                />
              </Box>
            </>
          )}
        </div>
      </div>
      <Divider />
      {/* SPM */}
      <div>
        <div className="w-full px-1 py-1">
          <FormControlLabel
            control={
              <Switch
                checked={checkSPM[0] || checkSPM[1]}
                onChange={handleChangeSPM}
              />
            }
            label="จัดการนำเข้าส่งออก"
          />
          {(checkSPM[0] || checkSPM[1]) && (
            <>
              <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                <Controller
                  name={'access.SPM.IMPORT.access'}
                  control={control}
                  defaultValue={
                    employee ? employee?.access?.SPM?.IMPORT?.access : false
                  }
                  rules={{ required: false }}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          checked={checkSPM[0]}
                          onChange={handleChangeSPMIMPORT}
                          defaultChecked={field.value}
                        />
                      }
                      label="การนำเข้าสินค้า"
                    />
                  )}
                />
                <Controller
                  name={'access.SPM.EXPORT.access'}
                  control={control}
                  defaultValue={
                    employee ? employee?.access?.SPM?.EXPORT?.access : false
                  }
                  rules={{ required: false }}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          checked={checkSPM[1]}
                          onChange={handleChangeSPMEXPORT}
                          defaultChecked={field.value}
                        />
                      }
                      label="การส่งออกสินค้า"
                    />
                  )}
                />
                <Controller
                  name={'access.SPM.RETURN.access'}
                  control={control}
                  defaultValue={
                    employee ? employee?.access?.SPM?.RETURN?.access : false
                  }
                  rules={{ required: false }}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          checked={checkSPM[2]}
                          onChange={handleChangeSPMRETURN}
                          defaultChecked={field.value}
                        />
                      }
                      label="การรับคืนสินค้า"
                    />
                  )}
                />
              </Box>
            </>
          )}
        </div>
      </div>
      <Divider />
      {/* PRODUCT */}
      <div>
        <div className="w-full px-1 py-1">
          <FormControlLabel
            control={
              <Switch
                checked={
                  checkWMS[0] || checkWMS[1] || checkWMS[2] || checkWMS[3]
                }
                onChange={handleChangeWMS}
              />
            }
            label="คลังสินค้า"
          />
          {(checkWMS[0] || checkWMS[1] || checkWMS[2] || checkWMS[3]) && (
            <>
              <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                <Controller
                  name={'access.WMS.PRODUCT.access'}
                  control={control}
                  defaultValue={
                    employee ? employee?.access?.WMS?.PRODUCT?.access : false
                  }
                  rules={{ required: false }}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          checked={checkWMS[0]}
                          onChange={handleChangeWMSPRODUCT}
                          defaultChecked={field.value}
                        />
                      }
                      label="จัดการคลังสินค้า"
                    />
                  )}
                />

                <Controller
                  name={'access.WMS.PRODUCTTYPE.access'}
                  control={control}
                  defaultValue={
                    employee
                      ? employee?.access?.WMS?.PRODUCTTYPE?.access
                      : false
                  }
                  rules={{ required: false }}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          checked={checkWMS[1]}
                          onChange={handleChangeWMSPRODUCTTYPE}
                          defaultChecked={field.value}
                        />
                      }
                      label="จัดการประเภทสินค้า"
                    />
                  )}
                />
                <Controller
                  name={'access.WMS.BRAND.access'}
                  control={control}
                  defaultValue={
                    employee ? employee?.access?.WMS?.BRAND?.access : false
                  }
                  rules={{ required: false }}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          checked={checkWMS[2]}
                          onChange={handleChangeWMSBRAND}
                          defaultChecked={field.value}
                        />
                      }
                      label="จัดการแบรนด์"
                    />
                  )}
                />
                <Controller
                  name={'access.WMS.WAREHOUSE.access'}
                  control={control}
                  defaultValue={
                    employee ? employee?.access?.WMS?.WAREHOUSE?.access : false
                  }
                  rules={{ required: false }}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          checked={checkWMS[3]}
                          onChange={handleChangeWMSWAREHOUSE}
                          defaultChecked={field.value}
                        />
                      }
                      label="จัดการคลัง"
                    />
                  )}
                />
              </Box>
            </>
          )}
        </div>
      </div>
      <Divider />
      {/* HISTORY */}
      <div>
        <div className="w-full px-1 py-1">
          <FormControlLabel
            control={
              <Switch
                checked={checkHIS[0] || checkHIS[1]}
                onChange={handleChangeHIS}
              />
            }
            label="จัดการประวัติ"
          />
          {(checkHIS[0] || checkHIS[1]) && (
            <>
              <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                <Controller
                  name={'access.HISTORY.SALEORDER.access'}
                  control={control}
                  defaultValue={
                    employee
                      ? employee?.access?.HISTORY?.SALEORDER?.access
                      : false
                  }
                  rules={{ required: false }}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          checked={checkHIS[0]}
                          onChange={handleChangeHISSALEORDER}
                          defaultChecked={field.value}
                        />
                      }
                      label="ประวัติการนำเข้า/ส่งออก"
                    />
                  )}
                />
                <Controller
                  name={'access.HISTORY.STOCK.access'}
                  control={control}
                  defaultValue={
                    employee ? employee?.access?.HISTORY?.STOCK?.access : false
                  }
                  rules={{ required: false }}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          checked={checkHIS[1]}
                          onChange={handleChangeHISSTOCK}
                          defaultChecked={field.value}
                        />
                      }
                      label="ประวัติการจัดการสต๊อกสินค้า"
                    />
                  )}
                />
              </Box>
            </>
          )}
        </div>
      </div>
      <Divider />
      {/* CUSTOMER */}
      <div>
        <div className="w-full px-1 py-1">
          <FormControlLabel
            control={
              <Switch
                checked={checkCRM[0] || checkCRM[1]}
                onChange={handleChangeCRM}
              />
            }
            label="ลูกค้าสัมพันธ์"
          />
          {(checkCRM[0] || checkCRM[1]) && (
            <>
              <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                <Controller
                  name={'access.CRM.CUSTOMER.access'}
                  control={control}
                  defaultValue={
                    employee ? employee?.access?.CRM?.CUSTOMER?.access : false
                  }
                  rules={{ required: false }}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          checked={checkCRM[0]}
                          onChange={handleChangeCRMCUSTOMER}
                          defaultChecked={field.value}
                        />
                      }
                      label="รายการลูกค้า"
                    />
                  )}
                />
                <Controller
                  name={'access.CRM.CUSTOMERTYPE.access'}
                  control={control}
                  defaultValue={
                    employee
                      ? employee?.access?.CRM?.CUSTOMERTYPE?.access
                      : false
                  }
                  rules={{ required: false }}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          checked={checkCRM[1]}
                          onChange={handleChangeCRMCUSTTOMERTYPE}
                          defaultChecked={field.value}
                        />
                      }
                      label="ประเภทลูกค้า"
                    />
                  )}
                />
              </Box>
            </>
          )}
        </div>
      </div>
      <Divider />
      {/* HRMS */}
      <div>
        <div className="w-full px-1 py-1">
          <FormControlLabel
            control={
              <Switch
                checked={
                  checkHMS[0] || checkHMS[1] || checkHMS[2] || checkHMS[3]
                }
                onChange={handleChangeHMS}
              />
            }
            label="ทรัพยากรบุคคล"
          />
          {(checkHMS[0] || checkHMS[1] || checkHMS[2] || checkHMS[3]) && (
            <>
              <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                <Controller
                  name={'access.HRMS.EMPLOYEE.access'}
                  control={control}
                  defaultValue={
                    employee ? employee?.access?.HRMS?.EMPLOYEE?.access : false
                  }
                  rules={{ required: false }}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          checked={checkHMS[0]}
                          onChange={handleChangeHMSEMPLOYEE}
                          defaultChecked={field.value}
                        />
                      }
                      label="จัดการพนักงาน"
                    />
                  )}
                />
                <Controller
                  name={'access.HRMS.DEPARTMENT.access'}
                  control={control}
                  defaultValue={
                    employee
                      ? employee?.access?.HRMS?.DEPARTMENT?.access
                      : false
                  }
                  rules={{ required: false }}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          checked={checkHMS[1]}
                          onChange={handleChangeHMSDEPARTMENT}
                          defaultChecked={field.value}
                        />
                      }
                      label="จัดการแผนก"
                    />
                  )}
                />
                <Controller
                  name={'access.HRMS.ROLETYPE.access'}
                  control={control}
                  defaultValue={
                    employee ? employee?.access?.HRMS?.ROLETYPE?.access : false
                  }
                  rules={{ required: false }}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          checked={checkHMS[2]}
                          onChange={handleChangeHMSROLETYPE}
                          defaultChecked={field.value}
                        />
                      }
                      label="จัดการบทบาท"
                    />
                  )}
                />
                <Controller
                  name={'access.HRMS.USER.access'}
                  control={control}
                  defaultValue={
                    employee ? employee?.access?.HRMS?.USER?.access : false
                  }
                  rules={{ required: false }}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          checked={checkHMS[3]}
                          onChange={handleChangeHMSUSER}
                          defaultChecked={field.value}
                        />
                      }
                      label="จัดการผู้ใช้"
                    />
                  )}
                />
              </Box>
            </>
          )}
        </div>
      </div>
      <Divider />
      {/* SETTING */}
      <div>
        <div className="w-full px-1 py-1">
          <FormControlLabel
            control={
              <Switch
                checked={checkSETTING[0]}
                onChange={handleChangeSETTING}
              />
            }
            label="ตั้งค่าระบบ"
          />
          {checkSETTING[0] && (
            <>
              <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                <Controller
                  name={'access.SETTING.INFORMATION.access'}
                  control={control}
                  defaultValue={
                    employee
                      ? employee?.access?.SETTING?.INFORMATION?.access
                      : false
                  }
                  rules={{ required: false }}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          checked={checkSETTING[0]}
                          onChange={handleChangeSETTINGINFOR}
                          defaultChecked={field.value}
                        />
                      }
                      label="ตั้งค่าข้อมูลระบบ"
                    />
                  )}
                />
              </Box>
            </>
          )}
        </div>
      </div>
      {/* ----------------- */}
    </div>
  );
  return (
    <div className="flex flex-row flex-wrap">
      {addUser != null ? (
        <div className="w-full px-1 py-1">
          <FormControlLabel
            value="end"
            defaultValue={addUser}
            control={<Checkbox color="primary" />}
            label="สร้างพนักงานพร้อมบัญชีผู้ใช้"
            labelPlacement="end"
            name="addUser"
            onChange={() => {
              setAddUser(!addUser);
            }}
          />
        </div>
      ) : (
        <></>
      )}

      {addUser === true ? (
        <>
          <div className="w-full px-1 py-1">
            <Controller
              name={'username'}
              control={control}
              defaultValue={employee ? employee.username : ''}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="ชื่อผู้ใช้"
                  // value={employee ? employee.username : ''}
                  fullWidth
                  size={'small'}
                  helperText={errors.username && 'กรุณาใส่ข้อมูล'}
                />
              )}
            />
          </div>
          <div className="flex w-full">
            <div className="w-1/2 px-1 py-1">
              <Controller
                name={'password'}
                control={control}
                defaultValue={employee ? employee.password : ''}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="รหัสผ่าน"
                    type="password"
                    // value={employee ? employee.password : ''}
                    fullWidth
                    size={'small'}
                    helperText={errors.password && 'กรุณาใส่ข้อมูล'}
                  />
                )}
              />
            </div>
            <div className="w-1/2 px-1 py-1">
              <Controller
                name={'confirmPassword'}
                control={control}
                defaultValue={employee ? employee.password : ''}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="ยืนยันรหัสผ่าน"
                    type="password"
                    // value={employee ? employee.password : ''}
                    fullWidth
                    size={'small'}
                    helperText={errors.password && 'กรุณาใส่ข้อมูล'}
                  />
                )}
              />
            </div>
          </div>
        </>
      ) : (
        <></>
      )}

      <div className="w-1/2 px-1 py-1">
        <Controller
          name={'firstname'}
          control={control}
          defaultValue={employee ? employee.firstname : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ชื่อ"
              required
              // value={employee ? employee.firstname : ''}
              fullWidth
              size={'small'}
              helperText={errors.firstname && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-1/2 px-1 py-1">
        <Controller
          name={'lastname'}
          control={control}
          defaultValue={employee ? employee.lastname : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="นามสกุล"
              required
              // value={employee ? employee.lastname : ''}
              fullWidth
              size={'small'}
              helperText={errors.lastname && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-1">
        <Controller
          name={'phone_number'}
          control={control}
          defaultValue={employee ? employee.phone_number : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="เบอรโทรศัพท์"
              // value={employee ? employee.lastname : ''}
              fullWidth
              size={'small'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-1">
        <Controller
          name={'department'}
          control={control}
          // eslint-disable-next-line no-underscore-dangle
          defaultValue={employee ? employee?.department?._id : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
              <InputLabel id="type" size={'small'}>
                แผนก *
              </InputLabel>
              <Select
                {...field}
                label="แผนก"
                // value={employee ? employee?.department?.id : ''}
                size={'small'}
                required
                fullWidth
              >
                {_.size(department?.rows) ? (
                  _.map(department.rows, (row) => (
                    <MenuItem key={row.id} value={row.id}>
                      {row.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem>
                    <MenuItem disabled value="">
                      <em>ไม่มีข้อมูล</em>
                    </MenuItem>
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          )}
        />
      </div>
      {employee?.image && (
        <div className="w-full px-1 py-2 ">
          <img src={employee?.image?.url} className="h-64" />
        </div>
      )}

      <div className="w-full px-1 py-2">
        <ImageUpload
          preview_size="250"
          maxNumber={1}
          images={employeeImage}
          setImages={setEmployeeImage}
          title={employee?.image ? 'อัพโหลดรูปภาพ' : 'แก้ไขรูปภาพ'}
        />
      </div>
      <Divider />
      {subModuleForm()}
    </div>
  );
}

EmployeeForm.propTypes = {
  department: PropTypes.object,
  roletype: PropTypes.object,
  errors: PropTypes.shape({
    username: PropTypes.object,
    password: PropTypes.object,
    firstname: PropTypes.object,
    lastname: PropTypes.object,
    phone_number: PropTypes.object,
  }),
  employeeImage: PropTypes.arrayOf(PropTypes.object),
  setEmployeeImage: PropTypes.func.isRequired,
  addUser: PropTypes.bool,
  setAddUser: PropTypes.func,
  employee: PropTypes.object,
  Controller: PropTypes.func,
  control: PropTypes.object,
  driverLine: PropTypes.object,
  checkDASHBOARD: PropTypes.array,
  setCheckDASHBOARD: PropTypes.func,
  checkSPM: PropTypes.array,
  setCheckSPM: PropTypes.func,
  checkWMS: PropTypes.array,
  setCheckWMS: PropTypes.func,
  checkHIS: PropTypes.array,
  setCheckHIS: PropTypes.func,
  checkCRM: PropTypes.array,
  setCheckCRM: PropTypes.func,
  checkHMS: PropTypes.array,
  setCheckHMS: PropTypes.func,
  checkSETTING: PropTypes.array,
  setCheckSETTING: PropTypes.func,
};

EmployeeForm.defaultProps = {
  employee: null,
  addUser: null,
};

export default EmployeeForm;
