/* eslint-disable react/jsx-key */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import {
  Backdrop,
  Button,
  Card,
  Fade,
  Modal,
  TextField,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Avatar,
  ToggleButton,
  ToggleButtonGroup,
  IconButton,
} from '@mui/material';

import SaveIcon from '@mui/icons-material/Save';
import AddBoxIcon from '@mui/icons-material/AddBox';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CallIcon from '@mui/icons-material/Call';

import { useForm, Controller } from 'react-hook-form';
import _ from 'lodash';
import PropTypes from 'prop-types';
import * as actions from '../../redux/actions';
import { EmployeeForm } from '../../components/Forms';
import Loading from '../../components/Loading';
import { Error, NotAuthorized } from '../../components/Error';
import accessSubModule from '../../utils/functions/accessSubModule';

import { ViewTitle } from '../../components/ViewTitle';
import { CSVUpload } from '../../components/CSVUpload';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  boxShadow: 24,
  p: 4,
};

const defaultValue = {
  firstname: '',
  lastname: '',
  department: '',
  role: '',
  username: '',
  password: '',
  phone_number: '',
  addedEmployeeImage: '',
};

function Employees({ title, subtitle }) {
  const module = 'HRMS';
  const subModule = 'EMPLOYEE';
  const dispatch = useDispatch();
  const employee = useSelector((state) => state.employee);
  const department = useSelector((state) => state.department);
  const driverLine = useSelector((state) => state.driverLine);
  const roletype = useSelector((state) => state.roletype);
  const me = useSelector((state) => state.me);

  const subAvailableModule = useSelector((state) => state.subAvailableModule);
  const history = useHistory();
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(undefined);
  const [open, setOpen] = useState(false);
  const [addUser, setAddUser] = useState(false);
  const [addedEmployeeImage, setAddedEmployeeImage] = useState([]);
  const [csvUploadOpen, setCsvUploadOpen] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [alignment, setAlignment] = React.useState('list');

  // STATE OD FORM MODULES
  const [checkDASHBOARD, setCheckDASHBOARD] = useState([false]);
  const [checkSPM, setCheckSPM] = useState([false, false, false]);
  const [checkWMS, setCheckWMS] = useState([false, false, false, false]);
  const [checkHIS, setCheckHIS] = useState([false, false]);
  const [checkCRM, setCheckCRM] = useState([false, false]);
  const [checkHMS, setCheckHMS] = useState([false, false, false, false]);
  const [checkSETTING, setCheckSETTING] = useState([false]);

  const handleChangeAlignment = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };

  const handleCSVOpen = () => setCsvUploadOpen(true);
  const handleCSVClose = () => {
    setCsvUploadOpen(false);
    setCsvData([]);
  };

  console.log('employee', employee);
  // console.log('subAvailableModule', subAvailableModule);
  // console.log('roletype', roletype);
  // console.log('driverLine', driverLine);

  // console.log('addedEmployeeImage', addedEmployeeImage);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setAddUser(false);
  };

  useEffect(() => {
    dispatch(actions.meGet());
    dispatch(actions.subAvailableModuleAll());
    dispatch(actions.userReset());
    dispatch(actions.employeeAll({ name, page, size }));
    dispatch(actions.departmentAll(''));
    dispatch(actions.driverLineAll(''));
    dispatch(actions.roletypeAll(''));
    return () => {};
  }, [name, page, size]);

  useEffect(() => {
    setTotal(employee?.total);
    return () => {};
  }, [employee]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 700);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const onSubmit = async (data) => {
    try {
      // eslint-disable-next-line no-param-reassign
      const payload = {
        ...data,
        access: {
          HRMS: {
            access: checkHMS[0] || checkHMS[1] || checkHMS[2] || checkHMS[3],
            EMPLOYEE: {
              access: checkHMS[0],
            },
            DEPARTMENT: {
              access: checkHMS[1],
            },
            ROLETYPE: {
              access: checkHMS[2],
            },
            USER: {
              access: checkHMS[3],
            },
          },
          CRM: {
            access: checkCRM[0] || checkCRM[1],
            CUSTOMER: {
              access: checkCRM[0],
            },
            CUSTOMERTYPE: {
              access: checkCRM[1],
            },
          },
          SPM: {
            access: checkSPM[0] || checkSPM[1] || checkSPM[2],
            IMPORT: {
              access: checkSPM[0],
            },
            EXPORT: {
              access: checkSPM[1],
            },
            RETURN: {
              access: checkSPM[2],
            },
          },
          WMS: {
            access: checkWMS[0] || checkWMS[1] || checkWMS[2] || checkWMS[3],
            PRODUCT: {
              access: checkWMS[0],
            },
            PRODUCTTYPE: {
              access: checkWMS[1],
            },
            BRAND: {
              access: checkWMS[2],
            },
            WAREHOUSE: {
              access: checkWMS[3],
            },
          },
          DASHBOARD: {
            access: checkDASHBOARD[0],
            MAIN: {
              access: checkDASHBOARD[0],
            },
          },
          SETTING: {
            access: checkSETTING[0],
            INFORMATION: {
              access: checkSETTING[0],
            },
          },
          HISTORY: {
            access: checkHIS[0] || checkHIS[1],
            SALEORDER: {
              access: checkHIS[0],
            },
            STOCK: {
              access: checkHIS[1],
            },
          },
        },
        role: _.find(roletype?.rows, (e) => e.name === 'SUPERUSER').id,
        image: addedEmployeeImage[0]?.data_url,
      };
      console.log('payload', payload);
      if (addUser === true) {
        if (data.password === data.confirmPassword) {
          try {
            await dispatch(actions.userRegister(payload));
            reset(defaultValue);
            alert('สำเร็จ');
            handleClose();
            setAddUser(false);
            await dispatch(actions.employeeAll({ name, page, size }));
          } catch (error) {
            alert('ขออภัย มีชื่อผู้ใช้นี้ในระบบแล้ว');
          }
        } else {
          alert('กรุณาใส่รหัสผ่านให้ตรงกัน');
        }
      } else {
        await dispatch(actions.employeeCreate(payload));
        reset(defaultValue);
        alert('สำเร็จ');
        handleClose();
        setAddUser(false);
        await dispatch(actions.employeeAll({ name, page, size }));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteEmployee = async (id) => {
    const confirm = window.confirm('ยืนยันการลบข้อมูล');
    if (confirm) {
      try {
        await dispatch(actions.employeeDelete(id));
        await dispatch(actions.employeeAll({ name, page, size }));
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handlePushToEditEmployee = (id) => {
    history.push(`/hrms/employee/edit/${id}`);
  };

  const handlePushToEditUser = (id) => {
    history.push(`/hrms/user/edit/${id}`);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleAddFromFile = async () => {
    if (!_.isEmpty(csvData)) {
      try {
        await dispatch(actions.employeeCreate({ arr: csvData }));
        alert('สำเร็จ');
        handleCSVClose();
        await dispatch(actions.employeeAll({ name, page, size }));
      } catch (error) {
        alert('เพิ่มพนักงานไม่สำเร็จ');
        console.error(error);
      }
    } else {
      alert('ไม่สามารถอ่านไฟล์รายการลูกค้าได้');
    }
  };

  const renderAddButton = () => (
    <div className="flex flex-row justify-end pb-4 gap-2">
      <Button
        startIcon={<AddBoxIcon />}
        variant="contained"
        onClick={handleOpen}
      >
        เพิ่ม
      </Button>
      <Button
        variant="contained"
        onClick={handleCSVOpen}
        startIcon={<FileUploadIcon />}
      >
        อัพโหลด
      </Button>
    </div>
  );

  const renderSearch = () => (
    <Card>
      <div className="p-4 flex flex-row">
        <div className="w-full md:w-1/2">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="w-full md:w-1/2 flex justify-end">
          <ToggleButtonGroup
            value={alignment}
            exclusive
            onChange={handleChangeAlignment}
          >
            <ToggleButton value="list" aria-label="left aligned" size="small">
              <FormatListNumberedIcon />
            </ToggleButton>
            <ToggleButton value="module" aria-label="centered" size="small">
              <ViewModuleIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </div>
    </Card>
  );

  const renderModal = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Card sx={style} className="max-w-4xl">
          <div className="w-full overflow-y-auto max-h-screen">
            <div className="py-2">เพิ่มข้อมูลพนักงาน</div>
            <div className="py-2">
              <form onSubmit={handleSubmit(onSubmit)}>
                <EmployeeForm
                  control={control}
                  Controller={Controller}
                  errors={errors}
                  employee={employee}
                  department={department}
                  driverLine={driverLine}
                  roletype={roletype}
                  addUser={addUser}
                  setAddUser={setAddUser}
                  employeeImage={addedEmployeeImage}
                  setEmployeeImage={setAddedEmployeeImage}
                  checkDASHBOARD={checkDASHBOARD}
                  setCheckDASHBOARD={setCheckDASHBOARD}
                  checkSPM={checkSPM}
                  setCheckSPM={setCheckSPM}
                  checkWMS={checkWMS}
                  setCheckWMS={setCheckWMS}
                  checkHIS={checkHIS}
                  setCheckHIS={setCheckHIS}
                  checkCRM={checkCRM}
                  setCheckCRM={setCheckCRM}
                  checkHMS={checkHMS}
                  setCheckHMS={setCheckHMS}
                  checkSETTING={checkSETTING}
                  setCheckSETTING={setCheckSETTING}
                />
                <div className="flex flex-row justify-end gap-1 py-8 mb-10">
                  <Button
                    variant="contained"
                    type="submit"
                    startIcon={<SaveIcon />}
                  >
                    บันทึก
                  </Button>
                  <Button variant="outlined" onClick={handleClose}>
                    ยกเลิก
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </Card>
      </Fade>
    </Modal>
  );

  const renderCSVUploadModal = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={csvUploadOpen}
      onClose={handleCSVClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={csvUploadOpen}>
        <Card sx={style} className="max-w-4xl">
          <div className="pt-2 text-xl">อัพโหลดข้อมูล</div>
          <div className="py-2 font-system">
            อัพโหลดไฟล์ .csv โดยมีโครงสร้างตามไฟล์เทมเพลต แถวที่ 1-2
            และแถวสุดท้าย จะไม่ถูกนำมาคิดในระบบ <br />
            <Button variant="contained">
              <Link to="/filetemplate/Employee.csv" target="_blank">
                ดาวน์โหลดเทมเพลต
              </Link>
            </Button>
          </div>
          <div className="my-2">
            <div className="bg-blue-50 p-4 rounded-lg">
              <div className="pb-2">
                * โปรดแทนที่ข้อมูลใน <strong>แผนก</strong> ด้วยรหัสดังต่อไปนี้
              </div>
              <div className="rounded-sm overflow-y-auto">
                <Table
                  sx={{
                    '&:last-child td, &:last-child th': { border: 1 },
                  }}
                >
                  <TableHead>
                    <TableCell>ลำดับ</TableCell>
                    <TableCell>ชื่อแผนก</TableCell>
                    <TableCell>รหัส</TableCell>
                  </TableHead>
                  <TableBody>
                    {_.map(department?.rows, (_department, index) => (
                      <TableRow key={index}>
                        <TableCell>{index + 1} </TableCell>
                        <TableCell>{_department?.name} </TableCell>
                        <TableCell>{_department?.id} </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </div>

            <div className="bg-blue-50 p-4 rounded-lg">
              <div className="pb-2">
                * โปรดแทนที่ข้อมูลใน <strong>บทบาท</strong> ด้วยรหัสดังต่อไปนี้
              </div>
              <div className="rounded-sm overflow-y-auto">
                <Table
                  sx={{
                    '&:last-child td, &:last-child th': { border: 1 },
                  }}
                >
                  <TableHead>
                    <TableCell>ลำดับ</TableCell>
                    <TableCell>ชื่อแผนก</TableCell>
                    <TableCell>รหัส</TableCell>
                  </TableHead>
                  <TableBody>
                    {_.map(roletype?.rows, (_roletype, index) => (
                      <TableRow key={index}>
                        <TableCell>{index + 1} </TableCell>
                        <TableCell>{_roletype?.name} </TableCell>
                        <TableCell>{_roletype?.id} </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </div>
          </div>
          <div className="py-2">
            <div className="flex justify-center">
              <div>
                <CSVUpload setCsvData={setCsvData} />
                {!_.isEmpty(csvData) && (
                  <div>
                    <p className="font-system my-1">
                      พบข้อมูล {_.size(csvData)} รายการ
                    </p>
                    <Button
                      color="primary"
                      variant="contained"
                      type="button"
                      onClick={handleAddFromFile}
                    >
                      บันทึก
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Card>
      </Fade>
    </Modal>
  );

  function stringAvatar(EmployeeName) {
    return {
      children: `${EmployeeName.split(' ')[0][0]}${
        EmployeeName.split(' ')[1][0]
      }`,
    };
  }

  const renderModule = () => (
    <div className="py-2 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
      {!_.isEmpty(employee.rows) ? (
        employee.rows.map((row, index) => (
          <Card
            kay={index}
            className="w-full  cursor-pointer duration-150 ease-in-out transform hover:-translate-y-1  hover:shadow-2xl"
          >
            <div className="text-center">
              <div className="p-4">
                <div className="my-auto pr-2 flex justify-center">
                  {row.image ? (
                    <Avatar
                      alt={`${row?.firstname} ${row?.lastname}`}
                      src={row?.image?.url}
                      sx={{ width: 100, height: 100 }}
                    />
                  ) : (
                    <Avatar
                      sx={{ width: 100, height: 100 }}
                      {...stringAvatar(`${row?.firstname} ${row?.lastname}`)}
                    />
                  )}
                </div>
                <div className="my-auto font-bold mt-2">{`${row?.firstname} ${row?.lastname}`}</div>
                <div>เบอร์โทรศัพท์</div>( {row?.phone_number || '-'} )
                <div>แผนก</div>( {row?.department?.name || '-'} )
              </div>

              <div className="border-t-2 grid grid-cols-2 text-white text-center">
                <div
                  className="bg-yellow-300 hover:bg-yellow-400 py-2"
                  // eslint-disable-next-line no-underscore-dangle
                  onClick={() => handlePushToEditEmployee(row.id)}
                  disabled={
                    row?.firstname === 'SUPER' && row?.lastname === 'USER'
                  }
                >
                  <EditIcon /> แก้ไข
                </div>
                <div
                  className="bg-red-400 hover:bg-red-500 py-2"
                  onClick={() => {
                    handleDeleteEmployee(row?.id);
                  }}
                  disabled={
                    row?.firstname === 'SUPER' && row?.lastname === 'USER'
                  }
                >
                  <DeleteForeverIcon /> ลบ
                </div>
              </div>
              <div className="absolute top-1 right-1">
                <IconButton
                  color="primary"
                  disabled={row?.phone_number === '-' || !row?.phone_number}
                  onClick={() => window.open('tel:0928938956', '_blank')}
                >
                  <CallIcon />
                </IconButton>
              </div>
            </div>
          </Card>
        ))
      ) : (
        <div></div>
      )}
    </div>
  );

  const renderTable = () => (
    <div className="my-2">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ชื่อ-สกุล</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> เบอร์โทร</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> แผนก</div>
                </TableCell>
                {/* <TableCell>
                  <div className="font-bold"> บทบาท</div>
                </TableCell> */}
                <TableCell>
                  <div className="font-bold"> ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(employee.rows) ? (
                employee.rows.map((row, index) => (
                  <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    className={index % 2 === 0 ? 'bg-gray-100' : ''}
                  >
                    <TableCell component="th" scope="row">
                      {(page - 1) * size + index + 1}
                    </TableCell>
                    <TableCell>
                      <div className="flex">
                        <div className="my-auto pr-2">
                          {row.image ? (
                            <Avatar
                              alt={`${row?.firstname} ${row?.lastname}`}
                              src={row?.image?.url}
                            />
                          ) : (
                            <Avatar
                              {...stringAvatar(
                                `${row?.firstname} ${row?.lastname}`,
                              )}
                            />
                          )}
                        </div>
                        <div className="my-auto ">
                          <div className="font-bold">{`${row?.firstname} ${row?.lastname}`}</div>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>{row?.phone_number || '-'}</TableCell>
                    <TableCell>{`${row?.department?.name}`}</TableCell>
                    {/* <TableCell>{`${row?.role?.name}`}</TableCell> */}
                    <TableCell>
                      <div className="flex flex-row flex-wrap gap-1">
                        <Button
                          variant="contained"
                          startIcon={<EditIcon />}
                          size={'small'}
                          // eslint-disable-next-line no-underscore-dangle
                          onClick={() => handlePushToEditUser(row?.uid?._id)}
                          disabled={!row?.uid}
                        >
                          จัดการผู้ใช้
                        </Button>
                        <Button
                          variant="contained"
                          startIcon={<EditIcon />}
                          color={'warning'}
                          size={'small'}
                          onClick={() => handlePushToEditEmployee(row.id)}
                          disabled={
                            row?.firstname === 'SUPER' &&
                            row?.lastname === 'USER'
                          }
                        >
                          แก้ไข
                        </Button>
                        <Button
                          variant="contained"
                          startIcon={<DeleteIcon />}
                          color={'error'}
                          size={'small'}
                          onClick={() => {
                            handleDeleteEmployee(row?.id);
                          }}
                          disabled={
                            row?.firstname === 'SUPER' &&
                            row?.lastname === 'USER'
                          }
                        >
                          ลบ
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25, 100]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={handleChangePage}
        />
      </Paper>
    </div>
  );

  if (employee.isLoading) {
    return <Loading />;
  }
  if (!employee.isLoading && employee.isCompleted) {
    if (accessSubModule(me?.userData?.access, module, subModule)) {
      return (
        <div>
          {renderModal()}
          {renderCSVUploadModal()}
          <div className="flex justify-between">
            <div>{renderTitle()}</div>
            <div className="mt-6">{renderAddButton()}</div>
          </div>
          {renderSearch()}
          {alignment === 'list' ? renderTable() : renderModule()}
        </div>
      );
    }
    return <NotAuthorized />;
  }
  return <Error />;
}

Employees.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

Employees.defaultProps = {
  title: '',
  subtitle: '',
};

export default Employees;
