import CreateOrder from './CreateOrder';
import EditOrder from './EditOrder';
import EditExport from './EditExport';
import Orders from './Orders';
import DetailOrder from './DetailOrder';
import CreateExport from './CreateExport';
import Exports from './Exports';
import DetailExport from './DetailExport';
import Onlines from './Onlines';
import DetailOnline from './DetailOnline';
import CreateBill from './CreateBill';
import OrdersReturn from './OrdersReturn';
import CreateOrderStock from './CreateOrderStock';
import CreateOrderReturn from './CreateOrderReturn';
import EditOrderReturn from './EditOrderReturn';
import DetailOrderReturn from './DetailOrderReturn';

import DetailOrderReturnLog from './DetailOrderReturnLog';
import DetailExportLog from './DetailExportLog';
import DetailOrderLog from './DetailOrderLog';

export {
  CreateOrder,
  Orders,
  DetailOrder,
  CreateExport,
  Exports,
  DetailExport,
  Onlines,
  DetailOnline,
  CreateBill,
  CreateOrderStock,
  EditOrder,
  EditExport,
  CreateOrderReturn,
  EditOrderReturn,
  DetailOrderReturn,
  OrdersReturn,
  DetailOrderReturnLog,
  DetailExportLog,
  DetailOrderLog,
};
export default {
  CreateOrder,
  Orders,
  DetailOrder,
  CreateExport,
  Exports,
  DetailExport,
  Onlines,
  DetailOnline,
  CreateBill,
  CreateOrderStock,
  EditOrder,
  EditExport,
  CreateOrderReturn,
  EditOrderReturn,
  DetailOrderReturn,
  OrdersReturn,
  DetailOrderReturnLog,
  DetailExportLog,
  DetailOrderLog,
};
