import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
// import { io } from 'socket.io-client';
// import dayjs from 'dayjs';

import {
  Backdrop,
  Button,
  Card,
  Fade,
  Modal,
  TextField,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
} from '@mui/material';

// import {
//   GoogleMap,
//   LoadScript,
//   Marker,
//   InfoWindow,
// } from '@react-google-maps/api';
// import MapIcon from '@mui/icons-material/Map';
// ref : https://react-google-maps-api-docs.netlify.app/
// ref : https://www.npmjs.com/package/@react-google-maps/api

import SaveIcon from '@mui/icons-material/Save';
import AddBoxIcon from '@mui/icons-material/AddBox';
import EditIcon from '@mui/icons-material/Edit';
import DescriptionIcon from '@mui/icons-material/Description';
import DeleteIcon from '@mui/icons-material/Delete';
import { useForm, Controller } from 'react-hook-form';
import _ from 'lodash';
import PropTypes from 'prop-types';
import * as actions from '../../redux/actions';
import { CustomerForm } from '../../components/Forms';
import Loading from '../../components/Loading';
import { Error, NotAuthorized } from '../../components/Error';
import accessSubModule from '../../utils/functions/accessSubModule';
import { ViewTitle } from '../../components/ViewTitle';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  boxShadow: 24,
  p: 4,
};

const defaultValues = {
  type: '',
  organization_type: '',
  name: '',
  address: '',
  taxes: '',
  phone_number: '',
  fax: '',
  email: '',
  payment: '',
  source: '',
};

function Customers({ title, subtitle }) {
  const module = 'CRM';
  const subModule = 'CUSTOMER';
  const dispatch = useDispatch();
  const customer = useSelector((state) => state.customer);
  const customerType = useSelector((state) => state.customerType);
  const setting = useSelector((state) => state.setting);
  const me = useSelector((state) => state.me);

  const history = useHistory();
  const {
    formState: { errors },
    handleSubmit,
    control,
    reset,
  } = useForm();
  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(25);
  const [total, setTotal] = useState(undefined);
  const [open, setOpen] = useState(false);
  const [selectCustomer, setSelectCustomer] = useState('');
  const [addSupplier, setAddSupplier] = useState(false);
  console.log('setting in customer', setting);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    reset(defaultValues);
  };

  useEffect(() => {
    dispatch(actions.meGet());
    dispatch(actions.userReset());
    dispatch(
      actions.customerAll({
        name,
        page,
        size,
        selectCustomer,
      }),
    );
    dispatch(actions.customerTypeAll(''));
    dispatch(actions.settingAll());
    return () => {};
  }, [name, page, size, selectCustomer]);

  useEffect(() => {
    setTotal(customer?.total);
    return () => {};
  }, [customer]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 700);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const onSubmit = async (data, event) => {
    try {
      console.log('data', data);
      const dataEnd = {
        ...data,
        user_type: 'customer',
      };
      console.log('data', data);
      console.log('dataEnd', dataEnd);
      event.preventDefault();
      await dispatch(actions.customerCreate(dataEnd));
      reset(defaultValues);
      alert('สำเร็จ');
      handleClose();

      await dispatch(actions.customerAll({ name, page, size }));
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteCustomer = async (id) => {
    const confirm = window.confirm('ยืนยันการลบข้อมูล');
    if (confirm) {
      try {
        await dispatch(actions.customerDelete(id));
        await dispatch(actions.customerAll({ name, page, size }));
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handlePushToDetailCustomer = async (id) => {
    history.push(`/crm/customer/${id}`);
  };

  const handlePushToEditCustomer = async (id) => {
    history.push(`/crm/customer/edit/${id}`);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeType = (event) => {
    console.log('type customer', event.target.value);
    setSelectCustomer(event.target.value);
  };

  const renderAddButton = () => (
    <div className="flex flex-row justify-end pb-4 gap-2">
      <Button
        startIcon={<AddBoxIcon />}
        variant="contained"
        onClick={handleOpen}
      >
        สร้าง
      </Button>
    </div>
  );

  const renderSearch = () => (
    <Card>
      <div className="p-4 flex flex-row">
        <div className="w-full md:w-1/2">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="w-full md:w-1/2 pl-1">
          <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
            <InputLabel id="type" size={'small'}>
              ประเภทลูกค้า
            </InputLabel>
            <Select
              label="ประเภทลูกค้า"
              size={'small'}
              value={selectCustomer}
              onChange={handleChangeType}
              fullWidth
            >
              <MenuItem value={''}>แสดงทั้งหมด</MenuItem>
              {_.size(customerType.rows) ? (
                _.map(customerType.rows, (row) => (
                  <MenuItem key={row.id} value={row.id}>
                    {row.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem>
                  <MenuItem disabled value="">
                    <em>ไม่มีข้อมูล</em>
                  </MenuItem>
                </MenuItem>
              )}
            </Select>
          </FormControl>
        </div>
      </div>
    </Card>
  );

  const renderModal = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Card sx={style} className="max-w-4xl">
          <div className="w-full overflow-y-auto max-h-screen">
            <div className="py-2">เพิ่มข้อมูล</div>
            <div className="py-2">
              <form onSubmit={handleSubmit(onSubmit)}>
                <CustomerForm
                  Controller={Controller}
                  control={control}
                  errors={errors}
                  customerType={customerType}
                  addSupplier={addSupplier}
                  setAddSupplier={setAddSupplier}
                />
                <div className="flex flex-row justify-end gap-1 py-4">
                  <Button
                    variant="contained"
                    type="submit"
                    startIcon={<SaveIcon />}
                  >
                    บันทึก
                  </Button>
                  <Button variant="outlined" onClick={handleClose}>
                    ยกเลิก
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </Card>
      </Fade>
    </Modal>
  );

  const renderTable = () => (
    <div className="my-2">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ชื่อ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ประเภท</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(customer.rows) ? (
                customer.rows.map((row, index) => (
                  <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    className={index % 2 === 0 ? 'bg-gray-100' : ''}
                  >
                    <TableCell component="th" scope="row">
                      {(page - 1) * size + index + 1}
                    </TableCell>
                    <TableCell>
                      <div>{`${row?.name}`}</div>
                      {row?.code_name && <div>({`${row?.code_name}`})</div>}
                    </TableCell>
                    <TableCell>{`${row?.type?.name || '-'}`}</TableCell>
                    <TableCell>
                      <div className="flex flex-row flex-wrap gap-1">
                        <Button
                          variant="contained"
                          startIcon={<DescriptionIcon />}
                          color={'info'}
                          size={'small'}
                          onClick={() => handlePushToDetailCustomer(row.id)}
                        >
                          รายละเอียด
                        </Button>
                        <Button
                          variant="contained"
                          startIcon={<EditIcon />}
                          color={'warning'}
                          size={'small'}
                          onClick={() => handlePushToEditCustomer(row.id)}
                        >
                          แก้ไข
                        </Button>
                        <Button
                          variant="contained"
                          startIcon={<DeleteIcon />}
                          color={'error'}
                          size={'small'}
                          onClick={() => {
                            handleDeleteCustomer(row?.id);
                          }}
                        >
                          ลบ
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25, 100]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={handleChangePage}
        />
      </Paper>
    </div>
  );

  if (customer.isLoading) {
    return <Loading />;
  }
  if (!customer.isLoading && customer.isCompleted) {
    if (accessSubModule(me?.userData?.access, module, subModule)) {
      return (
        <div>
          {renderModal()}
          <div className="flex justify-between">
            <div>{renderTitle()}</div>
            <div className="mt-6">{renderAddButton()}</div>
          </div>
          {renderSearch()}
          {renderTable()}
        </div>
      );
    }
    return <NotAuthorized />;
  }
  return <Error />;
}

Customers.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

Customers.defaultProps = {
  title: '',
  subtitle: '',
};

export default Customers;
