/* eslint-disable no-underscore-dangle */
/* eslint-disable arrow-body-style */
/* eslint-disable operator-linebreak */
import React, { useEffect, useState } from 'react';
import { TextField, Button, Tooltip, Autocomplete } from '@mui/material';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { useForm, useFieldArray } from 'react-hook-form';
import CreateProduct from '../../Modal/CreateProduct';

import { AddSerialNumberDialog, AddOptionDialog } from '../../Dialogs';

import { SPM } from '../../../utils/Proptypes';

const resetValues = {
  product: '',
  amount: '',
};

export const ExportProductForm = ({
  errors,
  Controller,
  product,
  filterProduct,
  setFilterProduct,
  productList,
  setProductList,
  setReload,
  reload,
  setSelectWarehouse,
  selectWarehouse,
}) => {
  const [isModalCreateProductOpen, setIsModalCreateProductOpen] = useState(
    false,
  );
  const handleOpen = () => setIsModalCreateProductOpen(true);
  const handleClose = () => {
    setIsModalCreateProductOpen(false);
  };
  const [productLists, setProductLists] = useState([]);
  const { getValues, control, reset, setValue } = useForm({
    mode: 'onBlur',
  });

  console.log('selectWarehouse in product form', selectWarehouse);
  console.log('productLists in product form', productLists);
  console.log('filterProduct in product form', filterProduct);

  useEffect(() => {
    if (selectWarehouse === null) {
      setProductLists([]);
    } else {
      const NewProductLists = _.map(selectWarehouse.inventory, (ep, index) => ({
        index: index + 1,
        ...ep.product,
        stock: ep.stock,
      }));
      setProductLists(NewProductLists);
    }
    setValue(null);
    setFilterProduct(null);
    // setReload(!reload);
    return () => {};
  }, [selectWarehouse]);

  useEffect(() => () => {}, [reload]);

  // useEffect(() => {
  //   console.log('change productLists1', productLists);
  //   console.log('change productLists1.5', productList);
  //   console.log('change productLists2', filterProduct);

  //   const findProduct = _.map(productLists, (p) => {
  //     if (p?._id === filterProduct?._id) {
  //       const findProduct2 = _.map(
  //         productList,
  //         (p2) => p2?._id === filterProduct?._id,
  //       );
  //       return {
  //         ...p,
  //         inventory: p?.stock - parseInt(findProduct2?.amount || 0, 10),
  //       };
  //     }
  //     return p;
  //   });

  //   console.log('findProduct', findProduct);
  //   setProductLists(findProduct);

  //   return () => {};
  // }, [filterProduct, productList]);

  const handleAddProduct = () => {
    if (getValues('amount') === '' || !getValues('product')) {
      alert('กรุณาใส่ข้อมูลให้ครบถ้วน');
    } else {
      // eslint-disable-next-line no-lonely-if
      if (getValues('amount') > filterProduct?.stock) {
        alert('สินค้ามีจำนวนคงเหลือไม่พอ');
      } else {
        const data = {
          product: filterProduct,
          amount: getValues('amount'),
          warehouse: selectWarehouse,
        };
        console.log('handleAddProduct', data);
        productList.push(data);
        setProductList(productList);
        reset(resetValues);
        setReload(!reload);
      }
    }
  };

  const filterOptions = (options, { inputValue }) => {
    return options.filter((option) => {
      const text = `${option.name}${option.type_code}${
        option?.model_name || ''
      }`.toLowerCase();
      return text.includes(inputValue.toLowerCase());
    });
  };

  return (
    <div>
      <CreateProduct
        isOpen={isModalCreateProductOpen}
        handleOnClose={handleClose}
      />
      <div className="flex flex-row flex-wrap">
        <div className="w-full px-1 py-2 flex ">
          <div className="flex-grow">
            <Controller
              name={'product'}
              control={control}
              rules={{ required: false }}
              render={({ field }) => (
                <Autocomplete
                  size={'small'}
                  {...field}
                  onChange={(e, newValue) => {
                    field.onChange(newValue);
                    setFilterProduct(newValue);
                    setReload(!reload);
                  }}
                  options={productLists}
                  filterOptions={filterOptions} // ใช้ฟังก์ชันการกรองที่กำหนดเอง
                  getOptionLabel={(option) =>
                    // eslint-disable-next-line implicit-arrow-linebreak
                    `${option?.index} : ${option?.name} ${
                      option?.model_name && `( ${option?.model_name} )`
                    }  ( เหลือสินค้า ${option?.stock}  ${option?.unit} )`
                  }
                  autoHighlight
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="สินค้า"
                      onKeyDown={(event) => {
                        // ใช้งานเพื่อตรวจสอบปัญหาที่อาจเกิดขึ้นกับการจัดการ onKeyDown อื่นๆ
                        if (
                          event.key === 'Enter' &&
                          params.InputProps.onKeyDown
                        ) {
                          params.InputProps.onKeyDown(event);
                        }
                      }}
                    />
                  )}
                />
              )}
            />
          </div>
          <div className="my-auto pl-1  flex-none">
            <Tooltip
              title="เพิ่มสินค้ากรณีที่ยังไม่มีข้อมูลในระบบ"
              placement="bottom"
            >
              <Button variant="outlined" onClick={handleOpen}>
                เพิ่มสินค้า
              </Button>
            </Tooltip>
          </div>
        </div>
        <div className="w-full px-1 py-2">
          <Controller
            name={'amount'}
            control={control}
            rules={{ required: false }}
            defaultValue={''}
            render={({ field }) => (
              <TextField
                {...field}
                label="จำนวน"
                fullWidth
                type="number"
                size={'small'}
                // eslint-disable-next-line react/prop-types
                helperText={errors.inventory && 'กรุณาใส่ข้อมูล'}
              />
            )}
          />
        </div>
        <div className="w-full px-1 py-2">
          <Button
            variant="outlined"
            className="w-full"
            onClick={() => handleAddProduct()}
          >
            เพิ่ม
          </Button>
        </div>
      </div>
    </div>
  );
};

ExportProductForm.propTypes = {
  errors: PropTypes.shape({
    product_transaction_type: PropTypes.object,
    orderNumber: PropTypes.billNumber,
    customer: PropTypes.array,
    source: PropTypes.object,
    quantity: PropTypes.object,
    price: PropTypes.object,
    product_stock_lots: PropTypes.object,
    warehouse: PropTypes.object,
    product_type: PropTypes.array,
    productionDate: PropTypes.object,
    expirationDate: PropTypes.object,
    receiptDate: PropTypes.object,
    sellDate: PropTypes.object,
    remark: PropTypes.object,
    recipientName: PropTypes.object,
    sellName: PropTypes.object,
    trackingNumber: PropTypes.object,
    sellPrice: PropTypes.object,
    shippingCost: PropTypes.object,
    discount: PropTypes.object,
    payment: PropTypes.payment,
  }),
  product: PropTypes.array,
  productType: PropTypes.array,
  productTransactionType: PropTypes.array,
  order: PropTypes.oneOf([SPM.Order, SPM.OrderArray]),
  Controller: PropTypes.func,
  control: PropTypes.object,
  type: PropTypes.object,
  setType: PropTypes.func,
  selectProduct: PropTypes.array,
  setSelectProduct: PropTypes.func,
  filterProduct: PropTypes.object,
  setFilterProduct: PropTypes.func,
  customers: PropTypes.array,
  departments: PropTypes.object,
  watch: PropTypes.func.isRequired,
  products: PropTypes.object,
  selectedProducts: PropTypes.array,
  setSelectedProducts: PropTypes.func,
  employees: PropTypes.array,
  productTypes: PropTypes.object,
  productList: PropTypes.array,
  setProductList: PropTypes.func,
  reload: PropTypes.bool,
  setReload: PropTypes.func,
  selectSerial: PropTypes.array,
  setSelectSerial: PropTypes.func,
  selectOption: PropTypes.array,
  setSelectOption: PropTypes.func,
  setSelectWarehouse: PropTypes.func,
  selectWarehouse: PropTypes.object,
};

ExportProductForm.defaultProps = {
  product: null,
  productType: null,
  productTransactionType: null,
  customer: null,
  department: null,
  me: null,
  type: null,
  selectProduct: null,
  filterProduct: null,
};

export default ExportProductForm;
