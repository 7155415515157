import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, TextField } from '@mui/material';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import * as actions from '../../redux/actions';
import { BackButton } from '../../components/Button';
import { Loading } from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';

export default function DetailWarehouse({ title, subtitle }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const warehouse = useSelector((state) => state.warehouse);

  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    dispatch(actions.warehouseGet(id));
    return () => {};
  }, []);

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  console.log(
    'warehouse bb',
    (warehouse?.inventory || []).filter(
      (eachItem) =>
        // eslint-disable-next-line implicit-arrow-linebreak
        (eachItem?.product?.name || '-')
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        eachItem?.product?.type_code.includes(searchTerm) ||
        (eachItem?.product?.model_name || '-')
          .toLowerCase()
          .includes(searchTerm.toLowerCase()),
    ),
  );

  if (warehouse.isLoading || warehouse.rows) {
    return <Loading />;
  }
  if (!warehouse.isLoading && warehouse.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
          <div className="text-lg font-semibold ml-6">ข้อมูลประเภทสินค้า</div>
        </div>
        <div>
          <Card className="p-4 ">
            <div className="flex flex-wrap divide-y">
              <div className="w-full py-4 text-lg font-semibold ">
                {'รายละเอียด'}
              </div>
              <div className="w-1/2 py-4 px-2 font-semibold ">{'ชื่อ'}</div>
              <div className="w-1/2 py-4 ">{`${warehouse.name || '-'}`}</div>
              <div className="w-1/2 py-4 px-2 font-semibold ">{'ที่อยู่'}</div>
              <div className="w-1/2 py-4 ">{`${warehouse.address || '-'}`}</div>
            </div>
          </Card>
          <Card className="p-4 mt-4">
            <div className="w-full py-4 text-lg font-semibold ">
              {'รายการสินค้า'}
            </div>
            <TextField
              label="ค้นหาสินค้าด้วย ชื่อ รหัสสินค้า หรือบาร์โค๊ด"
              variant="outlined"
              fullWidth
              value={searchTerm}
              onChange={handleChange}
            />
            <div className="grid grid-cols-4 gap-2 px-1 mt-2 p-2">
              {_.map(
                (warehouse?.inventory || []).filter(
                  (eachItem) =>
                    // eslint-disable-next-line implicit-arrow-linebreak
                    (eachItem?.product?.name || '-')
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase()) ||
                    (eachItem?.product?.type_code || '-').includes(
                      searchTerm,
                    ) ||
                    (eachItem?.product?.model_name || '-')
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase()),
                ),
                (eachProduct, index) => (
                  <div
                    key={index}
                    className="p-3 flex flex-col border border-gray-200 rounded-md h-40 justify-between cursor-pointer shadow-lg"
                  >
                    <div>
                      <div className="font-bold text-gray-800 truncate">
                        {eachProduct?.product?.name}
                      </div>
                      <div className=" text-gray-800 truncate">
                        {eachProduct?.product?.model_name}
                      </div>
                    </div>
                    <div className="flex flex-row justify-between items-center">
                      <span className="self-end font-bold text-xl text-yellow-500">
                        {eachProduct?.stock} {eachProduct?.product?.unit}
                      </span>
                      {_.isEmpty(eachProduct?.images) ? (
                        <img
                          key={index}
                          src="https://storage.googleapis.com/mydev_eonlineshop/other/thumbnails/1645339249181_800x800.png"
                          alt={eachProduct?.name}
                          className=" h-20 w-20 object-cover rounded-md"
                        ></img>
                      ) : (
                        <img
                          src={`${eachProduct?.images[0]?.url}`}
                          alt={eachProduct?.name}
                          className=" h-20 w-20 object-cover rounded-md"
                        />
                      )}
                    </div>
                  </div>
                ),
              )}
            </div>
          </Card>
        </div>
      </div>
    );
  }
  return <Error />;
}

DetailWarehouse.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DetailWarehouse.defaultProps = {
  title: '',
  subtitle: '',
};
