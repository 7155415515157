import React from 'react';
import PropTypes from 'prop-types';

function LowerMenu({ me, availableModule }) {
  return (
    <div className="min-h-screen bg-white">
      <div className="h-96"></div>
      <div className="container mr-auto ml-auto">3</div>
    </div>
  );
}

export default LowerMenu;

LowerMenu.propTypes = {
  me: PropTypes.object,
  availableModule: PropTypes.arrayOf(PropTypes.object),
  informationLayout: PropTypes.object,
};
