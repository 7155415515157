import { combineReducers } from 'redux';

import NotifyReducer from './features/NotifyReducer';
import InformationReducer from './common/InformationReducer';

// Common
import UserReducers from './common/UserReducers';
import MeReducers from './common/MeReducers';
import AvailableModuleReducer from './common/AvailableModuleReducer';
import SubAvailableModuleReducer from './common/SubAvailableModuleReducer';

import SettingReducer from './common/SettingReducer';
// Feature
import CustomerReducer from './features/CustomerReducer';
import CustomerTypeReducer from './features/CustomerTypeReducer';
import DepartmentReducer from './features/DepartmentReducer';
import RoleTypesReducer from './features/RoleTypesReducer';
import EmployeeReducer from './features/EmployeeReducer';
import SEOManagementReducer from './features/SEOManagementReducer';

// คลังวัตถุดิบ
import MaterialTypeReducer from './features/MaterialTypeReducer';
import MaterialReducer from './features/MaterialReducer';
import MaterialTransactionTypeReducer from './features/MaterialTransactionTypeReducer';
import PlaceReducer from './features/PlaceReducer';
import MaterialStockLotReducer from './features/MaterialStockLotReducer';

// คลังสินค้า
import WarehouseReducer from './features/WarehouseReducer';
import ProductTypeReducer from './features/ProductTypeReducer';
import ProductReducer from './features/ProductReducer';
import BrandReducer from './features/BrandReducer';
import PolicyReducer from './features/PolicyReducer';
import ProductTransactionTypeReducer from './features/ProductTransactionTypeReducer';
import ProductTransactionReducer from './features/ProductTransactionReducer';
import ProductTransactionReturnReducer from './features/ProductTransactionReturnReducer';
import ProductTransactionExportReducer from './features/ProductTransactionExportReducer';
import ProductStockLotReducer from './features/ProductStockLotReducer';
import StockCheckingReducers from './features/StockCheckingReducers';

// logs
import ProductTransactionLogReducer from './features/ProductTransactionLogReducer';
import ProductTransactionReturnLogReducer from './features/ProductTransactionReturnLogReducer';
import ProductTransactionExportLogReducer from './features/ProductTransactionExportLogReducer';

// การผลิต
import OrderReducer from './features/OrderReducer';
import ProcessTemplateReducer from './features/ProcessTemplateReducer';
import ProcessOfOrderReducer from './features/ProcessOfOrderReducer';

// จัดการหน้าเว็บ
import InfoReducers from './features/InfoReducers';
// การแก้ไขสต๊อก
import LogReducers from './features/LogReducers';

// serial Number
import SerialReducers from './features/SerialReducers';

// payment
import PaymentTransactionReducer from './features/PaymentTransactionReducer';
import OnlineOrderReducer from './features/OnlineOrderReducer';

import OptionReducers from './features/OptionReducers';

import PortfolioReducer from './features/PortfolioReducer';

import DeliveryPriceReducer from './features/DeliveryPriceReducers';
import DeliveryProviderReducer from './features/DeliveryProviderReducers';

import PromotionWithCustomerTypeReducer from './features/PromotionWithCustomerTypeReducer';

import PromotionWithProductTypeReducer from './features/PromotionWithProductTypeReducer';

import DiscountPaymentReducer from './features/DiscountPaymentReducer';

import PromotionWithWholesaleReducer from './features/PromotionWithWholesaleReducer';
import PromotionBillPriceReducer from './features/PromotionBillPriceReducer';
import CouponReducer from './features/CouponReducer';

import OrderMessageReducer from './features/OrderMessageReducer';

import SponsorReducer from './features/SponsorReducer';

import SettingLayoutReducer from './features/SettingLayoutReducer';
import CourseReducer from './features/CourseReducer';
import BookingReducer from './features/BookingReducer';
import DriverLineReducer from './features/DriverLineReducer';

const rootReducer = combineReducers({
  me: MeReducers,
  portfolio: PortfolioReducer,
  setting: SettingReducer,
  user: UserReducers,
  customer: CustomerReducer,
  customerType: CustomerTypeReducer,
  department: DepartmentReducer,
  roletype: RoleTypesReducer,
  employee: EmployeeReducer,
  informationLayout: InformationReducer,
  notify: NotifyReducer,
  orderMessage: OrderMessageReducer,
  materialType: MaterialTypeReducer,
  material: MaterialReducer,
  materialTransactionType: MaterialTransactionTypeReducer,
  place: PlaceReducer,
  materialStockLot: MaterialStockLotReducer,
  order: OrderReducer,
  processOfOrderReducer: ProcessOfOrderReducer,
  processTemplate: ProcessTemplateReducer,
  warehouse: WarehouseReducer,
  logs: LogReducers,
  productType: ProductTypeReducer,
  brand: BrandReducer,
  policy: PolicyReducer,
  product: ProductReducer,
  productTransactionType: ProductTransactionTypeReducer,
  productTransaction: ProductTransactionReducer,
  productTransactionReturn: ProductTransactionReturnReducer,
  productTransactionExport: ProductTransactionExportReducer,
  productTransactionLog: ProductTransactionLogReducer,
  productTransactionReturnLog: ProductTransactionReturnLogReducer,
  productTransactionExportLog: ProductTransactionExportLogReducer,
  paymentTransaction: PaymentTransactionReducer,
  OnlineOrder: OnlineOrderReducer,
  productStockLot: ProductStockLotReducer,
  information: InfoReducers,
  serialNumber: SerialReducers,
  availableModule: AvailableModuleReducer,
  subAvailableModule: SubAvailableModuleReducer,
  option: OptionReducers,
  deliveryPrice: DeliveryPriceReducer,
  deliveryProvider: DeliveryProviderReducer,
  promotionCustomerType: PromotionWithCustomerTypeReducer,
  promotionProductType: PromotionWithProductTypeReducer,
  discountPayment: DiscountPaymentReducer,
  promotionWholesale: PromotionWithWholesaleReducer,
  promotionBillPrice: PromotionBillPriceReducer,
  SEOManagement: SEOManagementReducer,
  stockChecking: StockCheckingReducers,
  sponsor: SponsorReducer,
  settingLayout: SettingLayoutReducer,
  coupon: CouponReducer,
  course: CourseReducer,
  booking: BookingReducer,
  driverLine: DriverLineReducer,
});
export default rootReducer;
