import { DOMImplementation, XMLSerializer } from 'xmldom';
import JsBarcode from 'jsbarcode';

const xmlSerializer = new XMLSerializer();

const document = new DOMImplementation().createDocument(
  'http://www.w3.org/1999/xhtml',
  'html',
  null,
);
const svgNode = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
console.log('svgNode', svgNode);
const BarcodeGenerator = (word) => {
  JsBarcode(svgNode, word, {
    xmlDocument: document,
  });

  return xmlSerializer.serializeToString(svgNode);
};

export default BarcodeGenerator;
