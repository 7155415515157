/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import dayjs from 'dayjs';

import {
  Card,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

import { useForm, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
// eslint-disable-next-line no-unused-vars
import _ from 'lodash';
import * as actions from '../../redux/actions';
import { ExportForm, ExportProductForm } from '../../components/Forms';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
import { BackButton } from '../../components/Button';

const defaultValues = {
  type_code: '',
  name: '',
  description: '',
};

const CreateExport = ({ title, subtitle }) => {
  const dispatch = useDispatch();
  const customer = useSelector((state) => state.customer);
  const me = useSelector((state) => state.me);
  const product = useSelector((state) => state.product);
  const productType = useSelector((state) => state.productType);
  const employee = useSelector((state) => state.employee);
  const warehouse = useSelector((state) => state.warehouse);

  const history = useHistory();
  const [reload, setReload] = useState(false);
  const [productList, setProductList] = useState([]);
  const [selectSerial, setSelectSerial] = useState([]);
  const [selectOption, setSelectOption] = useState([]);
  const [createLoading, setCreateLoading] = useState(false);
  const [checkReserveOrder, setCheckReserveOrder] = useState(false);
  const [uploadedImage, setUploadedImage] = useState('');

  // Setting React hook form
  const {
    formState: { errors },
    handleSubmit,
    control,
    watch,
    reset,
  } = useForm();

  const [name] = useState('');
  const [page] = useState(1);
  const [size] = useState(5);
  const [type, setType] = useState(null);
  const [filterProduct, setFilterProduct] = useState(null);
  const [selectWarehouse, setSelectWarehouse] = useState(null);

  console.log('selectWarehouse', selectWarehouse);

  const [defaultMe, setDefaultMe] = useState(me?.userData);

  console.log('productList in main', productList);

  useEffect(() => {
    dispatch(actions.productAll(''));
    dispatch(actions.warehouseAll(''));
    dispatch(actions.employeeAll(''));
    dispatch(actions.productTypeAll(''));
    dispatch(actions.customerAll(''));
    dispatch(actions.meGet());
    setDefaultMe(me?.userData);

    return () => {};
  }, [name, page, size]);

  useEffect(() => {
    if (selectWarehouse === null) {
      setProductList([]);
    }

    return () => {};
  }, [selectWarehouse]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  // console.log('productList', productList);
  console.log('product', product);

  const onSubmit = async (data, event) => {
    if (productList?.length === 0) {
      alert('กรุณาเพิ่มสินค้าก่อน');
    } else {
      console.log('data on submit', data);
      console.log('productList on submit', productList);
      setCreateLoading(true);
      try {
        const preprocessedData = {
          ...data,
          order_type: 'EXPORT',
          status_export: 'WAIT_FOR_EXPORT',
          export_employee: data.employee || {
            ...defaultMe,
            _id: defaultMe?._id,
          },
          reserve_order: checkReserveOrder,
          reserve_status: 'WAIT_FOR_EXPORT',
          reserve_date:
            data.date || dayjs(new Date()).locale('th').format('YYYY-MM-DD'),
          reserve_expire_date:
            data.reserve_expire_date ||
            dayjs(new Date()).locale('th').format('YYYY-MM-DD'),
          export_date:
            data.date || dayjs(new Date()).locale('th').format('YYYY-MM-DD'),
          orders: _.map(productList, (each) => ({
            product: each.product._id,
            amount: each.amount,
            warehouse: each.warehouse._id,
          })),
          images: _.map(uploadedImage, (image) => ({
            image: image.data_url,
          })),
          modify_employee: me?.userData?._id,
        };
        // console.log('Data', data);
        console.log('preprocessedData', preprocessedData);
        event.preventDefault();
        await dispatch(
          actions.productTransactionExportCreate(preprocessedData),
        );
        reset(defaultValues);
        setCreateLoading(false);
        alert('สำเร็จ');

        await dispatch(
          actions.productTransactionExportAll({ name, page, size }),
        );
        history.goBack();
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleDeleteProduct = (index) => {
    productList.splice(index, 1);
    setProductList(productList);
    setReload(!reload);
  };

  const handleChangeCheckReserveOrder = async (event) => {
    setCheckReserveOrder(event.target.checked);
  };

  const renderForm = () => (
    <Card className="p-6">
      <div className="py-2">เพิ่มข้อมูล</div>
      <ExportForm
        Controller={Controller}
        control={control}
        errors={errors}
        watch={watch}
        customers={customer.rows}
        product={product.rows}
        productType={productType.rows}
        employees={employee.rows}
        warehouse={warehouse.rows}
        type={type}
        setType={setType}
        filterProduct={filterProduct}
        setFilterProduct={setFilterProduct}
        me={me}
        setSelectWarehouse={setSelectWarehouse}
        selectWarehouse={selectWarehouse}
        checkReserveOrder={checkReserveOrder}
        handleChangeCheckReserveOrder={handleChangeCheckReserveOrder}
        uploadedImage={uploadedImage}
        setUploadedImage={setUploadedImage}
      />
    </Card>
  );

  const renderAddProductForm = () => (
    <Card className="p-6">
      <div className="py-2">เลือกสินค้า</div>
      <ExportProductForm
        Controller={Controller}
        control={control}
        errors={errors}
        product={product.rows}
        type={type}
        setType={setType}
        filterProduct={filterProduct}
        setFilterProduct={setFilterProduct}
        productList={productList}
        setProductList={setProductList}
        reload={reload}
        setReload={setReload}
        selectSerial={selectSerial}
        setSelectSerial={setSelectSerial}
        setSelectOption={setSelectOption}
        selectOption={selectOption}
        setSelectWarehouse={setSelectWarehouse}
        selectWarehouse={selectWarehouse}
      />
    </Card>
  );

  const displayProduct = () => (
    <TableContainer>
      <Table size="small" className="border-lg">
        <TableHead className="bg-yellow-100">
          <TableRow>
            <TableCell>
              <div className="font-bold">ลำดับที่</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> คลังสินค้า</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> ชื่อสินค้า</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> จำนวน</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> ดำเนินการ</div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!_.isEmpty(productList) ? (
            productList.map((_product, index) => (
              <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                className={index % 2 === 0 ? 'bg-gray-100' : ''}
              >
                <TableCell>{index + 1}</TableCell>
                <TableCell>{_product?.warehouse.name}</TableCell>
                <TableCell>
                  {_product?.product.name} ( {_product?.product.model_name} )
                </TableCell>
                <TableCell>
                  {_product?.amount} {_product?.unit}
                </TableCell>
                <TableCell>
                  <Button
                    color={'error'}
                    variant="contained"
                    size={'small'}
                    onClick={() => handleDeleteProduct(index)}
                  >
                    ลบ
                  </Button>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow key={''}>
              <TableCell colSpan={6}>
                <div className="text-center">ไม่มีรายการ</div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderShowProductList = () => (
    <Card className="p-6">
      <div className="py-2">รายการสินค้า</div> {displayProduct()}
    </Card>
  );

  const handleKeyDown = (event) => {
    // ตรวจสอบว่าปุ่มที่กดคือ Enter หรือไม่
    if (event.key === 'Enter') {
      event.preventDefault(); // ป้องกันการ submit ของฟอร์ม
      // คุณสามารถทำสิ่งอื่นๆ ที่นี่ถ้าจำเป็น
    }
  };

  if (
    customer.isLoading ||
    product.isLoading ||
    employee.isLoading ||
    createLoading
  ) {
    return <Loading />;
  }
  if (
    !product.isLoading &&
    product.isCompleted &&
    !customer.isLoading &&
    customer.isCompleted &&
    !employee.isLoading &&
    employee.isCompleted &&
    !createLoading
  ) {
    return (
      <div>
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
        </div>
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
          <div className="text-lg font-semibold ml-6">สร้างการส่งออกสินค้า</div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} onKeyDown={handleKeyDown}>
          <div className="md:flex">
            <div className="w-full md:w-1/3 pr-4">{renderForm()}</div>
            <div className="w-full md:w-2/3 pt-4 md:pt-0">
              {renderAddProductForm()}
              <div className="w-full pt-4">{renderShowProductList()}</div>
            </div>
          </div>
          <div className="flex flex-row justify-end gap-1 py-4">
            <Button variant="contained" type="submit" startIcon={<SaveIcon />}>
              บันทึก
            </Button>
          </div>
        </form>
      </div>
    );
  }
  return <Error message={customer?.message} />;
};

CreateExport.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

CreateExport.defaultProps = {
  title: '',
  subtitle: '',
};

export default CreateExport;
