/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

import { useForm, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { ViewTitle } from '../../components/ViewTitle';
import * as actions from '../../redux/actions';
import { InformationForm } from '../../components/Forms';
import Loading from '../../components/Loading';
import { Error, NotAuthorized } from '../../components/Error';
import accessSubModule from '../../utils/functions/accessSubModule';

function Information({ title, subtitle }) {
  const module = 'SETTING';
  const subModule = 'INFORMATION';
  const dispatch = useDispatch();
  const setting = useSelector((state) => state.setting);
  const me = useSelector((state) => state.me);

  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm();

  const [logoImage, setLogoImage] = useState([]);

  const [QRPromptpay1, setQRPromptpay1] = useState([]);
  const [QRPromptpay2, setQRPromptpay2] = useState([]);
  const [checkPromptPay, setCheckPromptPay] = useState(
    setting?.promptpay?.available,
  );
  const [ShowQRPromptpay1, setShowQRPromptpay1] = useState(
    setting?.promptpay?.ShowQrCode,
  );
  const [checkPromptPay2, setCheckPromptPay2] = useState(
    setting?.promptpay2?.available,
  );
  const [checkPayment2c2p, setCheckPayment2c2p] = useState(
    setting?.payment2c2p?.available,
  );
  const [checkBanking, setCheckBanking] = useState(setting?.banking?.available);
  const [taxInclude, setTaxInclude] = useState(setting?.tax_include);
  const [taxExclude, setTaxExclude] = useState(setting?.tax_exclude);
  console.log('texInclude', setting?.tax_exclude);
  console.log('taxExclude', taxExclude);
  const [frontweb, setFrontWeb] = useState(setting?.frontweb?.available);
  const [channelpay, setChannelPay] = useState(
    setting?.channelpayment?.available,
  );
  const [checkBanking2, setCheckBanking2] = useState(
    setting?.banking2?.available,
  );
  const [checkBanking3, setCheckBanking3] = useState(
    setting?.banking3?.available,
  );

  console.log('setting', setting);

  const [checkDeposit, setCheckDeposit] = useState(setting?.deposit?.available);
  const [checkNoteReceipt, setCheckNoteReceipt] = useState(
    setting?.noteReceipt?.available,
  );
  const [checkTopic, setCheckTopic] = useState(setting?.noteReceipt?.topic);
  const handleChangeCheckPromptPay = (event) => {
    setCheckPromptPay(event.target.checked);
  };
  const handleChangeCheckShowQRPromptpay = (event) => {
    setShowQRPromptpay1(event.target.checked);
  };
  const handleChangeCheckPromptPay2 = (event) => {
    setCheckPromptPay2(event.target.checked);
  };
  const handleChangeCheckPayment2c2p = (event) => {
    setCheckPayment2c2p(event.target.checked);
  };

  const handleChangeCheckBanking = (event) => {
    setCheckBanking(event.target.checked);
  };
  const handleChangeCheckBanking2 = (event) => {
    setCheckBanking2(event.target.checked);
  };
  const handleChangeCheckBanking3 = (event) => {
    setCheckBanking3(event.target.checked);
  };

  const handleChangeCheckDeposit = (event) => {
    setCheckDeposit(event.target.checked);
  };
  const handleChangeCheckNoteReceipt = (event) => {
    setCheckNoteReceipt(event.target.checked);
  };
  const handleChangeCheckTopic = (event) => {
    setCheckTopic(event.target.checked);
  };

  const handleChangeCheckTaxInclude = (event) => {
    setTaxInclude(event.target.checked);
  };

  const handleChangeCheckTaxExclude = (event) => {
    setTaxExclude(event.target.checked);
  };
  const handleChangeCheckfrontWeb = (event) => {
    setFrontWeb(event.target.checked);
  };
  const handleChangeCheckchannelPay = (event) => {
    setChannelPay(event.target.checked);
  };
  console.log('frontweb', frontweb);

  useEffect(() => {
    dispatch(actions.meGet());
    dispatch(actions.settingAll());
    return () => {};
  }, []);

  // console.log(
  //   'setting?.payment2c2p?.available',
  //   setting?.payment2c2p?.available,
  // );

  console.log('checkPayment2c2p', checkPayment2c2p);
  useEffect(() => {
    setCheckPromptPay(setting?.promptpay?.available);
    setCheckPromptPay2(setting?.promptpay2?.available);
    setCheckBanking(setting?.banking?.available);
    setCheckBanking2(setting?.banking2?.available);
    setCheckBanking3(setting?.banking3?.available);
    setCheckPayment2c2p(setting?.payment2c2p?.available);
    setShowQRPromptpay1(setting?.promptpay?.ShowQrCode);
    setCheckDeposit(setting?.deposit?.available);
    setCheckNoteReceipt(setting?.noteReceipt?.available);
    setCheckTopic(setting?.noteReceipt?.topic);
    setQRPromptpay1([setting?.promptpay?.QrCode]);
    setQRPromptpay2([setting?.promptpay2?.QrCode]);
    return () => {};
  }, [setting]);

  const onSubmit = async (data, event) => {
    console.log('22222', data);
    try {
      const payload = {
        ...data,
        tax_exclude: false,
        tax_include: false,
        tax_number: 0,
        frontweb: {
          url: '',
          available: false,
        },
        channelpayment: {
          namepay: '',
          namepayeng: '',
          available: false,
        },
        banking: {
          accountName: '',
          bankName: '',
          accountNumber: '',
          available: false,
        },
        banking2: {
          accountName: '',
          bankName: '',
          accountNumber: '',
          available: false,
        },

        promptpay: {
          accountName: '',
          accountNumber: '',
          available: false,
          ShowQrCode: false,
          QrCode: QRPromptpay1[0]?.url
            ? QRPromptpay1[0]?.id
            : QRPromptpay1[0]?.data_url
            ? {
                image: QRPromptpay1[0]?.data_url,
                imageType: 'QRPromptpay1',
                alt: '',
              }
            : null,
        },
        payment2c2p: {
          invoiceNo: '',
          available: false,
        },
        deposit: {
          percent: '',
          available: false,
        },
        noteReceipt: {
          available: false,
          note: '',
          topic: false,
        },
        images: _.map(setting?.banner, (image) => ({
          // eslint-disable-next-line no-underscore-dangle
          id: image.image_id,
          image: image.data_url || image.image.url,
        })),
      };

      console.log('payload on setting', payload);

      if (!_.isEmpty(logoImage)) {
        payload.logo = {
          image: logoImage[0]?.data_url,
          imageType: 'logo',
          alt: '',
        };
      }

      event.preventDefault();
      await dispatch(actions.settingPut(setting?.id, payload));
      setLogoImage([]);
      alert('สำเร็จ');
      await dispatch(actions.settingAll());
    } catch (error) {
      console.error(error);
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderForm = () => (
    <form onSubmit={handleSubmit(onSubmit)}>
      <InformationForm
        Controller={Controller}
        control={control}
        errors={errors}
        information={setting}
        selectedImage={logoImage}
        setSelectedImage={setLogoImage}
        handleChangeCheckDeposit={handleChangeCheckDeposit}
        handleChangeCheckBanking={handleChangeCheckBanking}
        handleChangeCheckBanking2={handleChangeCheckBanking2}
        handleChangeCheckBanking3={handleChangeCheckBanking3}
        handleChangeCheckPromptPay={handleChangeCheckPromptPay}
        handleChangeCheckPromptPay2={handleChangeCheckPromptPay2}
        handleChangeCheckPayment2c2p={handleChangeCheckPayment2c2p}
        handleChangeCheckTaxInclude={handleChangeCheckTaxInclude}
        handleChangeCheckTaxExclude={handleChangeCheckTaxExclude}
        handleChangeCheckShowQRPromptpay={handleChangeCheckShowQRPromptpay}
        handleChangeCheckNoteReceipt={handleChangeCheckNoteReceipt}
        handleChangeCheckchannelPay={handleChangeCheckchannelPay}
        handleChangeCheckTopic={handleChangeCheckTopic}
        handleChangeCheckfrontWeb={handleChangeCheckfrontWeb}
        checkDeposit={checkDeposit}
        checkNoteReceipt={checkNoteReceipt}
        checkBanking={checkBanking}
        ShowQRPromptpay1={ShowQRPromptpay1}
        checkBanking2={checkBanking2}
        checkBanking3={checkBanking3}
        checkPromptPay={checkPromptPay}
        checkPromptPay2={checkPromptPay2}
        checkPayment2c2p={checkPayment2c2p}
        QRPromptpay1={QRPromptpay1}
        QRPromptpay2={QRPromptpay2}
        channelpay={channelpay}
        checkTopic={checkTopic}
        setCheckTopic={setCheckTopic}
        setShowQRPromptpay1={setShowQRPromptpay1}
        setCheckNoteReceipt={setCheckNoteReceipt}
        setQRPromptpay1={setQRPromptpay1}
        setQRPromptpay2={setQRPromptpay2}
        frontweb={frontweb}
        taxInclude={taxInclude}
        taxExclude={taxExclude}
      />
      <div className="flex flex-row justify-end gap-1 py-4">
        <Button variant="contained" type="submit" startIcon={<SaveIcon />}>
          บันทึก
        </Button>
      </div>
    </form>
  );

  if (setting.isLoading) {
    return <Loading />;
  }
  if (!setting.isLoading && setting.isCompleted) {
    if (accessSubModule(me?.userData?.access, module, subModule)) {
      return (
        <div>
          {renderTitle()}
          {renderForm()}
        </div>
      );
    }
    return <NotAuthorized />;
  }
  return <Error />;
}
Information.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

Information.defaultProps = {
  title: '',
  subtitle: '',
};

export default Information;
