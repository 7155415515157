/* eslint-disable consistent-return */
import React from 'react';
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  PRODUCT_TRANSACTION_TYPE,
  PRODUCT_STOCK_STATUS,
} from '../../../utils/constants';

export function ProductStockForm({
  errors,
  Controller,
  control,
  product,
  productType,
  warehouse,
  productTransactionType,
  productStockLot,
  customer,
  // department,
  // me,
  type,
  setType,
  selectProduct,
  setSelectProduct,
  filterProduct,
  setFilterProduct,
  filterWarehouse,
  setFilterWarehouse,
}) {
  // console.log('me', me);
  console.log('customer', customer);
  console.log('type', type);
  console.log('selectProduct', selectProduct);
  console.log('filterProduct', filterProduct);
  console.log('filterWarehouse', filterWarehouse);

  const checkType = (data) => {
    const findType = _.find(productTransactionType, { id: data });
    setType(findType);
  };

  const checkProduct = (data) => {
    const findProduct = _.find(product, { id: data });
    setSelectProduct(findProduct);
  };

  const checkProductType = (data) => {
    const findproductType = _.find(productType, { id: data });
    setFilterProduct(findproductType);
  };

  const checkWarehouse = (data) => {
    const findWarehouse = _.find(warehouse, { id: data });
    setFilterWarehouse(findWarehouse);
  };

  const productOptions = () => {
    if (type?.direction === PRODUCT_TRANSACTION_TYPE[0].direction) {
      return (
        <div className="w-full flex flex-row flex-wrap">
          <div className="w-full px-1 py-2">
            <Controller
              name={'orderNumber'}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="เลขที่สั่งผลิต"
                  fullWidth
                  size={'small'}
                  // helperText={errors.orderNumber && 'กรุณาใส่ข้อมูล'}
                />
              )}
            />
          </div>
          <div className="w-full px-1 py-2">
            <Controller
              name={'customer'}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
                  <InputLabel id="type" size={'small'}>
                    คู่ค้า
                  </InputLabel>
                  <Select {...field} label="คู่ค้า" size={'small'} fullWidth>
                    {_.size(customer) ? (
                      _.map(customer, (_customer) => (
                        <MenuItem key={_customer.id} value={_customer.id}>
                          {_customer.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem>
                        <MenuItem disabled value="">
                          <em>ไม่มีข้อมูล</em>
                        </MenuItem>
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              )}
            />
          </div>

          <div className="w-full lg:w-1/2 px-1 py-2">
            <Controller
              name={'product_type'}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
                  <InputLabel id="type" size={'small'}>
                    ประเภทสินค้า
                  </InputLabel>
                  <Select
                    {...field}
                    label="ประเภทสินค้า"
                    size={'small'}
                    fullWidth
                    onChange={(e) => {
                      field.onChange(e.target.value);
                      checkProductType(e.target.value);
                    }}
                  >
                    {_.size(productType) ? (
                      _.map(productType, (_type) => (
                        <MenuItem key={_type.id} value={_type.id}>
                          {_type.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem>
                        <MenuItem disabled value="">
                          <em>ไม่มีข้อมูล</em>
                        </MenuItem>
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              )}
            />
          </div>
          <div className="w-full lg:w-1/2 px-1 py-2">
            <Controller
              name={'product'}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
                  <InputLabel id="type" size={'small'}>
                    สินค้า
                  </InputLabel>
                  <Select
                    {...field}
                    label="สินค้า"
                    size={'small'}
                    fullWidth
                    onChange={(e) => {
                      field.onChange(e.target.value);
                      checkProduct(e.target.value);
                    }}
                  >
                    {_.size(product) ? (
                      _.map(product, (_product) => {
                        if (_product?.product_type?.id === filterProduct?.id) {
                          return (
                            <MenuItem key={_product.id} value={_product.id}>
                              {_product.name}
                            </MenuItem>
                          );
                        }
                      })
                    ) : (
                      <MenuItem>
                        <MenuItem disabled value="">
                          <em>ไม่มีข้อมูล</em>
                        </MenuItem>
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              )}
            />
          </div>
          <div className="w-full lg:w-1/2 px-1 py-2">
            <Controller
              name={'price'}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="ราคาต้นทุน"
                  fullWidth
                  size={'small'}
                  helperText={errors.price && 'กรุณาใส่ข้อมูล'}
                />
              )}
            />
          </div>
          <div className="w-full lg:w-1/2 px-1 py-2">
            <Controller
              name={'quantity'}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="จำนวน"
                  fullWidth
                  size={'small'}
                  helperText={errors.quantity && 'กรุณาใส่ข้อมูล'}
                />
              )}
            />
          </div>
          <div className="w-full lg:w-1/3 px-1 py-2">
            <label>วันที่ผลิต</label>
            <Controller
              name={'productionDate'}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="date"
                  fullWidth
                  size={'small'}
                  helperText={errors.productionDate && 'กรุณาใส่ข้อมูล'}
                />
              )}
            />
          </div>
          <div className="w-full lg:w-1/3 px-1 py-2">
            <label>วันหมดอายุ</label>
            <Controller
              name={'expirationDate'}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="date"
                  fullWidth
                  size={'small'}
                  helperText={errors.expirationDate && 'กรุณาใส่ข้อมูล'}
                />
              )}
            />
          </div>
          <div className="w-full lg:w-1/3 px-1 py-2">
            <label>วันเข้าคลัง</label>
            <Controller
              name={'receiptDate'}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="date"
                  fullWidth
                  size={'small'}
                  helperText={errors.receiptDate && 'กรุณาใส่ข้อมูล'}
                />
              )}
            />
          </div>
          <div className="w-full px-1 py-2">
            <Controller
              name={'recipientName'}
              control={control}
              rules={{ required: true }}
              // defaultValue={
              //   me
              //     ? me?.rows?.name
              //     : ''
              // }
              render={({ field }) => (
                <TextField
                  {...field}
                  label="ชื่อผู้รับสินค้า"
                  fullWidth
                  size={'small'}
                  helperText={errors.recipientName && 'กรุณาใส่ข้อมูล'}
                />
              )}
            />
          </div>
          <div className="w-full lg:w-full px-1 py-2">
            <Controller
              name={'remark'}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="หมายเหตุ"
                  fullWidth
                  size={'small'}
                  multiline={true}
                  rows={3}
                  helperText={errors.remark && 'กรุณาใส่ข้อมูล'}
                />
              )}
            />
          </div>
        </div>
      );
      // eslint-disable-next-line no-else-return
    } else if (type?.direction === PRODUCT_TRANSACTION_TYPE[1].direction) {
      return (
        <div className="w-full flex flex-row flex-wrap">
          <div className="w-full lg:w-1/2 px-1 py-2">
            <Controller
              name={'product_type'}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
                  <InputLabel id="type" size={'small'}>
                    ประเภทสินค้า
                  </InputLabel>
                  <Select
                    {...field}
                    label="ประเภทสินค้า"
                    size={'small'}
                    fullWidth
                    onChange={(e) => {
                      field.onChange(e.target.value);
                      checkProductType(e.target.value);
                    }}
                  >
                    {_.size(productType) ? (
                      _.map(productType, (_type) => (
                        <MenuItem key={_type.id} value={_type.id}>
                          {_type.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem>
                        <MenuItem disabled value="">
                          <em>ไม่มีข้อมูล</em>
                        </MenuItem>
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              )}
            />
          </div>
          <div className="w-full lg:w-1/2 px-1 py-2">
            <Controller
              name={'product'}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
                  <InputLabel id="type" size={'small'}>
                    สินค้า
                  </InputLabel>
                  <Select
                    {...field}
                    label="สินค้า"
                    size={'small'}
                    fullWidth
                    onChange={(e) => {
                      field.onChange(e.target.value);
                      checkProduct(e.target.value);
                    }}
                  >
                    {_.size(product) ? (
                      _.map(product, (stock) => (
                        <MenuItem key={stock.id} value={stock.id}>
                          {stock.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem>
                        <MenuItem disabled value="">
                          <em>ไม่มีข้อมูล</em>
                        </MenuItem>
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              )}
            />
          </div>
          <div className="w-full lg:w-1/2 px-1 py-2">
            <Controller
              name={'product_stock_lot'}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
                  <InputLabel id="type" size={'small'}>
                    ล็อตสินค้า
                  </InputLabel>
                  <Select
                    {...field}
                    label="ล็อตสินค้า"
                    size={'small'}
                    fullWidth
                  >
                    {_.size(productStockLot) ? (
                      _.map(productStockLot, (_productStockLot) => {
                        if (
                          _productStockLot.status !==
                            PRODUCT_STOCK_STATUS.OUT_OF_STOCK &&
                          _productStockLot?.product_type?.id === product?.id
                        ) {
                          return (
                            <MenuItem
                              key={_productStockLot.id}
                              value={_productStockLot.id}
                            >
                              วันที่{' '}
                              {new Date(
                                _productStockLot?.recording_date,
                              ).toLocaleDateString('th', {
                                day: '2-digit',
                                month: '2-digit',
                                year: 'numeric',
                              })}{' '}
                              คงเหลือ {_productStockLot?.amount}{' '}
                              {_productStockLot?.product_type?.unit}
                            </MenuItem>
                          );
                        }
                      })
                    ) : (
                      <MenuItem>
                        <MenuItem disabled value="">
                          <em>ไม่มีข้อมูล</em>
                        </MenuItem>
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              )}
            />
          </div>
          <div className="w-full lg:w-1/2 px-1 py-2">
            <Controller
              name={'quantity'}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="จำนวน"
                  fullWidth
                  size={'small'}
                  helperText={errors.quantity && 'กรุณาใส่ข้อมูล'}
                />
              )}
            />
          </div>
          <div className="w-full lg:w-1/3 px-1 py-2">
            <Controller
              name={'payment'}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
                  <InputLabel id="type" size={'small'}>
                    ประเภทการชำระเงิน
                  </InputLabel>
                  <Select
                    {...field}
                    label="ประเภทการชำระเงิน"
                    size={'small'}
                    fullWidth
                  >
                    <MenuItem value="เงินสด">เงินสด</MenuItem>
                    <MenuItem value="เครดิต">เครดิต</MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </div>
          <div className="w-full lg:w-1/3 px-1 py-2">
            <Controller
              name={'sellPrice'}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="ราคาขาย"
                  fullWidth
                  size={'small'}
                  helperText={errors.sellPrice && 'กรุณาใส่ข้อมูล'}
                />
              )}
            />
          </div>
          <div className="w-full lg:w-1/3 px-1 py-2">
            <Controller
              name={'discount'}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="ส่วนลด"
                  fullWidth
                  size={'small'}
                  helperText={errors.discount && 'กรุณาใส่ข้อมูล'}
                />
              )}
            />
          </div>
          <div className="w-full lg:w-1/2 px-1 py-2">
            <label>วันที่เบิก</label>
            <Controller
              name={'issueDate'}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="date"
                  fullWidth
                  size={'small'}
                  helperText={errors.receiptDate && 'กรุณาใส่ข้อมูล'}
                />
              )}
            />
          </div>
          <div className="w-full lg:w-1/2 px-1 py-2">
            <label>วันที่ขาย</label>
            <Controller
              name={'sellDate'}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="date"
                  fullWidth
                  size={'small'}
                  helperText={errors.sellDate && 'กรุณาใส่ข้อมูล'}
                />
              )}
            />
          </div>
          <div className="w-full lg:w-1/3 px-1 py-2">
            <Controller
              name={'send_type'}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
                  <InputLabel id="type" size={'small'}>
                    ประเภทการจัดส่ง
                  </InputLabel>
                  <Select
                    {...field}
                    label="ประเภทการจัดส่ง"
                    size={'small'}
                    fullWidth
                  >
                    <MenuItem value="รับเอง">รับเอง</MenuItem>
                    <MenuItem value="จัดส่ง">จัดส่ง</MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </div>
          <div className="w-full lg:w-1/3 px-1 py-2">
            <Controller
              name={'trackingNumber'}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="หมายเลขติดตามสินค้า"
                  fullWidth
                  size={'small'}
                  helperText={errors.trackingNumber && 'กรุณาใส่ข้อมูล'}
                />
              )}
            />
          </div>
          <div className="w-full lg:w-1/3 px-1 py-2">
            <Controller
              name={'shippingCost'}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="ค่าจัดส่ง"
                  fullWidth
                  size={'small'}
                  helperText={errors.shippingCost && 'กรุณาใส่ข้อมูล'}
                />
              )}
            />
          </div>
          <div className="w-full px-1 py-2">
            <Controller
              name={'sellName'}
              control={control}
              rules={{ required: true }}
              // defaultValue={
              //   me
              //     ? me?.rows?.name
              //     : ''
              // }
              render={({ field }) => (
                <TextField
                  {...field}
                  label="ชื่อพนักงานขาย"
                  fullWidth
                  size={'small'}
                  helperText={errors.sellName && 'กรุณาใส่ข้อมูล'}
                />
              )}
            />
          </div>
          <div className="w-full lg:w-full px-1 py-2">
            <Controller
              name={'remark'}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="หมายเหตุ"
                  fullWidth
                  size={'small'}
                  multiline={true}
                  rows={3}
                  helperText={errors.remark && 'กรุณาใส่ข้อมูล'}
                />
              )}
            />
          </div>
        </div>
      );
    }
  };

  return (
    <div className="flex flex-row flex-wrap">
      <div className="w-full px-1 py-2">
        <Controller
          name={'product_transaction_type'}
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
              <InputLabel id="type" size={'small'}>
                ประเภทการดำเนินการ
              </InputLabel>
              <Select
                {...field}
                label="ประเภทการดำเนินการ"
                size={'small'}
                fullWidth
                onChange={(e) => {
                  field.onChange(e.target.value);
                  checkType(e.target.value);
                }}
              >
                {_.size(productTransactionType) ? (
                  _.map(productTransactionType, (_productTransactionType) => (
                    <MenuItem
                      key={_productTransactionType.id}
                      value={_productTransactionType.id}
                    >
                      {_productTransactionType.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem>
                    <MenuItem disabled value="">
                      <em>ไม่มีข้อมูล</em>
                    </MenuItem>
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'warehouse'}
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
              <InputLabel id="type" size={'small'}>
                คลัง
              </InputLabel>
              <Select
                {...field}
                label="คลัง"
                size={'small'}
                fullWidth
                onChange={(e) => {
                  field.onChange(e.target.value);
                  checkWarehouse(e.target.value);
                }}
              >
                {_.size(warehouse) ? (
                  _.map(warehouse, (_warehouse) => (
                    <MenuItem key={_warehouse.id} value={_warehouse.id}>
                      {_warehouse.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem>
                    <MenuItem disabled value="">
                      <em>ไม่มีข้อมูล</em>
                    </MenuItem>
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          )}
        />
      </div>
      {productOptions()}
    </div>
  );
}

ProductStockForm.propTypes = {
  errors: PropTypes.shape({
    product_transaction_type: PropTypes.object,
    orderNumber: PropTypes.billNumber,
    customer: PropTypes.object,
    source: PropTypes.object,
    quantity: PropTypes.object,
    price: PropTypes.object,
    product_stock_lots: PropTypes.object,
    warehouse: PropTypes.object,
    product_type: PropTypes.object,
    productionDate: PropTypes.object,
    expirationDate: PropTypes.object,
    receiptDate: PropTypes.object,
    sellDate: PropTypes.object,
    remark: PropTypes.object,
    recipientName: PropTypes.object,
    sellName: PropTypes.object,
    trackingNumber: PropTypes.object,
    sellPrice: PropTypes.object,
    shippingCost: PropTypes.object,
    discount: PropTypes.object,
    payment: PropTypes.payment,
  }),
  product: PropTypes.object,
  productType: PropTypes.object,
  productTransactionType: PropTypes.object,
  productStockLot: PropTypes.object,
  warehouse: PropTypes.object,
  customer: PropTypes.object,
  department: PropTypes.object,
  me: PropTypes.object,
  type: PropTypes.object,
  setType: PropTypes.func,
  selectProduct: PropTypes.object,
  setSelectProduct: PropTypes.func,
  filterProduct: PropTypes.object,
  setFilterProduct: PropTypes.func,
  filterWarehouse: PropTypes.object,
  setFilterWarehouse: PropTypes.func,
  Controller: PropTypes.func,
  control: PropTypes.object,
};

ProductStockForm.defaultProps = {
  product: null,
  productType: null,
  productTransactionType: null,
  productStockLot: null,
  warehouse: null,
  customer: null,
  department: null,
  me: null,
  type: null,
  selectProduct: null,
  filterProduct: null,
  filterWarehouse: null,
};

export default ProductStockForm;
