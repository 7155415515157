/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Modal,
  Backdrop,
  Fade,
  FormControl,
  MenuItem,
  InputLabel,
  TextField,
  Autocomplete,
  InputAdornment,
} from '@mui/material';

import { Trash2 as Delete } from 'react-feather';

import Select, { SelectChangeEvent } from '@mui/material/Select';

import CancelIcon from '@mui/icons-material/Cancel';

import { red } from '@mui/material/colors';

import { useForm, Controller, useFieldArray } from 'react-hook-form';

import PrintIcon from '@mui/icons-material/Print';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import ReceiptIcon from '@mui/icons-material/Receipt';

import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
// import { m } from 'framer-motion';

// import io from 'socket.io-client';

import MessageCard from '../../components/card/OrderMessage/MessageCard';

import * as actions from '../../redux/actions';
import { BackButton } from '../../components/Button';
import { Loading } from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
import CoverPrint from '../../components/PdfFile/Cover';
import CoverVerticalPrint from '../../components/PdfFile/CoverVertical';
import QuotationPrint from '../../components/PdfFile/quotation';
import InvoicePrint from '../../components/PdfFile/Invoice';
import PurchasePrint from '../../components/PdfFile/Purchase';

import * as constants from '../../utils/constants';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  boxShadow: 24,
  p: 4,
};

const defaultValue = {
  tracking_number: '',
};

export default function DetailOnline({ title, subtitle }) {
  // const socket = useRef();
  const dispatch = useDispatch();
  const { id } = useParams();

  const me = useSelector((state) => state.me);
  const product = useSelector((state) => state.product);
  const option = useSelector((state) => state.option);
  const setting = useSelector((state) => state.setting);
  const paymentTransaction = useSelector((state) => state.paymentTransaction);

  const orderMessage = useSelector((state) => state.orderMessage);
  const [openTrack, setOpenTrack] = useState(false);
  const [testReset, setTestReset] = useState(0);

  const [onlineUsers, setOnlineUsers] = useState([]);
  const [messageList, setMessageList] = useState([]);

  const [loadingStatus, setLoadingStatus] = useState(false);

  const NewProduct = _.map(product?.rows, (eachProduct, index) => ({
    ...eachProduct,
    index: index + 1,
  }));

  console.log('paymentTransaction', paymentTransaction);
  console.log('setting', setting);

  // const [reload, setReload] = useState(false);

  const { control, handleSubmit, reset, setValue, watch } = useForm({
    mode: 'onBlur',
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'itemList',
  });

  // useEffect(() => {
  //   socket.current = io(process.env.REACT_APP_SOCKET_URL);
  //   socket.current.emit('new-user-add', me?.userData?.id || me?.userData?._id);
  //   socket.current.on('get-users', (users) => {
  //     console.log('users online', users);
  //     setOnlineUsers(users);
  //   });
  // }, [me]);

  // useEffect(() => {
  //   socket.current.on('receive-message', (data) => {
  //     // console.log('paymentTransaction in receive-message', paymentTransaction);
  //     // eslint-disable-next-line no-underscore-dangle
  //     if (data === id) {
  //       console.log('receive-message', data, paymentTransaction);
  //       // eslint-disable-next-line no-underscore-dangle
  //       if (paymentTransaction?.order_id?._id) {
  //         dispatch(
  //           // eslint-disable-next-line no-underscore-dangle
  //           actions.orderMessageAll({
  //             // eslint-disable-next-line no-underscore-dangle
  //             order: paymentTransaction?.order_id?._id,
  //           }),
  //         );
  //         console.log('setMessageList', orderMessage?.rows);
  //         setMessageList(orderMessage?.rows);
  //       }
  //     }
  //   });
  // }, []);

  const handleOpenTrack = () => setOpenTrack(true);
  const handleCloseTrack = () => setOpenTrack(false);

  useEffect(() => {
    setMessageList(orderMessage?.rows);
  }, [orderMessage]);
  // const socket = io.connect(process.env.REACT_APP_SOCKET_URL);

  // socket.on('connect', () => {
  //   // console.log('socket connect online');
  //   socket.on('new-message', (data) => {
  //     //   console.log('socket log', data);
  //     setReload(true);
  //   });
  // });

  // console.log('orderMessage', orderMessage);

  const [openSlip, setOpenSlip] = useState(false);

  const handleOpenSlip = () => {
    setOpenSlip(true);
  };
  const handleCloseSlip = () => {
    setOpenSlip(false);
  };

  const [openCreditCard, setOpenCreditCard] = useState(false);

  const handleOpenCreditCard = () => {
    setOpenCreditCard(true);
  };
  const handleCloseCreditCard = () => {
    setOpenCreditCard(false);
  };

  const [openEditProduct, setOpenEditProduct] = useState(false);

  const handleOpenEditProduct = () => {
    setOpenEditProduct(true);
  };
  const handleCloseEditProduct = () => {
    setOpenEditProduct(false);
  };

  // useEffect(() => {
  //   socket.on('receive-message', (data) => {
  //     console.log('receive-message', data);
  //     dispatch(
  //       // eslint-disable-next-line no-underscore-dangle
  //       actions.orderMessageAll({ order: paymentTransaction?.order_id?._id }),
  //     );
  //   });
  // }, [socket]);

  // console.log('paymentTransaction', paymentTransaction);
  console.log('setting', setting);
  const { rows } = product;

  useEffect(() => {
    const fetchPaymentTransaction = async () => {
      try {
        if (id) {
          await dispatch(actions.paymentTransactionGet(id, 1));
        }

        await dispatch(actions.productAll(''));
        await dispatch(actions.getAllOption(''));
        dispatch(actions.meGet());
        dispatch(actions.settingAll());
      } catch (error) {
        console.error(error);
      }
    };

    fetchPaymentTransaction();
    // console.log(
    //   'paymentTransaction orders',
    //   paymentTransaction?.order_id?.orders,
    // );
    // setValue('itemList', paymentTransaction?.order_id?.orders);
    return () => {};
  }, [loadingStatus]);

  useEffect(() => {
    // console.log('product in useE', rows);
    const NewOrders = _.map(
      paymentTransaction?.order_id?.orders,
      (order, index) => {
        const findProduct = _.find(
          rows,
          (eachProduct) => eachProduct?.id === order?.product?._id,
        );
        // console.log('findProduct', index, findProduct);
        const payload = {
          ...order,
          product: findProduct,
          discount: order?.discount_percent || 0,
        };
        // console.log('payload in useE', payload);
        return payload;
      },
    );
    // console.log('NewOrders', NewOrders);
    setValue('itemList', NewOrders);

    return () => {};
  }, [paymentTransaction, rows, testReset]);

  useEffect(() => {
    // console.warn('FETCH ORDER MESSAGE', paymentTransaction?.order_id?._id);
    // eslint-disable-next-line no-underscore-dangle
    if (paymentTransaction?.order_id?._id) {
      dispatch(
        // eslint-disable-next-line no-underscore-dangle
        actions.orderMessageAll({ order: paymentTransaction?.order_id?._id }),
      );
    }

    return () => {};
  }, [paymentTransaction]);

  const checkProduct = (productId) => {
    const productData = _.find(rows, { id: productId });
    return productData;
  };

  const checkOption = (optionId) => {
    const optionData = _.find(option?.rows, { id: optionId });
    return optionData;
  };

  const ArrayProductList = _.map(
    paymentTransaction?.order_id?.orders,
    (eachOrder) => ({
      ...eachOrder,
    }),
  );

  const TotalDiscountPrice = (orderlist) => {
    const sumprice = _.sumBy(orderlist, (e) => e?.discount * e?.quantity);
    // console.log(_.map(orderlist, (e) => e?.discount * e?.quantity));
    return sumprice;
  };

  const handlePrintQuotation = (data) => {
    console.log('data print', data);
    console.log('setting', setting);
    console.log('ArrayProductList', ArrayProductList);
    console.log('paymentTransaction', paymentTransaction);

    QuotationPrint(data, setting, ArrayProductList);
  };

  console.log('ArrayProductList', ArrayProductList);
  console.log('paymentTransaction', paymentTransaction);
  const handlePrintCover = () => {
    CoverPrint(paymentTransaction, setting, 'online');
  };

  const handlePrintCoverVertical = () => {
    CoverVerticalPrint(paymentTransaction, setting, 'online');
  };
  const handlePrintInvoice = () => {
    alert('ขออภัย กำลังพัฒนา');
    // InvoicePrint(paymentTransaction, setting, ArrayProductList);
  };
  const handlePrintPurchase = () => {
    alert('ขออภัย กำลังพัฒนา');
    // PurchasePrint(paymentTransaction, setting, ArrayProductList);
  };
  const handlePrintAll = () => {
    alert('ขออภัย กำลังพัฒนา');
    // CoverPrint(paymentTransaction, setting);
    // InvoicePrint(paymentTransaction, setting, ArrayProductList);
    // PurchasePrint(paymentTransaction, setting, ArrayProductList);
  };

  const orderMessageProp = {
    handleSubmitMessage: async (data) => {
      try {
        const dataSubmit = {
          ...data,
          // eslint-disable-next-line no-underscore-dangle
          online_order: paymentTransaction?.order_id?._id,
          employee: me?.userData?.id || me?.userData?._id,
        };
        console.log(
          'dataSubmit',
          dataSubmit,
          paymentTransaction?.customer?._id,
        );
        await dispatch(actions.orderMessageCreate(dataSubmit));
        // socket.current.emit(
        //   'send-message',
        //   id,
        //   paymentTransaction?.customer?._id,
        // );

        // eslint-disable-next-line no-underscore-dangle
        if (paymentTransaction?.order_id?._id) {
          // eslint-disable-next-line no-underscore-dangle
          dispatch(
            // eslint-disable-next-line no-underscore-dangle
            actions.orderMessageAll({
              // eslint-disable-next-line no-underscore-dangle
              order: paymentTransaction?.order_id?._id,
            }),
          );
        }
      } catch (error) {
        console.log('error', error);
      }
    },
    me,
  };

  const changeStatus = async (Sid, data, tel) => {
    setLoadingStatus(true);
    const payload = {
      status_order: data,
      tel,
    };

    console.log('payload and id', payload, Sid);

    await dispatch(actions.paymentTransactionPut(Sid, payload));
    // socket.current.emit('new-order', 'new-status');
    if (id) {
      await dispatch(actions.paymentTransactionGet(id, 2));
    }
    setLoadingStatus(false);
  };

  const handleCutStock = async (data, orderId, customer) => {
    // const confirm = window.confirm(
    //   'จัดเตรียมสินค้าเรียบร้อย จะทำการตัดสต็อกสินค้า',
    // );

    console.log('data cut stock', data, customer);

    // if (confirm) {
    const preprocessedData = {
      customer_name: customer?.name,
      product_transaction_type: 'online',
      order: _.map(data, (each) => ({
        option: each?.option,
        product: each?.product,
        amount: each?.quantity,
      })),
    };
    console.log('preprocessedData', preprocessedData);
    await dispatch(actions.productTransactionCreate(preprocessedData));
    changeStatus(
      orderId,
      constants.TRANSACTION_STATUS.PRODUCT_PREPARED.status_code,
    );
  };

  const onSubmitTracking = async (data) => {
    const payload = {
      tracking_number: data?.tracking_number,
    };

    await dispatch(
      actions.paymentTransactionPut(paymentTransaction?.source_id, payload),
    );
    reset(defaultValue);
    handleCloseTrack();
    if (id) {
      await dispatch(actions.paymentTransactionGet(id, 3));
    }
  };

  console.log('paymentTransaction', paymentTransaction);

  const onSubmitUpdateOrders = async (data) => {
    try {
      const confirm = window.confirm('ยืนยันการแก้ไขหรือไม่?');
      if (confirm) {
        console.log('DATA', data);
        const newOrders = _.map(data?.itemList, (item) => {
          const payload = {
            ...item,
            price: item?.product?.price,
            product: item?.product?.id,
            discount: parseFloat(
              (item?.discount_percent * item?.product?.price) / 100,
            ),
            discount_percent: parseFloat(item?.discount_percent),
            quantity: parseInt(item?.quantity, 10),
            detailMore: '',
          };
          console.log('payload', payload);
          return payload;
        });

        console.log('newOrders', newOrders);
        await dispatch(
          actions.onlineOrderPut(
            // eslint-disable-next-line no-underscore-dangle
            paymentTransaction?.order_id?._id,
            newOrders,
          ),
        );
        await dispatch(
          // eslint-disable-next-line no-underscore-dangle
          actions.paymentTransactionPut(paymentTransaction?._id, data),
        );
        setOpenEditProduct(false);
        if (id) {
          await dispatch(actions.paymentTransactionGet(id, 4));
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const renderModalAddTrackingNumber = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openTrack}
      onClose={handleCloseTrack}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openTrack}>
        <Card sx={style} className="max-w-sm ">
          <div className="py-2">เพิ่มหมายเลขติดตามสินค้า</div>
          <div className="py-2">
            <form onSubmit={handleSubmit(onSubmitTracking)}>
              <div className="w-full  px-1 py-2">
                <Controller
                  name={'tracking_number'}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="หมายเลขติดตามสินค้า"
                      fullWidth
                      type="string"
                      size={'small'}
                      required
                      defaultValue={paymentTransaction?.tracking_number}
                    />
                  )}
                />
              </div>
              <div className="flex flex-row justify-end gap-1 py-4">
                <Button
                  variant="contained"
                  type="submit"
                  startIcon={<SaveIcon />}
                >
                  บันทึก
                </Button>
                <Button variant="outlined" onClick={handleCloseTrack}>
                  ยกเลิก
                </Button>
              </div>
            </form>
          </div>
        </Card>
      </Fade>
    </Modal>
  );

  const renderPrintButton = () => (
    <Card className="">
      <FormControl sx={{ m: 1, minWidth: 300 }} size="small">
        <InputLabel id="demo-select-small">
          <div className="w-full flex justify-between">
            <p className="pr-2">เลือกการพิมพ์</p> <PrintIcon />
          </div>
        </InputLabel>
        <Select
          labelId="demo-select-small"
          id="demo-select-small"
          label="เลือกการพิมพ์"
        >
          <MenuItem
            onClick={() => {
              handlePrintCover();
            }}
          >
            ใบปะหน้าพัสดุ (แนวตั้ง)
          </MenuItem>
          <MenuItem
            onClick={() => {
              handlePrintCoverVertical();
            }}
          >
            ใบปะหน้าพัสดุ (แนวนอน)
          </MenuItem>
          <MenuItem
            onClick={() => {
              handlePrintPurchase();
            }}
          >
            พิมพ์ใบสั่งซื้อ
          </MenuItem>
          <MenuItem
            onClick={() => {
              handlePrintAll();
            }}
          >
            พิมพ์ทั้งหมด
          </MenuItem>
        </Select>
      </FormControl>
    </Card>
  );

  const renderMessage = () => (
    <>
      <MessageCard
        complainMessage={messageList}
        {...orderMessageProp}
        // complain={orderMessage}
      />
    </>
  );

  console.log('fields', fields);

  const handleDeleteItem = (index) => {
    remove(index);
  };

  const handleAddItem = () => {
    append({
      product: rows[0],
      quantity: 1,
      price: rows[0]?.price,
    });
  };

  const renderTableEditProduct = () => (
    <div>
      {/* {_.map(fields, (item, index) => (
        <div>
          {index + 1} {item?.product?.name}
        </div>
      ))} */}
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size={'small'}>
            <colgroup>
              <col width="8%" />
              <col width="47%" />
              <col width="15%" />
              <col width="15%" />
              <col width="15%" />
            </colgroup>
            <TableHead>
              <TableRow className="bg-yellow-100">
                <TableCell>
                  <div className="font-bold py-1">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">ชื่อสินค้า</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">จำนวน</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">ราคาต่อชิ้น</div>
                </TableCell>
                {/* <TableCell>
                  <div className="font-bold py-1">ราคารวม</div>
                </TableCell> */}
                <TableCell>
                  <div className="font-bold py-1">ส่วนลดต่อชิ้น</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(fields) ? (
                _.map(fields, (item, index) => (
                  <TableRow
                    key={item.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <div className="py-1">{`${index + 1}`}</div>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <div className="py-1">
                        <Controller
                          name={`itemList.${index}.product`}
                          control={control}
                          render={({ field }) => (
                            <Autocomplete
                              size={'small'}
                              {...field}
                              onChange={(e, newValue) => {
                                field.onChange(newValue);
                                // setSelectCustomerType(newValue);
                              }}
                              defaultValue={rows ? rows[0] : ' '}
                              options={rows}
                              getOptionLabel={(type) => `${type?.name}`}
                              required
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="เลือกสินค้า"
                                  required
                                />
                              )}
                            />
                          )}
                        />
                      </div>
                      <div className="flex gap-1 text-red-600">
                        <div>
                          ({' '}
                          {
                            watch(`itemList.${index}.product`)?.product_type
                              ?.name
                          }{' '}
                          )
                        </div>
                        <div>
                          ( {watch(`itemList.${index}.product`)?.brand?.name} )
                        </div>
                      </div>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <div className="py-1">
                        <Controller
                          name={`itemList.${index}.quantity`}
                          control={control}
                          defaultValue={
                            watch(`itemList.${index}.quantity`) || 1
                          }
                          render={({ field }) => (
                            <TextField
                              size="small"
                              fullWidth
                              required
                              type="number"
                              label="จำนวน"
                              {...field}
                            />
                          )}
                        />
                      </div>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <div className="py-1">
                        {watch(`itemList.${index}.product.price`)}
                      </div>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <div className="py-1">
                        {' '}
                        <Controller
                          name={`itemList.${index}.discount_percent`}
                          control={control}
                          render={({ field }) => (
                            <TextField
                              size="small"
                              fullWidth
                              required
                              type="number"
                              label="ส่วนลด"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    %
                                  </InputAdornment>
                                ),
                              }}
                              {...field}
                            />
                          )}
                        />
                      </div>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Button
                        size="sm"
                        variant="outlined"
                        color="error"
                        onClick={() => {
                          handleDeleteItem(index);
                        }}
                      >
                        <Delete size={16} color="red" />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <div className="mt-2 w-full flex justify-center">
        <Button
          size="small"
          variant="outlined"
          color="success"
          onClick={() => {
            handleAddItem();
          }}
        >
          เพิ่มสินค้า
        </Button>
      </div>
    </div>
  );

  const renderModalEditProducts = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openEditProduct}
      onClose={handleCloseEditProduct}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openEditProduct}>
        <Card sx={style} className="max-w-8xl my-4">
          <form onSubmit={handleSubmit(onSubmitUpdateOrders)}>
            <div className="max-h-screen overflow-y-auto">
              <div className="flex justify-between">
                <div className="text-xl pb-2 underline">แก้ไขข้อมูล</div>
                <div>
                  <Button
                    variant="outlined"
                    onClick={() => setTestReset(testReset + 1)}
                  >
                    รีเซ็ต
                  </Button>
                </div>
              </div>
              <div className="flex flex-wrap my-2">
                <div className="w-full  px-1 min-h-full">
                  <div className="flex flex-wrap">
                    <div className="w-full">{renderTableEditProduct()}</div>
                  </div>
                </div>
              </div>
              <div>เพิ่มผู้เสนอราคา</div>
              <div className="py-4">
                <Controller
                  name={'bidder'}
                  control={control}
                  defaultValue={paymentTransaction?.order_id?.bidder}
                  render={({ field }) => (
                    <TextField
                      size="small"
                      fullWidth
                      type="text"
                      multiline
                      label="ผู้เสนอราคา"
                      {...field}
                    />
                  )}
                />
              </div>
              <div>เพิ่มหมายเหตุ</div>
              <div className="py-4">
                <Controller
                  name={'remark'}
                  control={control}
                  defaultValue={paymentTransaction?.order_id?.remark}
                  render={({ field }) => (
                    <TextField
                      size="small"
                      fullWidth
                      required
                      type="text"
                      multiline
                      label="หมายเหตุ"
                      {...field}
                    />
                  )}
                />
              </div>
              <div className="flex flex-row justify-end gap-1 py-4">
                <Button
                  type="submit"
                  variant="contained"
                  startIcon={<SaveIcon />}
                >
                  บันทึก
                </Button>
                <Button variant="outlined" onClick={handleCloseEditProduct}>
                  ปิด
                </Button>
              </div>
            </div>
          </form>
        </Card>
      </Fade>
    </Modal>
  );

  const renderModal = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openSlip}
      onClose={handleCloseSlip}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openSlip}>
        <Card sx={style} className="max-w-xl my-4">
          <div className="max-h-screen overflow-y-auto">
            <div className="text-xl pb-2 underline">ข้อมูลการชำระเงิน</div>
            <div>
              {/* <p>ชื่อบัญชีผู้โอน : {paymentTransaction?.payment_data?.name}</p>
              <p>ธนคารที่โอน : {paymentTransaction?.payment_data?.bank}</p> */}
              <p>
                วันเวลาที่โอน :{' '}
                {`${dayjs(paymentTransaction?.payment_data?.date).format(
                  'D MMM BBBB ',
                )}`}
                {' , '}
                {`${dayjs(paymentTransaction?.payment_data?.date).format(
                  'HH:mm',
                )}`}
              </p>
              <p>หลักฐานการโอนเงิน : </p>
              <div className="p-4 flex justify-center">
                <img src={paymentTransaction?.payment_data?.slip?.url} />
              </div>
            </div>
            <div className="flex flex-row justify-end gap-1 py-4">
              <Button variant="outlined" onClick={handleCloseSlip}>
                ปิด
              </Button>
            </div>
          </div>
        </Card>
      </Fade>
    </Modal>
  );
  const renderModalCreditCard = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openCreditCard}
      onClose={handleCloseCreditCard}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openCreditCard}>
        <Card sx={style} className="max-w-xl my-4">
          <div className="max-h-screen overflow-y-auto">
            <div className="py-4">
              <div className="text-xl pb-2 underline">ข้อมูลการชำระเงิน</div>
              <div className="flex">
                <div className="font-bold">หมายเลขบัตรที่จ่าย </div>
                <div className="pl-4">
                  {paymentTransaction?.payment_data?.cardNo}
                </div>
              </div>
              <div className="flex">
                <div className="font-bold">หมายเลขอ้างอิงธุรกรรม </div>
                <div className="pl-4">
                  {paymentTransaction?.payment_data?.tranRef}
                </div>
              </div>
              <div className="flex">
                <div className="font-bold">วันและเวลาที่ธุรกรรม </div>
                <div className="pl-4">
                  {paymentTransaction?.payment_data?.transactionDateTime}
                </div>
              </div>
              <div className="flex">
                <div className="font-bold">หมายเลขอ้างอิง </div>
                <div className="pl-4">
                  {paymentTransaction?.payment_data?.referenceNo}
                </div>
              </div>
            </div>

            <div className="flex flex-row justify-end gap-1 py-4">
              <Button variant="outlined" onClick={handleCloseCreditCard}>
                ปิด
              </Button>
            </div>
          </div>
        </Card>
      </Fade>
    </Modal>
  );

  const displayProduct = () => (
    <div className="">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead className="bg-yellow-100">
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ชื่อสินค้า</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> จำนวน</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ราคาต่อหน่วย</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ส่วนลด (%)</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ราคารวม</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(paymentTransaction?.order_id?.orders) ? (
                paymentTransaction?.order_id?.orders.map((_order, index) => (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    className={index % 2 === 0 ? 'bg-gray-100' : ''}
                  >
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      <div className="flex gap-1">
                        <div className="">
                          <div className="my-auto w-full">
                            {_order.product?.name}
                          </div>
                          <div className="my-auto w-full text-gray-500">
                            ({' '}
                            {
                              checkProduct(_order.product?._id)?.product_type
                                ?.name
                            }{' '}
                            ) ( {checkProduct(_order.product?._id)?.brand?.name}{' '}
                            )
                          </div>
                        </div>
                        {_order.product?.option_type && (
                          <div>( {checkOption(_order.option)?.name} )</div>
                        )}
                      </div>
                      {_order?.detailMore !== '' && (
                        <div className="text-red-600">
                          * หมายเหตุ {_order?.detailMore}
                        </div>
                      )}
                    </TableCell>
                    <TableCell>
                      {_order?.quantity} {_order.product?.unit}
                    </TableCell>
                    <TableCell>
                      {`${parseFloat(
                        _order?.price - (_order?.discount || 0),
                        10 || 0,
                        10,
                      ).toLocaleString(undefined)}`}{' '}
                      {'บาท'}
                    </TableCell>
                    <TableCell>{_order?.discount_percent || 0}</TableCell>
                    <TableCell>
                      {`${parseFloat(
                        (_order?.price - (_order?.discount || 0)) *
                          _order?.quantity,
                        10 || 0,
                        10,
                      ).toLocaleString(undefined)}`}{' '}
                      {'บาท'}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow key={''}>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีรายการ</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {setting?.tax_include && (
        <div className="flex justify-end mt-1">
          ( ราคารวมภาษีมูลค่าเพิ่มแล้ว )
        </div>
      )}
      {setting?.tax_exclude && (
        <div className="flex justify-end mt-1">
          ( ราคายังไม่รวมภาษีมูลค่าเพิ่ม {setting?.tax_number} % )
        </div>
      )}
    </div>
  );

  const disabledButtonConfirm = (status) =>
    // eslint-disable-next-line implicit-arrow-linebreak
    status === constants.TRANSACTION_STATUS.ORDER_CONFIRM.status_code ||
    status === constants.TRANSACTION_STATUS.QUOTATION_SEND.status_code ||
    status === constants.TRANSACTION_STATUS.PO_RECEIVE.status_code ||
    status === constants.TRANSACTION_STATUS.PRODUCT_PREPARED.status_code ||
    status === constants.TRANSACTION_STATUS.PRODUCT_TRANS.status_code ||
    status === constants.TRANSACTION_STATUS.PRODUCT_COMPLETE.status_code ||
    status === constants.TRANSACTION_STATUS.PRODUCT_CANCEL.status_code;

  const disabledButtonQuotation = (status, paymentType) => {
    // console.log('paymentType', paymentType, status);
    if (paymentType === 'quotation') {
      return (
        status === constants.TRANSACTION_STATUS.ORDER_WAIT.status_code ||
        status === constants.TRANSACTION_STATUS.PRODUCT_PREPARED.status_code ||
        status === constants.TRANSACTION_STATUS.QUOTATION_SEND.status_code ||
        status === constants.TRANSACTION_STATUS.PO_RECEIVE.status_code ||
        status === constants.TRANSACTION_STATUS.PRODUCT_TRANS.status_code ||
        status === constants.TRANSACTION_STATUS.PRODUCT_COMPLETE.status_code
      );
    }
    return (
      status === constants.TRANSACTION_STATUS.ORDER_WAIT.status_code ||
      status === constants.TRANSACTION_STATUS.PRODUCT_PREPARED.status_code ||
      status === constants.TRANSACTION_STATUS.QUOTATION_SEND.status_code ||
      status === constants.TRANSACTION_STATUS.PO_RECEIVE.status_code ||
      status === constants.TRANSACTION_STATUS.PRODUCT_TRANS.status_code ||
      status === constants.TRANSACTION_STATUS.PRODUCT_COMPLETE.status_code ||
      paymentType !== 'quotation'
    );
  };

  const disabledButtonPOReceive = (status, paymentType) => {
    if (paymentType === 'quotation') {
      return (
        status === constants.TRANSACTION_STATUS.ORDER_WAIT.status_code ||
        status === constants.TRANSACTION_STATUS.ORDER_CONFIRM.status_code ||
        status === constants.TRANSACTION_STATUS.PRODUCT_PREPARED.status_code ||
        status === constants.TRANSACTION_STATUS.PO_RECEIVE.status_code ||
        status === constants.TRANSACTION_STATUS.PRODUCT_TRANS.status_code ||
        status === constants.TRANSACTION_STATUS.PRODUCT_COMPLETE.status_code
      );
    }
    return (
      status === constants.TRANSACTION_STATUS.ORDER_WAIT.status_code ||
      status === constants.TRANSACTION_STATUS.ORDER_CONFIRM.status_code ||
      status === constants.TRANSACTION_STATUS.PRODUCT_PREPARED.status_code ||
      status === constants.TRANSACTION_STATUS.PO_RECEIVE.status_code ||
      status === constants.TRANSACTION_STATUS.PRODUCT_TRANS.status_code ||
      status === constants.TRANSACTION_STATUS.PRODUCT_COMPLETE.status_code ||
      paymentType !== 'quotation'
    );
  };

  const disabledButtonCutStock = (status, paymentType) => {
    if (paymentType === 'quotation') {
      return (
        status === constants.TRANSACTION_STATUS.ORDER_WAIT.status_code ||
        status === constants.TRANSACTION_STATUS.ORDER_CONFIRM.status_code ||
        status === constants.TRANSACTION_STATUS.PRODUCT_PREPARED.status_code ||
        status === constants.TRANSACTION_STATUS.QUOTATION_SEND.status_code ||
        status === constants.TRANSACTION_STATUS.PRODUCT_TRANS.status_code ||
        status === constants.TRANSACTION_STATUS.PRODUCT_COMPLETE.status_code
      );
    }
    return (
      status === constants.TRANSACTION_STATUS.ORDER_WAIT.status_code ||
      status === constants.TRANSACTION_STATUS.PRODUCT_PREPARED.status_code ||
      status === constants.TRANSACTION_STATUS.QUOTATION_SEND.status_code ||
      status === constants.TRANSACTION_STATUS.PRODUCT_TRANS.status_code ||
      status === constants.TRANSACTION_STATUS.PRODUCT_COMPLETE.status_code
    );
  };

  const disabledButtonDelivery = (status) =>
    // eslint-disable-next-line implicit-arrow-linebreak
    status === constants.TRANSACTION_STATUS.ORDER_CONFIRM.status_code ||
    status === constants.TRANSACTION_STATUS.QUOTATION_SEND.status_code ||
    status === constants.TRANSACTION_STATUS.PO_RECEIVE.status_code ||
    status === constants.TRANSACTION_STATUS.ORDER_WAIT.status_code ||
    status === constants.TRANSACTION_STATUS.PRODUCT_TRANS.status_code ||
    status === constants.TRANSACTION_STATUS.PRODUCT_COMPLETE.status_code ||
    status === constants.TRANSACTION_STATUS.PRODUCT_CANCEL.status_code;

  // ปุ่มของสินค้าที่มีการจัดส่ง
  const buttonConfirmOrder = (data) => (
    // const currentStatus = constants.TRANSACTION_STATUS[data.status_order];
    <div className="w-1/4">
      <Button
        sx={{ backgroundColor: '#f005d0' }}
        variant="contained"
        fullWidth
        disabled={
          data?.status === 'failed' ||
          disabledButtonConfirm(data?.status_order, data?.payment_type)
        }
        // size={'small'}
        onClick={() => {
          const confirm = window.confirm('ยืนยันคำสั่งซื้อ');
          if (confirm) {
            changeStatus(
              data?.source_id,
              constants.TRANSACTION_STATUS.ORDER_CONFIRM.status_code,
            );
          }
        }}
      >
        {/* {statusButtonConfirm(data?.status_order, currentStatus) && (
            <i className="fas fa-check pr-1"></i>
          )} */}
        ยืนยัน
      </Button>
    </div>
  );

  const buttonQuotationSend = (data) => (
    // const currentStatus = constants.TRANSACTION_STATUS[data.status_order];
    <div className="w-1/4">
      <Button
        sx={{ backgroundColor: '#1607e8' }}
        variant="contained"
        // size={'small'}
        fullWidth
        disabled={
          data?.status === 'failed' ||
          disabledButtonQuotation(data?.status_order, data?.payment_type)
        }
        onClick={() => {
          const confirm = window.confirm('ยืนยันการส่งใบ QNแล้ว');
          if (confirm) {
            // eslint-disable-next-line implicit-arrow-linebreak
            changeStatus(
              data?.source_id,
              constants.TRANSACTION_STATUS.QUOTATION_SEND.status_code,
            );
          }
        }}
      >
        {/* {statusButtonCutStock(data?.status_order, currentStatus) && (
            <i className="fas fa-check pr-1"></i>
          )} */}
        ส่งใบ QN
      </Button>
    </div>
  );

  const buttonPOReceive = (data) => (
    // const currentStatus = constants.TRANSACTION_STATUS[data.status_order];
    <div className="w-1/4">
      <Button
        sx={{ backgroundColor: '#1607e8' }}
        variant="contained"
        // size={'small'}
        fullWidth
        disabled={
          data?.status === 'failed' ||
          disabledButtonPOReceive(data?.status_order, data?.payment_type)
        }
        onClick={() => {
          const confirm = window.confirm('ยืนยันการได้รับใบ PO แล้ว');
          if (confirm) {
            // eslint-disable-next-line implicit-arrow-linebreak
            changeStatus(
              data?.source_id,
              constants.TRANSACTION_STATUS.PO_RECEIVE.status_code,
            );
          }
        }}
      >
        {/* {statusButtonCutStock(data?.status_order, currentStatus) && (
            <i className="fas fa-check pr-1"></i>
          )} */}
        รับใบ PO
      </Button>
    </div>
  );

  const buttonCutStockOrder = (data) => (
    // const currentStatus = constants.TRANSACTION_STATUS[data.status_order];
    <div className="w-1/4">
      <Button
        sx={{ backgroundColor: '#1607e8' }}
        variant="contained"
        // size={'small'}
        fullWidth
        disabled={
          data?.status === 'failed' ||
          disabledButtonCutStock(data?.status_order, data?.payment_type)
        }
        onClick={() => {
          const confirmCut = window.confirm('ต้องการตัดสต๊อคสินค้าหรือไม่');
          if (confirmCut) {
            // eslint-disable-next-line implicit-arrow-linebreak
            handleCutStock(
              data?.order_id?.orders,
              data?.source_id,
              data?.customer,
            );
          } else {
            changeStatus(
              data?.source_id,
              constants.TRANSACTION_STATUS.PRODUCT_PREPARED.status_code,
            );
          }
        }}
      >
        {/* {statusButtonCutStock(data?.status_order, currentStatus) && (
            <i className="fas fa-check pr-1"></i>
          )} */}
        เตรียม
      </Button>
    </div>
  );
  const buttonDeliveryOrder = (data) => (
    // const currentStatus = constants.TRANSACTION_STATUS[data.status_order];
    <div className="w-1/4">
      <Button
        variant="contained"
        fullWidth
        sx={{ backgroundColor: '#e8d907' }}
        // startIcon={<LocalShippingIcon />}
        // size={'small'}
        disabled={
          data?.status === 'failed' ||
          disabledButtonDelivery(data?.status_order, data?.payment_type)
        }
        onClick={() => {
          const confirm = window.confirm(
            'ยืนยันการจัดส่ง พร้อมใส่หมายเลขติดตามพัสดุ',
          );

          if (confirm) {
            changeStatus(
              data?.source_id,
              constants.TRANSACTION_STATUS.PRODUCT_TRANS.status_code,
            );
          }
        }}
      >
        {/* {statusButtonDelivery(data?.status_order, currentStatus) && (
            <i className="fas fa-check pr-1"></i>
          )} */}
        จัดส่ง
      </Button>
    </div>
  );

  const buttonFinishOrder = (data) => (
    <div className="w-1/4">
      <Button
        variant="contained"
        sx={{ backgroundColor: '#00ab03' }}
        fullWidth
        // startIcon={<CheckCircleOutlineIcon />}
        // size={'small'}
        disabled={
          data?.status === 'failed' || data?.status_order !== 'PRODUCT_TRANS'
        }
        onClick={() => {
          const confirm = window.confirm('ยืนยันคำสั่งซื้อ');
          if (confirm) {
            // eslint-disable-next-line implicit-arrow-linebreak
            changeStatus(
              data?.source_id,
              constants.TRANSACTION_STATUS.PRODUCT_COMPLETE.status_code,
              data?.order_id?.delivery?.tel,
            );
          }
        }}
        // onClick={() => handleToInfoPage(row.id)}
        // PRODUCT_COMPLETE
      >
        สำเร็จ
      </Button>
    </div>
  );

  const renderShowStatus = (data) => (
    <div>
      <div className="flex gap-1">
        {buttonConfirmOrder(data)}
        {buttonQuotationSend(data)}
        {buttonPOReceive(data)}
        {buttonCutStockOrder(data)}
        {buttonDeliveryOrder(data)}
        {buttonFinishOrder(data)}
      </div>
      {data?.status_order === 'PRODUCT_TRANS' && (
        <div className="flex gap-1 pt-1">
          <div className="w-1/2">
            <Button
              variant="contained"
              // startIcon={<EditLocationAltIcon />}
              sx={{ backgroundColor: '#049dcc' }}
              fullWidth
              onClick={() => {
                handleOpenTrack();
              }}
            >
              หมายเลขติดตาม
            </Button>
          </div>
          <a
            className="cursor-pointer hover:underline my-auto border-4 p-1 text-sm text-center text-red-600 w-1/2"
            onClick={() => {
              navigator.clipboard.writeText(data?.tracking_number);
              alert('คัดลอกเรียบร้อย');
            }}
          >
            {data?.tracking_number}
          </a>
        </div>
      )}
    </div>
  );

  const renderShowProductList = () => (
    <Card className="p-6 ">
      <div className="flex justify-end gap-1 mb-2">
        {paymentTransaction?.payment_type === 'quotation' && (
          <div className="">
            <Button
              fullWidth
              variant="contained"
              startIcon={<EditIcon />}
              onClick={() => {
                handleOpenEditProduct();
              }}
            >
              แก้ไขข้อมูล
            </Button>
          </div>
        )}

        <div className="">
          <Button
            fullWidth
            variant="contained"
            startIcon={<PrintIcon />}
            onClick={() => handlePrintQuotation(paymentTransaction)}
          >
            พิมพ์ใบเสนอราคา
          </Button>
        </div>
      </div>
      {displayProduct()}
      {/* <div className="flex justify-end gap-1 mt-2">
        <div className="">
          <Button
            fullWidth
            variant="contained"
            startIcon={<SaveIcon />}
          >
            บันทึก
          </Button>
        </div>
      </div> */}
    </Card>
  );

  const renderOrderDetail = () => (
    <div>
      <Card className="p-4 ">
        <div className="w-full font-bold text-xl my-2 mb-4 flex gap-2">
          <div>รายละเอียด ( {paymentTransaction?.order_id?.prefix} ) </div>
          <div>
            {paymentTransaction?.status_order === 'PRODUCT_CANCEL' && (
              <div className="w-full pb-2">
                <CancelIcon sx={{ color: red[500] }} />
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-wrap">
          {paymentTransaction?.status_order === 'PRODUCT_CANCEL' && (
            <>
              <div className="w-1/2 py-4 px-2 font-semibold ">
                {'เหตุผลการยกเลิก'}
              </div>
              <div className="w-1/2 py-4 ">{`${paymentTransaction?.cancel_detail}`}</div>
            </>
          )}

          <div className="w-1/2 py-4 px-2 font-semibold ">{'ชื่อลูกค้า'}</div>
          <div className="w-1/2 py-4 ">{`${paymentTransaction?.customer?.name}`}</div>
          {setting?.hotel ? (
            <>
              <div className="w-1/2 py-4 px-2 font-semibold ">
                {'ที่อยู่ลูกค้าสำหรับจัดส่ง'}
              </div>
              <div className="w-1/2 py-4 px-2 ">
                ห้อง {paymentTransaction?.order_id?.room}
              </div>
            </>
          ) : (
            <>
              <div className="w-1/2 py-4 px-2 font-semibold ">
                {'ที่อยู่ลูกค้าสำหรับจัดส่ง'}
              </div>
              <div className="w-1/2 py-4 flex flex-wrap">
                <div className="pr-1">
                  บ้านเลขที่{' '}
                  {paymentTransaction?.order_id?.delivery?.number || ''}
                </div>
                <div className="pr-1">
                  {paymentTransaction?.order_id?.delivery?.apartment || ''}
                </div>
                <div className="pr-1">
                  {paymentTransaction?.order_id?.delivery?.building || ''}
                </div>
                <div className="pr-1">
                  {paymentTransaction?.order_id?.delivery?.village || ''}
                </div>
                <div className="pr-1">
                  หมู่ที่{' '}
                  {paymentTransaction?.order_id?.delivery?.village_number || ''}
                </div>
                <div className="pr-1">
                  ซอย {paymentTransaction?.order_id?.delivery?.alley || ''}
                </div>
                <div className="pr-1">
                  {paymentTransaction?.order_id?.delivery?.lane || ''}
                </div>
                <div className="pr-1">
                  ถนน {paymentTransaction?.order_id?.delivery?.road || ''}
                </div>
                <div className="pr-1">
                  ตำบล{' '}
                  {paymentTransaction?.order_id?.delivery?.sub_district || ''}
                </div>
                <div className="pr-1">
                  อำเภอ {paymentTransaction?.order_id?.delivery?.district || ''}
                </div>
                <div className="pr-1">
                  จังหวัด{' '}
                  {paymentTransaction?.order_id?.delivery?.province || ''}
                </div>
                <div className="pr-1">
                  {paymentTransaction?.order_id?.delivery?.postcode || ''}
                </div>
              </div>
            </>
          )}
          {!setting?.hotel && (
            <>
              <div className="w-1/2 py-4 px-2 font-semibold ">
                {'วิธีการจัดส่ง'}
              </div>
              <div className="w-1/2 py-4 ">
                {paymentTransaction?.order_id?.provider?.provider?.name}
              </div>
            </>
          )}

          <div className="w-1/2 py-4 px-2 font-semibold ">
            {'วัน-เวลาที่สะดวกรับของ'}
          </div>
          <div className="w-1/2 py-4 ">
            <a>
              {`${dayjs(paymentTransaction?.order_id?.date_pickup).format(
                'D MMM BBBB',
              )}`}
              {' เวลา '}
              {`${dayjs(paymentTransaction?.order_id?.date_pickup).format(
                'HH:mm',
              )}`}{' '}
              น.
            </a>
          </div>
          <div className="w-1/2 py-4 px-2 font-semibold ">
            {'เบอร์โทรติดต่อ'}
          </div>
          <div className="w-1/2 py-4 ">
            {`${paymentTransaction?.order_id?.delivery?.tel || '-'}`}
          </div>
          <div className="w-1/2 py-4 px-2 font-semibold ">{'จำนวนเงิน'}</div>
          <div className="w-1/2 py-4 ">
            {(paymentTransaction?.amount || 0)
              .toFixed(2)
              .replace(/\d(?=(\d{3})+\.)/g, '$&,')}{' '}
            บาท
            {paymentTransaction?.payment_type !== 'paymentSlip' && (
              <small>
                ( หลังหักค่าธรรมเนียม {paymentTransaction?.net} บาท )
              </small>
            )}
          </div>
          <div className="w-1/2 py-4 px-2 font-semibold ">
            {'ส่วนลดการสั่งซื้อ'}
          </div>
          <div className="w-1/2 py-4 ">
            {paymentTransaction?.payment_type === 'creditCard'
              ? TotalDiscountPrice(paymentTransaction?.order_id?.orders)
              : (paymentTransaction?.discount || 0)
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
            บาท
          </div>
          <div className="w-1/2 py-4 px-2 font-semibold ">{'ค่าจัดส่ง'}</div>
          <div className="w-1/2 py-4 ">
            {(paymentTransaction?.order_id?.delivery_price || 0)
              .toFixed(2)
              .replace(/\d(?=(\d{3})+\.)/g, '$&,')}{' '}
            บาท
          </div>

          <div className="w-1/2 py-4 px-2 font-semibold ">{'เวลาสั่งซื้อ'}</div>
          <div className="w-1/2 py-4 ">
            {`${dayjs(paymentTransaction?.createdAt).format('D MMM BBBB ')}`}
            {' , '}
            {`${dayjs(paymentTransaction?.createdAt).format('HH:mm')}`}
          </div>
          <div className="w-1/2 py-4 px-2 font-semibold ">
            {'วิธีการชำระเงิน'}
          </div>
          <div className="w-1/2 py-4 ">
            <div className="w-full">
              {paymentTransaction?.payment_type === 'paymentSlip' &&
                'แนบสลิปการโอน'}
              {paymentTransaction?.payment_type === 'promptpay' &&
                'โอนผ่าน omise'}
              {paymentTransaction?.payment_type === 'paymentLater' &&
                'ชำระเงินปลายทาง'}
              {paymentTransaction?.payment_type === 'creditCard' &&
                'จ่ายผ่าน 2c2p'}
            </div>

            {paymentTransaction?.payment_type === 'paymentSlip' && (
              <div className="w-full pt-2">
                <Button
                  variant="contained"
                  startIcon={<ReceiptIcon />}
                  onClick={() => handleOpenSlip()}
                >
                  ดูหลักฐานการชำระเงิน
                </Button>
              </div>
            )}
            {paymentTransaction?.payment_type === 'creditCard' && (
              <Button
                variant="contained"
                startIcon={<ReceiptIcon />}
                onClick={() => handleOpenCreditCard()}
              >
                ดูหลักฐานการชำระเงิน
              </Button>

              // <div className="pt-2">
              //   <div className="flex ">
              //     <div className="">หมายเลขบัตรที่จ่าย </div>
              //     <div className="pl-4">
              //       {paymentTransaction?.payment_data?.cardNo}
              //     </div>
              //   </div>
              //   <div className="flex ">
              //     <div className="">หมายเลขอ้างอิงธุรกรรม </div>
              //     <div className="pl-4">
              //       {paymentTransaction?.payment_data?.tranRef}
              //     </div>
              //   </div>
              //   <div className="flex ">
              //     <div className="">วันและเวลาที่ธุรกรรม </div>
              //     <div className="pl-4">
              //       {paymentTransaction?.payment_data?.transactionDateTime}
              //     </div>
              //   </div>
              //   <div className="flex ">
              //     <div className="">หมายเลขอ้างอิง </div>
              //     <div className="pl-4">
              //       {paymentTransaction?.payment_data?.referenceNo}
              //     </div>
              //   </div>
              // </div>
            )}
          </div>
        </div>
      </Card>
    </div>
  );

  const renderOrderQuotation = () => (
    <Card className="p-4">
      <div className="w-full font-bold text-xl my-2 mb-4 flex gap-2">
        ใบเสนอราคา ( {paymentTransaction?.order_id?.prefix} )
        <div>
          {paymentTransaction?.status_order === 'PRODUCT_CANCEL' && (
            <div className="w-full pb-2">
              <CancelIcon sx={{ color: red[500] }} />
            </div>
          )}
        </div>
      </div>
      <div className="flex my-2">
        {paymentTransaction?.status_order === 'PRODUCT_CANCEL' && (
          <>
            <div className="w-1/2  font-semibold ">{'เหตุผลการยกเลิก'}</div>
            <div className="w-1/2  ">{`${paymentTransaction?.cancel_detail}`}</div>
          </>
        )}
      </div>

      <div className="flex my-2">
        <div className="w-1/2 font-bold">ชื่อ</div>
        <div className="w-1/2">
          {paymentTransaction?.order_id?.quotation?.name}
        </div>
      </div>
      <div className="flex my-2">
        <div className="w-1/2 font-bold">บริษัท</div>
        <div className="w-1/2">
          {paymentTransaction?.order_id?.quotation?.company}
        </div>
      </div>
      <div className="flex my-2">
        <div className="w-1/2 font-bold">เบอร์โทรติดต่อ</div>
        <div className="w-1/2">
          {paymentTransaction?.order_id?.quotation?.phone}
        </div>
      </div>
      <div className="flex my-2">
        <div className="w-1/2 font-bold">อีเมล</div>
        <div className="w-1/2">
          {paymentTransaction?.order_id?.quotation?.email}
        </div>
      </div>
      <div className="flex my-2">
        <div className="w-1/2 font-bold">ไฟล์ที่แนบมา</div>
        <div className="w-1/2">
          <a
            className="hover:underline"
            href={paymentTransaction?.order_id?.files[0]?.url}
            target="_blank"
            rel="noreferrer"
          >
            {paymentTransaction?.order_id?.files[0]?.file_name || '-'}
          </a>
        </div>
      </div>
      <div className="flex my-2">
        <div className="w-1/2 font-bold">ผู้เสนอราคา</div>
        <div className="w-1/2">{paymentTransaction?.bidder || '-'}</div>
      </div>
      <div className="flex my-2">
        <div className="w-1/2 font-bold">หมายเหตุ</div>
        <div className="w-1/2">{paymentTransaction?.remark}</div>
      </div>
    </Card>
  );

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (paymentTransaction.isLoading) {
    return <Loading />;
  }
  if (!paymentTransaction.isLoading && paymentTransaction.isCompleted) {
    return (
      <div>
        {renderModalAddTrackingNumber()}
        {renderModalEditProducts()}
        {renderModal()}
        {renderModalCreditCard()}
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
        </div>
        <div className="flex justify-between w-full pb-4">
          <div className=" my-auto">
            <BackButton />
          </div>
          {/* <div>
            <Button
              onClick={() => {
                // eslint-disable-next-line no-underscore-dangle
                socket.current.emit('send-message', paymentTransaction?._id);
              }}
            >
              test socket
            </Button>
          </div> */}
          {/* <div className="text-lg font-semibold ml-6 my-auto">
            รายละเอียดออเดอร์ {paymentTransaction?.order_id?.prefix}
          </div> */}
          {renderPrintButton()}
        </div>
        {/* {paymentTransaction?.payment_type === 'creditCard'
          ? renderModalCreditCard()
          : ''} */}
        {paymentTransaction?.payment_type === 'quotation'
          ? renderOrderQuotation()
          : renderOrderDetail()}

        {paymentTransaction?.order_id?.orders ? (
          <>
            <div className="w-full py-4 px-2 font-semibold ">
              {'รายการสินค้า'}
            </div>
            {renderShowProductList()}
          </>
        ) : (
          <></>
        )}

        {paymentTransaction?.status_order !== 'PRODUCT_CANCEL' && (
          <>
            <div className="w-full py-4 px-2 font-semibold ">{'สถานะ'}</div>
            {renderShowStatus(paymentTransaction)}
          </>
        )}

        {!orderMessage?.isLoading && (
          <div className="w-full mt-2">{renderMessage()}</div>
        )}
      </div>
    );
  }
  return <Error />;
}

DetailOnline.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DetailOnline.defaultProps = {
  title: '',
  subtitle: '',
};
