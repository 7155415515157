import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { Card, Button } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

import { useParams, useHistory } from 'react-router';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as actions from '../../redux/actions';
import { BackButton } from '../../components/Button';
import { EmployeeForm } from '../../components/Forms';
import { Loading } from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';

export default function EditEmployee({ title, subtitle }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [employeeImage, setEmployeeImage] = useState([]);
  const employee = useSelector((state) => state.employee);
  const department = useSelector((state) => state.department);
  // STATE OD FORM MODULES
  const [checkDASHBOARD, setCheckDASHBOARD] = useState([false]);
  const [checkSPM, setCheckSPM] = useState([false, false, false]);
  const [checkWMS, setCheckWMS] = useState([false, false, false, false]);
  const [checkHIS, setCheckHIS] = useState([false, false]);
  const [checkCRM, setCheckCRM] = useState([false, false]);
  const [checkHMS, setCheckHMS] = useState([false, false, false, false]);
  const [checkSETTING, setCheckSETTING] = useState([false]);

  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({
    defaultValues: {
      firstname: employee.firstname,
      lastname: employee.lastname,
      department: department.id,
      phone_number: employee.phone_number,
      access: employee.access,
    },
  });

  console.log('employee', employee);

  useEffect(() => {
    dispatch(actions.employeeGet(id));
    dispatch(actions.departmentAll(''));
    return () => {};
  }, []);

  useEffect(() => {
    setCheckDASHBOARD([employee?.access?.DASHBOARD?.MAIN?.access]);
    setCheckSPM([
      employee?.access?.SPM?.IMPORT?.access,
      employee?.access?.SPM?.EXPORT?.access,
      employee?.access?.SPM?.RETURN?.access,
    ]);
    setCheckWMS([
      employee?.access?.WMS?.PRODUCT?.access,
      employee?.access?.WMS?.PRODUCTTYPE?.access,
      employee?.access?.WMS?.BRAND?.access,
      employee?.access?.WMS?.WAREHOUSE?.access,
    ]);
    setCheckHIS([
      employee?.access?.HISTORY?.SALEORDER?.access,
      employee?.access?.HISTORY?.STOCK?.access,
    ]);
    setCheckCRM([
      employee?.access?.CRM?.CUSTOMER?.access,
      employee?.access?.CRM?.CUSTOMERTYPE?.access,
    ]);
    setCheckHMS([
      employee?.access?.HRMS?.EMPLOYEE?.access,
      employee?.access?.HRMS?.DEPARTMENT?.access,
      employee?.access?.HRMS?.ROLETYPE?.access,
      employee?.access?.HRMS?.USER?.access,
    ]);
    setCheckSETTING([employee?.access?.SETTING?.INFORMATION?.access]);
    return () => {};
  }, [employee]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const onSubmit = async (data) => {
    try {
      // eslint-disable-next-line no-param-reassign

      if (!_.isEmpty(employeeImage)) {
        // eslint-disable-next-line no-param-reassign
        data.image = {
          image: employeeImage[0]?.data_url,
          imageType: 'profile',
          alt: '',
        };
      }
      const payload = {
        ...data,
        access: {
          HRMS: {
            access: checkHMS[0] || checkHMS[1] || checkHMS[2] || checkHMS[3],
            EMPLOYEE: {
              access: checkHMS[0],
            },
            DEPARTMENT: {
              access: checkHMS[1],
            },
            ROLETYPE: {
              access: checkHMS[2],
            },
            USER: {
              access: checkHMS[3],
            },
          },
          CRM: {
            access: checkCRM[0] || checkCRM[1],
            CUSTOMER: {
              access: checkCRM[0],
            },
            CUSTOMERTYPE: {
              access: checkCRM[1],
            },
          },
          SPM: {
            access: checkSPM[0] || checkSPM[1],
            IMPORT: {
              access: checkSPM[0],
            },
            EXPORT: {
              access: checkSPM[1],
            },
            RETURN: {
              access: checkSPM[2],
            },
          },
          WMS: {
            access: checkWMS[0] || checkWMS[1] || checkWMS[2] || checkWMS[3],
            PRODUCT: {
              access: checkWMS[0],
            },
            PRODUCTTYPE: {
              access: checkWMS[1],
            },
            BRAND: {
              access: checkWMS[2],
            },
            WAREHOUSE: {
              access: checkWMS[3],
            },
          },
          DASHBOARD: {
            access: checkDASHBOARD[0],
            MAIN: {
              access: checkDASHBOARD[0],
            },
          },
          SETTING: {
            access: checkSETTING[0],
            INFORMATION: {
              access: checkSETTING[0],
            },
          },
          HISTORY: {
            access: checkHIS[0] || checkHIS[1],
            SALEORDER: {
              access: checkHIS[0],
            },
            STOCK: {
              access: checkHIS[1],
            },
          },
        },
        // eslint-disable-next-line no-underscore-dangle
        role: data?.role?._id,
      };
      await dispatch(actions.employeePut(id, payload));
      await dispatch(actions.employeeGet(id));
      setEmployeeImage([]);
      alert('สำเร็จ');
      history.goBack();
    } catch (error) {
      console.log(error);
    }
  };
  if (employee.isLoading || employee.rows) {
    return <Loading />;
  }
  if (!employee.isLoading && employee.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
          <div className="text-lg font-semibold ml-6">แก้ไขข้อมูลพนักงาน</div>
        </div>
        <div>
          <Card className="p-6">
            <form onSubmit={handleSubmit(onSubmit)}>
              <EmployeeForm
                control={control}
                Controller={Controller}
                errors={errors}
                employee={employee}
                department={department}
                employeeImage={employeeImage}
                setEmployeeImage={setEmployeeImage}
                checkDASHBOARD={checkDASHBOARD}
                setCheckDASHBOARD={setCheckDASHBOARD}
                checkSPM={checkSPM}
                setCheckSPM={setCheckSPM}
                checkWMS={checkWMS}
                setCheckWMS={setCheckWMS}
                checkHIS={checkHIS}
                setCheckHIS={setCheckHIS}
                checkCRM={checkCRM}
                setCheckCRM={setCheckCRM}
                checkHMS={checkHMS}
                setCheckHMS={setCheckHMS}
                checkSETTING={checkSETTING}
                setCheckSETTING={setCheckSETTING}
              />
              <div className="flex flex-row justify-end gap-1 py-4">
                <Button
                  variant="contained"
                  type="submit"
                  startIcon={<SaveIcon />}
                >
                  บันทึก
                </Button>
              </div>
            </form>
          </Card>
        </div>
      </div>
    );
  }
  return <Error />;
}
EditEmployee.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditEmployee.defaultProps = {
  title: '',
  subtitle: '',
};
