import React from 'react';
import PropTypes from 'prop-types';

function LeftMenu({ me, availableModule, informationLayout }) {
  return (
    <div className="min-h-screen bg-white">
      <div className="container pt-40 py-20 mr-auto ml-auto">
        <div className="flex flex-wrap">
          <div className="w-full lg:w-1/2">1</div>
          <div className="w-full lg:w-1/2">2</div>
        </div>
      </div>
    </div>
  );
}

export default LeftMenu;

LeftMenu.propTypes = {
  me: PropTypes.object,
  availableModule: PropTypes.arrayOf(PropTypes.object),
  informationLayout: PropTypes.object,
};
