/* eslint-disable no-confusing-arrow */
import appConfig from '../../configs/app';

const columns = [
  {
    label: 'ลำดับที่',
    value: 'index',
  },
  {
    label: 'รูปภาพ',
    value: 'image_show',
  },
  {
    label: 'รหัสสินค้า',
    value: 'type_code',
  },
  {
    label: 'รหัสสินค้า',
    value: 'model_name',
  },
  {
    label: 'ขื่อสินค้า',
    value: 'name',
  },
  {
    label: 'คงเหลือ',
    value: 'inventory',
  },
  {
    label: 'ลิงค์รูปภาพ',
    value: 'image_url',
  },
];

const query = ({ id }) => ({
  place: id,
  fetchStockLot: true,
  fetchProcess: false,
  page: 1,
  size: appConfig.maxFetchSize,
});

export default { columns, query };
