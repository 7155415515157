/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-underscore-dangle */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import _ from 'lodash';
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import * as actions from '../../redux/actions';
import { BackButton } from '../../components/Button';
import { Loading } from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';

export default function DetailOrder({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const { id } = useParams();
  const productTransaction = useSelector((state) => state.productTransaction);

  useEffect(() => {
    const fetchProductTransaction = async () => {
      try {
        await dispatch(actions.productTransactionGet(id));
        await dispatch(actions.getAllOption({}));
      } catch (error) {
        console.error(error);
      }
    };
    const fetchSerialNumber = async () => {
      try {
        await dispatch(actions.getAllSerial(''));
      } catch (error) {
        console.error(error);
      }
    };
    fetchProductTransaction();
    fetchSerialNumber();

    return () => {};
  }, []);

  const { orders } = productTransaction;
  // eslint-disable-next-line consistent-return

  const displayProduct = () => (
    <div className="">
      <Paper>
        <TableContainer component={Paper}>
          <Table size="small" className="border-lg">
            <TableHead className="bg-yellow-100">
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ชื่อสินค้า</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> รุ่นสินค้า</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">
                    คลัง{' '}
                    {!productTransaction?.project_number && '/ หมายเลขโครงการ'}
                  </div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> จำนวน</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(productTransaction.orders) ? (
                orders.map((_order, index) => (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    className={index % 2 === 0 ? 'bg-gray-100' : ''}
                  >
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      <div className="my-auto">{_order.product.name}</div>
                    </TableCell>
                    <TableCell>
                      <div className="my-auto">{_order.product.model_name}</div>
                    </TableCell>
                    <TableCell>
                      <div className="my-auto">
                        {productTransaction?.warehouse ? (
                          productTransaction?.warehouse?.name
                        ) : (
                          <>
                            {_order?.warehouse?.name} / {_order?.project_number}
                          </>
                        )}
                      </div>
                    </TableCell>
                    <TableCell>
                      {_order?.amount} {_order?.product?.unit}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow key={''}>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีรายการ</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );

  const handleToInfoPage = async (logId) => {
    history.push(`/spm/orders/log/${logId}`);
  };

  const displayOrderHistory = () => (
    <div className="">
      <Paper>
        <TableContainer component={Paper}>
          <Table size="small" className="border-lg">
            <TableHead className="bg-yellow-100">
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> หมายเลขเอกสาร</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ประเภทการแก้ไข</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> แก้ไขวันที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> แก้ไขโดย</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(productTransaction.producttransactionlogs) ? (
                productTransaction?.producttransactionlogs.map(
                  (_order2, index) => (
                    <TableRow
                      key={index}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      className={index % 2 === 0 ? 'bg-gray-100' : ''}
                    >
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>
                        <div className="my-auto">
                          <a
                            className="hover:underline cursor-pointer"
                            onClick={() => handleToInfoPage(_order2?._id)}
                          >
                            {productTransaction?.running_number}
                          </a>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="my-auto">{_order2?.type_edit}</div>
                      </TableCell>
                      <TableCell>
                        <div className="my-auto">{`${dayjs(
                          _order2?.modify_date,
                        ).format('D MMM BBBB ')} : ${dayjs(
                          _order2?.modify_date,
                        ).format('HH:mm')}`}</div>
                      </TableCell>
                      <TableCell>
                        {_order2?.modify_employee?.firstname}
                      </TableCell>
                    </TableRow>
                  ),
                )
              ) : (
                <TableRow key={''}>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีรายการ</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );

  const renderShowProductList = () => (
    <Card className="p-6 ">{displayProduct()}</Card>
  );

  const renderShowOrderHistory = () => (
    <Card className="p-6 ">{displayOrderHistory()}</Card>
  );

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (productTransaction.isLoading || productTransaction.rows) {
    return <Loading />;
  }
  if (!productTransaction.isLoading && productTransaction.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
          <div className="text-lg font-semibold ml-6">รายละเอียดการนำเข้า</div>
        </div>
        <div>
          <Card className="p-4 ">
            <div className="flex flex-wrap divide-y">
              <div className="w-full py-4 text-lg font-semibold ">
                {'รายละเอียด'}
              </div>
              <div className="w-1/2 py-4 px-2 font-semibold ">
                {'หมายเลขเอกสาร'}
              </div>
              <div className="w-1/2 py-4 ">{`${
                productTransaction?.running_number || '-'
              }`}</div>
              <div className="w-1/2 py-4 px-2 font-semibold ">{'คู่ค้า'}</div>
              <div className="w-1/2 py-4 ">{`${
                productTransaction?.customer?.name || '-'
              }`}</div>
              <div className="w-1/2 py-4 px-2 font-semibold ">
                {'ประเภทคู่ค้า'}
              </div>
              <div className="w-1/2 py-4 ">
                {`${productTransaction?.customer?.type?.name || '-'}`}
              </div>
              <div className="w-1/2 py-4 px-2 font-semibold ">
                {'ผู้นำเข้า'}
              </div>
              <div className="w-1/2 py-4 ">
                {productTransaction?.import_employee?.firstname &&
                  `${productTransaction?.import_employee?.firstname} ${productTransaction?.import_employee?.lastname}`}
              </div>
              <div className="w-1/2 py-4 px-2 font-semibold ">
                {'ผู้รับสินค้าจริง'}
              </div>
              <div className="w-1/2 py-4 ">
                {productTransaction?.real_import_employee?.firstname &&
                  `${productTransaction?.real_import_employee?.firstname} ${productTransaction?.real_import_employee?.lastname}`}
              </div>
              <div className="w-1/2 py-4 px-2 font-semibold ">
                {'ผู้รับสินค้าจริง( กรณีไม่มีชื่อในระบบ )'}
              </div>
              <div className="w-1/2 py-4 ">
                {productTransaction?.real_import_employee_string}
              </div>
              <div className="w-1/2 py-4 px-2 font-semibold ">
                {'หมายเลขบิล'}
              </div>
              <div className="w-1/2 py-4 ">
                {`${productTransaction?.bill_number}` || '-'}
              </div>
              {productTransaction?.project_number && (
                <>
                  <div className="w-1/2 py-4 px-2 font-semibold ">
                    {'หมายเลขโครงการ'}
                  </div>
                  <div className="w-1/2 py-4 ">
                    {`${productTransaction?.project_number}` || '-'}
                  </div>
                </>
              )}

              <div className="w-1/2 py-4 px-2 font-semibold ">
                {'วันที่นำเข้า'}
              </div>
              <div className="w-1/2 py-4 ">{`${dayjs(
                productTransaction?.import_date,
              ).format('D MMM BBBB ')}`}</div>
              <div className="w-1/2 py-4 px-2 font-semibold ">
                {'วันที่สร้างรายการ'}
              </div>
              <div className="w-1/2 py-4 ">{`${dayjs(
                productTransaction?.createAt,
              ).format('D MMM BBBB ')}`}</div>
              {productTransaction?.remark ? (
                <>
                  <div className="w-1/2 py-4 px-2 font-semibold ">
                    {'หมายเหตุ'}
                  </div>
                  <div className="w-1/2 py-4 ">{`${productTransaction?.remark}`}</div>
                </>
              ) : (
                <></>
              )}
              {productTransaction?.images ? (
                <>
                  <div className="w-full py-4 px-2 font-semibold ">รูปภาพ</div>
                  <div className="w-full flex">
                    {_.map(productTransaction.images, (eachImage, index) => (
                      <div key={index} className="">
                        <img
                          key={index}
                          src={eachImage?.url}
                          className="w-64 my-auto "
                        />
                        <div className="my-4 flex justify-center  "></div>
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </Card>
          {productTransaction?.orders ? (
            <>
              <div className="w-full py-4 px-2 font-semibold ">
                {'รายการสินค้า'}
              </div>
              {renderShowProductList()}
            </>
          ) : (
            <></>
          )}

          {productTransaction?.producttransactionlogs ? (
            <>
              <div className="w-full py-4 px-2 font-semibold ">
                {'ประวัติการแก้ไข'}
              </div>
              {renderShowOrderHistory()}
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  }
  return <Error />;
}

DetailOrder.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DetailOrder.defaultProps = {
  title: '',
  subtitle: '',
};
