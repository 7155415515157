/* eslint-disable import/no-unresolved */
import _ from 'lodash';

import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material';

import React, { useState } from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-unresolved
// eslint-disable-next-line import/extensions

export function EditProductForm({
  product,
  Controller,
  control,
  productType,
  brand,
}) {
  // const [reload, setReload] = useState(false);
  console.log('brand?.rows', brand?.rows);
  return (
    <div className="flex flex-row flex-wrap">
      <div className="p-2 bg-gray-600 text-white my-2 w-full">
        ข้อมูลเบื้องต้นของสินค้า
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'type_code'}
          control={control}
          defaultValue={product ? product.type_code : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="รหัสสินค้า 5 หลัก"
              fullWidth
              size={'small'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'name'}
          control={control}
          defaultValue={product ? product.name : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ชื่อสินค้า"
              required
              fullWidth
              size={'small'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'model_name'}
          control={control}
          defaultValue={product ? product.model_name : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField {...field} label="รุ่นสินค้า" fullWidth size={'small'} />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'unit'}
          control={control}
          defaultValue={product ? product.unit : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="หน่วยนับ"
              required
              fullWidth
              size={'small'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'product_type'}
          control={control}
          // eslint-disable-next-line no-underscore-dangle
          defaultValue={product ? product?.product_type?._id : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
              <InputLabel id="type" size={'small'}>
                ประเภทสินค้า
              </InputLabel>
              <Select
                {...field}
                label="ประเภทสินค้า"
                size={'small'}
                fullWidth
                required
              >
                {_.size(productType?.rows) ? (
                  _.map(productType.rows, (row) => (
                    <MenuItem key={row.id} value={row.id}>
                      {row.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem>
                    <MenuItem disabled value="">
                      <em>ไม่มีข้อมูล</em>
                    </MenuItem>
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'brand'}
          control={control}
          // eslint-disable-next-line no-underscore-dangle
          defaultValue={product ? product?.brand?._id : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
              <InputLabel id="brand" size={'small'}>
                แบรนด์
              </InputLabel>
              <Select {...field} label="แบรนด์" size={'small'} fullWidth>
                <MenuItem>
                  <MenuItem value={null}>
                    <em>ไม่เลือก</em>
                  </MenuItem>
                </MenuItem>
                {_.size(brand?.rows) ? (
                  _.map(brand?.rows, (row) => (
                    <MenuItem key={row.id} value={row.id}>
                      {row.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem>
                    <MenuItem disabled value="">
                      <em>ไม่มีข้อมูล</em>
                    </MenuItem>
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'minimum_inventory'}
          control={control}
          defaultValue={product ? product.minimum_inventory : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="จำนวนแจ้งเตือนสินค้าใกล้หมด"
              required
              fullWidth
              type="number"
              size={'small'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'detail'}
          control={control}
          defaultValue={product ? product.detail : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="รายละเอียด"
              fullWidth
              multiline
              rows={3}
              size={'small'}
            />
          )}
        />
      </div>
    </div>
  );
}

EditProductForm.propTypes = {
  errors: PropTypes.shape({
    name: PropTypes.string,
    unit: PropTypes.string,
    packing_size: PropTypes.number,
    packing_unit: PropTypes.string,
    inventory: PropTypes.number,
    price: PropTypes.number,
    unit_net: PropTypes.string,
    detail: PropTypes.string,
    type_code: PropTypes.string,
    option: PropTypes.string,
    model: PropTypes.string,
  }),
  product: PropTypes.object,
  productType: PropTypes.object,
  brand: PropTypes.array,
  Controller: PropTypes.func,
  control: PropTypes.object,
  productDescribe: PropTypes.string,
  model: PropTypes.string,
  setProductDescribe: PropTypes.func,
};

EditProductForm.defaultProps = {
  product: null,
  productType: null,
};

export default EditProductForm;
