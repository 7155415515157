/* eslint-disable import/extensions */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

// eslint-disable-next-line object-curly-newline
import {
  Card,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  // Backdrop,
  // CircularProgress,
  TextField,
} from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
import ProductGraph from '../../components/Graph/ProductGraph';
import DailySaleGraph from '../../components/Graph/DailySaleGraph';
import SalesGraph from '../../components/Graph/SalesGraph';
import ProductGraphProfit from '../../components/Graph/ProductGraphProfit';
import ProductGraphOnline from '../../components/Graph/ProductGraphOnline';
import ProductDonutOnline from '../../components/Graph/ProductDonutOnline';
// import ProductGraphProfitOnline from '../../components/Graph/ProductGraphProfitOnline';

import StatusCardsOnline from '../../components/Graph/StatusCardsOnline';
// import TableStatus from '../../components/Graph/TableStatus';

import NotAuthorized from '../../components/Error/NotAuthorized';
import accessSubModule from '../../utils/functions/accessSubModule';

const MainGraph = ({ title, subtitle }) => {
  const dispatch = useDispatch();
  const me = useSelector((state) => state.me);
  const paymentTransaction = useSelector((state) => state.paymentTransaction);
  const productTransaction = useSelector((state) => state.productTransaction);
  const product = useSelector((state) => state.product);
  const productType = useSelector((state) => state.productType);
  const customer = useSelector((state) => state.customer);
  const [selectProduct, setSelectProduct] = useState('');
  const [selectGraph, setSelectGraph] = useState('');

  const [productWithType, setProductWithType] = useState();
  const DashBoardPage = true;
  const DashBoardDaily = true;

  // console.log('productTransaction', productTransaction);
  // console.log('paymentTransaction', paymentTransaction?.rows);

  const name = '';
  const [loading, setLoading] = useState(false);

  const [startDate, setStartDate] = useState(
    dayjs().startOf('month').toISOString(),
  );
  const [endDate, setEndDate] = useState(dayjs().endOf('month').toISOString());
  const [periodText, setPeriodText] = useState('ออก');
  const transactionType = periodText;

  useEffect(async () => {
    await dispatch(actions.productAll({ startDate, endDate, DashBoardPage }));
    await dispatch(
      actions.productTransactionAll({
        transactionType,
        DashBoardDaily,
      }),
    );
    await dispatch(actions.paymentTransactionAll({ startDate, endDate }));
    setLoading(false);
    return () => {};
  }, [startDate, endDate, transactionType]);

  useEffect(() => {
    const fetchProductType = async () => {
      try {
        await dispatch(actions.productTypeAll(''));
      } catch (error) {
        console.error(error);
      }
    };
    const fetchCustomer = async () => {
      try {
        await dispatch(actions.customerAll({ name }));
      } catch (error) {
        console.error(error);
      }
    };
    const fetchMe = async () => {
      try {
        await dispatch(actions.meGet(''));
      } catch (error) {
        console.error(error);
      }
    };
    fetchCustomer();

    fetchProductType();
    fetchMe();
    return () => {};
  }, []);

  useEffect(() => {
    setProductWithType(product.rows);
    if (selectProduct === '') {
      setProductWithType(product.rows);
    } else {
      const stackProduct = [];
      // eslint-disable-next-line consistent-return
      _.map(product.rows, (eachProduct) => {
        if (eachProduct.product_type.name === selectProduct) {
          stackProduct.push(eachProduct);
        }
      });
      setProductWithType(stackProduct);
    }
    return () => {};
  }, [selectProduct, product]);

  const handleChangePeriod = (value) => {
    if (value === 'เข้า') {
      setPeriodText('เข้า');
    } else if (value === 'ออก') {
      setPeriodText('ออก');
    } else {
      setPeriodText('online');
    }
  };

  const handleChangeType = (event) => {
    console.log('type product', event.target.value);
    setSelectProduct(event.target.value);
    setLoading(false);
  };

  const handleChangeGraph = (event) => {
    console.log('type graph', event.target.value);
    setSelectGraph(event.target.value);
  };

  const renderPeriod = () => (
    <>
      <div className="grid md:grid-cols-2 pb-2 gap-x-2 gap-y-2">
        <div className="bg-white">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="วันเริ่มต้น"
              value={startDate}
              minDate={new Date('2020-01-01')}
              onChange={(newValue) => {
                setStartDate(dayjs(newValue).toISOString());
                setLoading(true);
              }}
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </LocalizationProvider>
        </div>
        <div className="bg-white">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="วันสิ้นสุด"
              value={endDate}
              minDate={new Date('2020-01-01')}
              onChange={(newValue) => {
                setEndDate(dayjs(newValue).toISOString());
                setLoading(true);
              }}
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </LocalizationProvider>
        </div>
      </div>
    </>
  );
  const renderAllGraph = () => (
    <div>
      {selectGraph === 'graph1' && (
        <div className="pb-2">
          <div className="w-full pb-2 lg:pb-0">
            <Card className="p-6">
              {periodText === 'ออก' && (
                <h1 className="font-bold text-xl text-center">
                  สรุปจำนวนการขายโดยรวมสินค้า
                </h1>
              )}
              {periodText === 'เข้า' && (
                <h1 className="font-bold text-xl text-center">
                  สรุปจำนวนการนำเข้าโดยรวมสินค้า
                </h1>
              )}
              <ProductGraph
                product={product?.rows}
                productTransaction={productTransaction}
                loading={loading}
                setLoading={setLoading}
                periodText={periodText}
              />
            </Card>
          </div>
        </div>
      )}
      {selectGraph === 'graph2' && (
        <div className="pb-2">
          <div className="w-full pb-2 lg:pb-0">
            <Card className="p-6">
              {periodText === 'ออก' && (
                <h1 className="font-bold text-xl text-center">
                  สรุปยอดขายโดยรวมสินค้า
                </h1>
              )}
              {periodText === 'เข้า' && (
                <h1 className="font-bold text-xl text-center">
                  สรุปยอดนำเข้าโดยรวมสินค้า
                </h1>
              )}
              <SalesGraph
                product={productWithType}
                productTransaction={productTransaction}
                loading={loading}
                setLoading={setLoading}
                periodText={periodText}
              />
            </Card>
          </div>
        </div>
      )}
      {selectGraph === 'graph3' && (
        <div className="pb-2">
          <div className="w-full pb-2 lg:pb-0"></div>
          <Card className="p-6">
            <h1 className="font-bold text-xl text-center pb-2">
              สรุปกำไร ต้นทุน ราคาขายของแต่ละสินค้า
            </h1>
            <ProductGraphProfit
              product={productWithType}
              productTransaction={productTransaction}
              loading={loading}
              setLoading={setLoading}
              periodText={periodText}
            />
          </Card>
        </div>
      )}
      {selectGraph === 'graph4' && (
        <div className="pb-2">
          {/* <div className="text-center mt-2">กำลังพัฒนา</div> */}
          <div className="w-full pb-2 lg:pb-0"></div>
          <Card className="p-6">
            <DailySaleGraph
              product={productWithType}
              productTransaction={productTransaction?.rows}
              loading={loading}
              setLoading={setLoading}
              periodText={periodText}
            />
          </Card>
        </div>
      )}
    </div>
  );

  const renderAllGraphOnline = () => (
    <div>
      <div className="w-full md:w-1/2  pb-2 pt-1">
        <FormControl
          sx={{ minWidth: 120 }}
          fullWidth={true}
          className="bg-white"
        >
          <InputLabel id="type" size={'small'}>
            ประเภทสินค้า
          </InputLabel>
          <Select
            label="ประเภทสินค้า"
            size={'small'}
            value={selectProduct}
            onChange={handleChangeType}
            fullWidth
          >
            <MenuItem value={''}>แสดงทั้งหมด</MenuItem>
            {_.size(productType.rows) ? (
              _.map(productType.rows, (row) => (
                <MenuItem key={row.id} value={row.name}>
                  {row.name}
                </MenuItem>
              ))
            ) : (
              <MenuItem>
                <MenuItem disabled value="">
                  <em>ไม่มีข้อมูล</em>
                </MenuItem>
              </MenuItem>
            )}
          </Select>
        </FormControl>
      </div>
      <div className="pb-2">
        <div className="w-full pb-2 lg:pb-0">
          <Card className="p-6">
            <h1 className="font-bold text-xl text-center">
              สรุปจำนวนการขายโดยรวมสินค้า
            </h1>
            <ProductDonutOnline
              product={productWithType}
              productTransaction={productTransaction}
              paymentTransaction={paymentTransaction}
              loading={loading}
              setLoading={setLoading}
            />
          </Card>
        </div>
      </div>
      <div className="pb-2">
        <div className="w-full pb-2 lg:pb-0">
          <Card className="p-6">
            <h1 className="font-bold text-xl text-center">
              สรุปจำนวนการขายแต่ละสินค้า (ออนไลน์)
            </h1>
            <ProductGraphOnline
              product={productWithType}
              productTransaction={productTransaction}
              paymentTransaction={paymentTransaction}
              loading={loading}
              setLoading={setLoading}
            />
          </Card>
        </div>
      </div>
    </div>
  );

  const renderAllStatusOnline = () => <div>{renderAllGraphOnline()}</div>;

  const renderAllStatus = () => (
    <div className="md:flex gap-2">
      <div className="w-full pb-2 pt-1">
        <FormControl
          sx={{ minWidth: 120 }}
          fullWidth={true}
          className="bg-white"
        >
          <InputLabel id="type" size={'small'}>
            เลือกกราฟ
          </InputLabel>
          <Select
            label="เลือกกราฟ"
            size={'small'}
            value={selectGraph}
            onChange={handleChangeGraph}
            fullWidth
          >
            <MenuItem value="graph1">กราฟสรุปจำนวนการขายของสินค้า</MenuItem>
            <MenuItem value="graph2">กราฟสรุปยอดขายของสินค้า</MenuItem>
            <MenuItem value="graph3">กราฟสรุปกำไรต้นทุนของการขาย</MenuItem>
            <MenuItem value="graph4">กราฟยอดขายรายวัน/เดือน/ปี</MenuItem>
          </Select>
        </FormControl>
      </div>
    </div>
  );

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (
    me.isLoading ||
    product.isLoading ||
    customer.isLoading ||
    productTransaction.isLoading ||
    productType.isLoading ||
    paymentTransaction.isLoading
  ) {
    return <Loading />;
  }
  if (
    !me.isLoading &&
    me.isCompleted &&
    !product.isLoading &&
    product.isCompleted &&
    !customer.isLoading &&
    customer.isCompleted &&
    !productTransaction.isLoading &&
    productTransaction.isCompleted &&
    !productType.isLoading &&
    productType.isCompleted &&
    !paymentTransaction.isLoading &&
    paymentTransaction.isCompleted
  ) {
    return (
      <div>
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
        </div>
        <div className="flex justify-start pb-4">
          กำลังพัฒนา
          {/* <div className="bg-white">
            <FormControl sx={{ minWidth: 120 }}>
              <InputLabel id="type" size={'small'}>
                เลือกรายการ
              </InputLabel>
              <Select
                label="เลือกรายการ"
                size={'small'}
                fullWidth
                defaultValue="ออก"
                onChange={(e) => {
                  handleChangePeriod(e.target.value);
                  setLoading(false);
                }}
              >
                <MenuItem value="online">ขายออนไลน์</MenuItem>
                <MenuItem value="เข้า">รายการนำเข้า</MenuItem>
                <MenuItem value="ออก">รายการส่งออก</MenuItem>
              </Select>
            </FormControl>
          </div> */}
        </div>
        {/* {selectGraph !== 'graph4' && renderPeriod()} */}
        {/* {renderAllStatus()} */}
        {/* {transactionType === 'online'
          ? renderAllStatusOnline()
          : renderAllStatus()}
        {renderAllGraph()} */}
      </div>
    );
  }
  return <Error message={me?.message} />;
};

MainGraph.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

MainGraph.defaultProps = {
  title: '',
  subtitle: '',
};

export default MainGraph;
